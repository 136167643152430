import React from 'react';

import { Dropdown } from 'semantic-ui-react';

const QDropdown = ({ children, ...props }) => (
  <Dropdown aria-label="Dropdown" aria-haspopup="listbox" {...props}>
    {children}
  </Dropdown>
);

export default QDropdown;
