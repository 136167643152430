import React, { useState } from 'react';

import { Table } from 'semantic-ui-react';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { triadQuestionOptions } from '../../types';

const getLabelForFeedback = (answer) => {
  const { label } = triadQuestionOptions.find((q) => q.value === answer);
  return label || '';
};

const getFeedbackAnswer = (feedback, index) => (feedback
  && feedback.answers
  && feedback.answers.length
  && feedback.answers.length > index ? (
    <span>{getLabelForFeedback(feedback.answers[index].answer)}</span>
  ) : (
    <span style={{ color: 'gray' }}>N/A</span>
  ));

const FeedbackRow = ({ feedback }) => (
  <Table.Row>
    <Table.Cell collapsing>{getFeedbackAnswer(feedback, 0)}</Table.Cell>
    <Table.Cell collapsing>{getFeedbackAnswer(feedback, 1)}</Table.Cell>
    <Table.Cell>
      {feedback.text || <span style={{ color: 'gray' }}>No comments</span>}
    </Table.Cell>
  </Table.Row>
);

const NoFeebackRow = () => (
  <Table.Row>
    <Table.Cell colSpan="4">No feedback for this student</Table.Cell>
  </Table.Row>
);

const FeedbackQuery = gql`
  query FeedbackQuery($userID: String!) {
    user(ID: $userID) {
      fullname
      externalid
      feedback {
        id
        text
        answers {
          question
          answer
        }
      }
    }
  }
`;

// Leaving this until we have backend support for this
const RichFeedbackHeader = () => (
  <Table.Row>
    <Table.HeaderCell collapsing>Staff</Table.HeaderCell>
    <Table.HeaderCell>Date</Table.HeaderCell>
    <Table.HeaderCell>Topic</Table.HeaderCell>
    <Table.HeaderCell>Comments</Table.HeaderCell>
  </Table.Row>
);

const BasicFeedbackHeader = () => (
  <Table.Row>
    <Table.HeaderCell collapsing>Was knowledgeable?</Table.HeaderCell>
    <Table.HeaderCell collapsing>Needed less help?</Table.HeaderCell>
    <Table.HeaderCell>Comments</Table.HeaderCell>
  </Table.Row>
);

const COLLAPSE_FEEDBACK_SIZE = 5;

const FeedbackHistory = ({ student }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { error, data } = useQuery(FeedbackQuery, {
    variables: {
      userID: student.externalid,
    },
    fetchPolicy: 'network-only',
  });

  // eslint-disable-next-line no-console
  if (error) console.error('FeedbackHistory gql error', error);

  const allFeedback = (data && data.user && data.user.feedback) || [];

  return (
    <>
      <Table celled>
        <Table.Header>
          <BasicFeedbackHeader />
        </Table.Header>
        <Table.Body>
          {allFeedback.length ? (
            allFeedback
              .slice(
                0,
                isExpanded ? allFeedback.length : COLLAPSE_FEEDBACK_SIZE,
              )
              .map((feedback) => (
                <FeedbackRow feedback={feedback} key={feedback.id} />
              ))
          ) : (
            <NoFeebackRow />
          )}
        </Table.Body>
      </Table>
      {allFeedback.length > COLLAPSE_FEEDBACK_SIZE && (
      <>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          style={{ marginLeft: '8px', marginTop: '.5em' }}
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}
          onKeyDown={() => {
            setIsExpanded(!isExpanded);
          }}
          role="button"
          tabIndex={0}
        >
          {(!isExpanded && 'View All Feedback...') || 'View Less'}
        </a>
      </>
      )}
    </>
  );
};

export default FeedbackHistory;
