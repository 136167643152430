import * as React from 'react';

// Until we have typing this is the source of truth for the shape of AppContext
// Things in AppContext should not change often or outside of the main container
const defaultAppContext = {
  institution: null,
  onCourseSelected: () => {},
  onRoomSelected: () => {},
  onLogout: () => {},
};
export const AppContext = React.createContext(defaultAppContext);

/*
 * Variables that may change in the course of the runtime should be wrapped in
 * their own contexts. This is because useContext will cause functional comps
 * to rerender on every change
 */
export const UserContext = React.createContext();
export const CourseIDContext = React.createContext();
export const RoomIDContext = React.createContext();
