import React from 'react';

import { Popup } from 'semantic-ui-react';

import { isOutOfTokens } from './token-utils';

const NoTokensTooltipCopy = () => (
  <p>
    You are out of tokens. Please wait until tomorrow when your tokens refresh.
  </p>
);
const WithTokensTooltipCopy = () => (
  <p>
    If you run out of tokens, you will not be able to receive office hours
    assistance until the next day
  </p>
);

const pluralify = (numTokens) => (numTokens === 1 ? 'token' : 'tokens');

const TokensText = ({ numTokens }) => {
  if (numTokens <= 0) {
    return <p>You are out of tokens</p>;
  }

  return (
    <p>
      You have <strong>{numTokens}</strong> {pluralify(numTokens)} remaining.
    </p>
  );
};

const StudentTokensView = ({ numTokens }) => (numTokens != null ? (
  <Popup trigger={<TokensText numTokens={numTokens} />}>
    {isOutOfTokens(numTokens) ? (
      <NoTokensTooltipCopy />
    ) : (
      <WithTokensTooltipCopy />
    )}
  </Popup>
) : null);

export default StudentTokensView;
