import React, { useContext } from 'react';
import {
  Icon, Header, Card, Message, Button,
} from 'semantic-ui-react';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';

import { Link, Route, Redirect } from 'react-router-dom';
import ErrorView from '../common/ErrorView';
import { AppContext } from '../Contexts';
import { toSentenceCase } from '../util/string-utils';
import Welcome from '../Onboarding/Welcome';

const SelectableCourse = ({ course, history, onCourseSelected }) => (
  <li className="unstyledList">
    <Card
      style={{
        border: '3px solid #044462',
        borderRadius: '2.5em',
        textAlign: 'center',
        margin: '10px',
        mouse: 'pointer',
      }}
      onClick={() => {
        onCourseSelected(course.id);
        history.push('/');
      }}
    >
      <div style={{ height: '150px' }}>
        <div
          style={{
            height: '40px',
            background: '#044462',
            borderRadius: '2em 2em 0px 0px',
            color: 'white',
            verticalAlign: 'center',
          }}
        >
          <div
            style={{
              fontWeight: 'bold',
              fontSize: '1.5em',
              paddingTop: '.3em',
            }}
          >
            {course.code}
          </div>
        </div>
        <div style={{ padding: '1em' }}>{course.name}</div>
        <div style={{ padding: '.5em' }}>
          {`${toSentenceCase(course.session.term)} ${course.session.year}`}
        </div>
      </div>
    </Card>
  </li>
);

const SelectCourseNew = ({ courses, onCourseSelected, history }) => (courses && courses.length ? (
  <ul
    style={{
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      padding: '0',
    }}
  >
    {courses.map((course) => (
      <SelectableCourse
        course={course}
        onCourseSelected={onCourseSelected}
        history={history}
        key={course.id}
      />
    ))}
  </ul>
) : null);

const SelectCourseQuery = gql`
  query SelectCourseQuery {
    user {
      enrollments {
        pending
        course {
          name
          id
          code
          session {
            term
            year
          }
        }
      }
    }
  }
`;

const SelectCourseView = ({ history }) => {
  const { onCourseSelected } = useContext(AppContext);
  const { loading, error, data } = useQuery(SelectCourseQuery, {
    fetchPolicy: 'no-cache',
  });

  if (loading) {
    return <Icon name="circle notched" loading />;
  }
  if (!data || !data.user || !data.user.enrollments) {
    return <ErrorView error={error} data={data} />;
  }

  if (!data?.user?.enrollments?.length) {
    return <Redirect to="/welcome" />;
  }

  const courses = data.user.enrollments
    .filter((e) => !e.pending)
    .map((e) => e.course);

  return (
    <>
      <Route
        path="/(selectCourse|allCourses)/welcome"
        component={(props) => <Welcome closeable {...props} />}
      />
      <Header>Your Quuly Spaces</Header>
      {!!courses.length && <span>Please select a space. </span>}
      {!courses.length && (
        <span>Create or join a new course, group, or space </span>
      )}
      {!courses.length ? (
        <Message info icon>
          <Icon name="info circle" />
          <Message.Content>
            <Message.Header>No courses available</Message.Header>
          </Message.Content>
        </Message>
      ) : (
        <SelectCourseNew
          history={history}
          courses={courses}
          onCourseSelected={onCourseSelected}
        />
      )}
      <div
        style={{
          display: 'flex',
          flexFlow: 'column',
          marginTop: '25px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div>
          <Button as={Link} to="/selectCourse/welcome" color="blue">
            <Icon name="plus" />
            Add Space
          </Button>
        </div>
      </div>
    </>
  );
};

export default SelectCourseView;
