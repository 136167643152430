import React, { useState } from 'react';

import { Segment, Icon } from 'semantic-ui-react';

import Collapsable from '../components/common/Collapsable';
import { successToast } from '../components/util/toast';

// Ripped from Mozilla
const onPushRequested = (setWasGranted) => {
  // Let's check if the browser supports notifications
  if (!('Notification' in window)) {
    alert('This browser does not support desktop notification');
  } else if (Notification.permission === 'granted') { // Let's check whether notification permissions have already been granted
    // If it's okay let's create a notification
    successToast("You're already receiving Quuly notifications");
  } else if (Notification.permission !== 'denied') { // Otherwise, we need to ask the user for permission
    Notification.requestPermission().then((permission) => {
      // If the user accepts, let's create a notification
      if (permission === 'granted') {
        successToast('You will now receive Quuly notifications.');
        setWasGranted(true);
      }
    });
  }
};

const RequestPushSection = ({ role }) => {
  const [wasGranted, setWasGranted] = useState(false);

  // notification isn't supported in all browsers
  if (!('Notification' in window)) {
    return null;
  }

  // Don't render if the user has already granted or denied permission
  if (Notification.permission !== 'default' || wasGranted) return null;
  return (
    <Collapsable expandedOnMount name="Enable push">
      <span>Get notified when you&apos;re ready to be seen.</span>
      <Segment>
        <Icon name="bullhorn" color="blue" />
        <button type="button" onClick={() => onPushRequested(setWasGranted)}>
          Enable push notifications here
        </button>
      </Segment>
    </Collapsable>
  );
};

export default RequestPushSection;
