import React, { useState, useEffect } from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import {
  Form,
  Input,
  TextArea,
  Button,
  Card,
  Dropdown,
  Message,
  Icon,
  Popup,
} from 'semantic-ui-react';
import { Callout } from '@blueprintjs/core';
import * as Toasts from '../util/toast';

import StudentTokensView from '../Tokens/StudentTokensView';
import { isOutOfTokens } from '../Tokens/token-utils';
import * as Logging from '../util/logging';
import QDropdown from '../common/Dropdown';
import useTagMatch from '../util/useTagMatch';

const tagOptions = (tags) => (tags
  ? tags.map(({ tag, id }) => ({ name: tag, value: id, text: tag }))
  : []);

const roomOptions = (rooms) => (rooms
  ? rooms.map(({ name, id }) => ({ name, value: id, text: name }))
  : []);

const CreateConversationMutation = gql`
  mutation CreateConversationMutation(
    $topic: String!
    $description: String!
    $room: String!
    $course: String!
    $tags: [String]
    $location: String
  ) {
    createConversation(
      conversationData: {
        topic: $topic
        description: $description
        room: $room
        course: $course
        tags: $tags
        location: $location
      }
    ) {
      topic
      description
      id
      tags {
        id
        tag
      }
    }
  }
`;

const StudentMessaging = ({ course: { message } = {} }) => (message ? (
  <Message attached="bottom" info>
    <Icon name="asterisk" />
    {message}
  </Message>
) : null);

const DemoModeWarning = () => (
  <span className="darkgray">
    You cannot create sessions in student demo mode
  </span>
);

const onMutationError = (error) => {
  // eslint-disable-next-line no-console
  console.error(error);
  Toasts.warningToast('Something went wrong while creating a session');
};

const CreateConversation = ({
  course, course: { rooms }, user, demo,
}) => {
  const defaultRoom = rooms && rooms.length
    ? rooms.find((room) => room.type === 'virtual') || rooms[0]
    : null;
  const [topic, setTopic] = useState('');
  const [description, setDescription] = useState('');
  const [room, setRoom] = useState(defaultRoom);
  const [tags, setTags] = useState([]);
  const [passcode, setPasscode] = useState('');
  const [location, setLocation] = useState('');
  const { tokens: numTokens } = user;

  // TODO: useReducer here
  const onCompleted = (data) => {
    Toasts.successToast("You've been added to the queue");
    setTopic('');
    setDescription('');
    setTags([]);
    setPasscode('');
    // We'll keep the room in place
  };

  useTagMatch(course, tags, setTags, topic, description);

  const roomMap = rooms.reduce((map, r) => ({
    [r.id]: r,
    ...map,
  }), {});

  return (
    <Mutation
      mutation={CreateConversationMutation}
      onError={onMutationError}
      refetchQueries={['StaffLanderQuery', 'StudentLanderQuery']}
    >
      {(createConversation, { data, error }) => {
        const onSubmit = (e) => {
          if (demo) return;
          e.preventDefault();
          Logging.info('CreateConversation', 'Creating session', {
            topic,
            description,
            room,
            tags,
            passcode,
            location,
          });
          createConversation({
            variables: {
              topic,
              description,
              room: room.id || '',
              tags,
              location,
              course: course.id,
            },
          });
        };
        return (
          <Card fluid>
            <Card.Content>
              <Card.Header>Request help session</Card.Header>
              <Card.Meta>
                You will be be notified when staff is ready to assist you.
              </Card.Meta>
            </Card.Content>
            <Card.Content>
              {user.activeConversation ? (
                <>
                  <Callout intent="danger">
                    You are already in the queue for
                    {' '}
                    <strong>
                      {user.activeConversation?.course?.name
                        || user.activeConversation?.course?.code}
                    </strong>
                    . You can only have one active
                    help session in progress at a time.
                  </Callout>
                  <br />
                </>
              ) : null}
              <Form onSubmit={onSubmit}>
                <Form.Input
                  label="Issue"
                  aria-label="Issue"
                  fluid
                  placeholder="Describe your issue in one sentence or fewer"
                  required
                  onChange={(e) => setTopic(e.target.value)}
                />
                <Form.Select
                  label="Topic tags"
                  aria-label="Topic tags"
                  placeholder="Tags on course topics are automatically recognized as you describe your issue"
                  fluid
                  multiple
                  search
                  selection
                  value={tags}
                  options={tagOptions(course.tags)}
                  onChange={(ignore, { value }) => {
                    setTags(value);
                  }}
                />
                <Form.TextArea
                  label="Steps tried"
                  aria-label="Steps tried"
                  placeholder="First, I tried... Next, I tried... I got stuck when I tried..."
                  onChange={(e) => setDescription(e.target.value)}
                />
                <Form.Group widths="equal">
                  <Form.Field>
                    <label htmlFor="roomSelect">Select a Room</label>
                    <QDropdown
                      id="roomSelect"
                      placeholder="Select a Room"
                      label="Room"
                      aria-label="Room"
                      required
                      fluid
                      search
                      selection
                      defaultValue={defaultRoom?.id || ''}
                      options={roomOptions(rooms)}
                      disabled={!rooms?.length}
                      onChange={(ignored, { value }) => setRoom(roomMap[value])}
                    />
                  </Form.Field>
                  {((room && room.type) || '').toLowerCase() !== 'virtual' ? (
                    <Form.Input
                      label="Location (within room)"
                      aria-label="Location (within room)"
                      fluid
                      placeholder="Round table near the back"
                      onChange={(e) => setLocation(e.target.value)}
                    />
                  ) : null}
                </Form.Group>

                {course.requiresPasscode && (
                  <Form.Input
                    type="password"
                    fluid
                    placeholder="passcode"
                    onChange={(e) => setPasscode(e.target.value)}
                  />
                )}
                <Form.Field>
                  {/* don't change flex direction,
                  keep it reversed. This lets us easily float button right */}
                  <div
                    style={{
                      display: 'flex',
                      flexFlow: 'row-reverse',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      position: 'relative',
                    }}
                  >
                    <Popup
                      open
                      disabled={
                        !room
                        || room.type !== 'virtual'
                        || user.isRegisteredForVOH
                      }
                      trigger={(
                        <Button
                          type="submit"
                          color="blue"
                          disabled={
                            isOutOfTokens(numTokens)
                            || demo
                            || user.isAccountInfoRequired
                            || !!user.activeConversation
                            || !room
                            || (room?.type === 'virtual'
                              && !user.isRegisteredForVOH)
                          }
                        >
                          Join Queue
                        </Button>
                      )}
                    >
                      <a
                        href={`https://zoom.us/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_ZOOM_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_API_URL}/voh`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src="https://marketplacecontent.zoom.us/zoom_marketplace/img/add_to_zoom.png"
                          height="32"
                          alt="Add to ZOOM"
                        />
                      </a>
                    </Popup>
                    <StudentTokensView numTokens={numTokens} />
                    {!!demo && <DemoModeWarning />}
                  </div>
                </Form.Field>
              </Form>
            </Card.Content>
            <StudentMessaging course={course} />
          </Card>
        );
      }}
    </Mutation>
  );
};

export default CreateConversation;
