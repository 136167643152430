import * as React from 'react';
import { Message } from 'semantic-ui-react';

const PrettyPrintJson = React.memo(({ data }) => (
  <Message warning>
    <pre style={{ width: '100%' }}>{JSON.stringify(data, null, 2)}</pre>
  </Message>
));

export default PrettyPrintJson;
