import React, { useContext } from 'react';
import gql from 'graphql-tag';
import { Dropdown } from 'semantic-ui-react';
import { useQuery } from 'react-apollo';
import { Link } from 'react-router-dom';
import { RoomFragment } from '../../api/queries';
import { CourseIDContext, AppContext } from '../Contexts';
import * as Logging from '../util/logging';
import QDropdown from '../common/Dropdown';

const MyCoursesListQuery = gql`
  ${RoomFragment}
  query MyCoursesListQuery {
    user {
      enrollments {
        pending
        course {
          name
          id
          code
          message
          ...RoomParts
        }
      }
    }
  }
`;

const CoursesDropdown = ({
  onCourseSelected,
  courses,
  currentCourse,
  dropdownProps = {},
}) => (courses.length ? (
  <QDropdown
    aria-label="Select course or space"
    text={(currentCourse && currentCourse.code) || 'Select course'}
    value={currentCourse && currentCourse.id}
    {...dropdownProps}
  >
    <Dropdown.Menu>
      <Dropdown.Header>Your spaces</Dropdown.Header>
      <Dropdown.Divider />
      {courses.map((course) => (course ? (
        <Dropdown.Item
          as={Link}
          to="/"
          onClick={() => onCourseSelected(course.id)}
          key={course.id}
          text={course.code}
          selected={currentCourse && course.id === currentCourse.id}
        />
      ) : null))}
      <Dropdown.Divider />
      <Dropdown.Item as={Link} to="/selectCourse" text="All spaces" />
    </Dropdown.Menu>
  </QDropdown>
) : null);

const CoursesDropdownContainer = ({ dropdownProps }) => {
  const currentCourseID = useContext(CourseIDContext);
  const { onCourseSelected } = useContext(AppContext);
  const { loading, error, data } = useQuery(MyCoursesListQuery, {
    errorPolicy: 'all',
  });

  if (error) Logging.error('CoursesDropdownContainer', error, data);

  if (!data || !data.user || !data.user.enrollments) {
    return null;
  }
  const { user: userData } = data;
  const courses = userData.enrollments.map((e) => e.course);

  const [currentCourse] = courses.filter(
    (course) => course.id === currentCourseID,
  );

  if (loading) {
    return null;
  }

  return (
    <CoursesDropdown
      onCourseSelected={onCourseSelected}
      courses={courses}
      currentCourse={currentCourse}
      dropdownProps={dropdownProps}
    />
  );
};

export default CoursesDropdownContainer;
