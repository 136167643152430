import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { split } from 'apollo-link';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';

import * as Logging from '../components/util/logging';

const APOLLO_FETCH_OPTIONS = {
  mode: 'cors'
};

export const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_SOCKET_API_URL,
  options: {
    reconnect: true
  }
});

export const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_API_URL}/query`,
  credentials: 'include',
  fetchOptions: APOLLO_FETCH_OPTIONS
});

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
export const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink
);

export const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          Logging.log(
            'GraphQL',
            `Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
      if (networkError) Logging.log('GraphQL Network', networkError);
    }),
    link
  ]),
  cache: new InMemoryCache()
});
