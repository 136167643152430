export const ROLES = {
  student: 'student',
  ta: 'ta',
  instructor: 'instructor',
};
export const ALL_ROLES = [ROLES.instructor, ROLES.ta, ROLES.student];

export const RoleOptions = [
  {
    key: ROLES.student,
    text: 'Student',
    value: ROLES.student,
  },
  {
    key: ROLES.ta,
    text: 'Assistant',
    value: ROLES.ta,
  },
  {
    key: ROLES.instructor,
    text: 'Instructor',
    value: ROLES.instructor,
  },
];

export const NotifTypes = {
  enrollmentInvite: {
    type: 'enrollmentInvite',
    iconName: 'envelope outline',
  },
  completeAccount: {
    type: 'completeAccount',
    iconName: 'exclamation triangle',
  },
};

export const triadQuestionOptions = [
  {
    label: 'Not sure',
    value: 'not_sure',
  },
  {
    label: 'Yes',
    value: 'yes',
  },
  {
    label: 'No',
    value: 'no',
  },
];

export const INTERACTIONS = {
  enqueue: {
    text: 'Created and enqueued by',
    orientation: 'before',
    icon: 'edit',
  },
  dequeue: { text: 'Dequeued by', orientation: 'before', icon: 'bullhorn' },
  no_show: {
    text: 'Marked as no show by',
    orientation: 'before',
    icon: 'sign-out',
  },
  leave: {
    text: 'removed from the queue ',
    orientation: 'after',
    icon: 'arrow alternate circle left',
  },
  open: {
    text: 'Marked as ongoing by',
    orientation: 'before',
    icon: 'angle double right',
  },
  requeue: {
    text: 're-entered the queue',
    orientation: 'after',
    icon: 'arrow alternate circle right',
  },
  resolve: { text: 'Resolved by', orientation: 'before', icon: 'check circle' },
  reopen: { text: 'Reopened by', orientation: 'before', icon: 'refresh' },
};

export const SPACE_TYPES = {
  course: {
    name: 'course',
    type: 'COURSE',
    nameLabel: 'Course name',
    codeLabel: 'Course code',
  },
  tutoring_group: {
    name: 'tutoring group',
    type: 'TUTORING_GROUP',
    nameLabel: 'Group description',
    codeLabel: 'Short name',
  },
  learning_space: {
    name: 'learning space',
    type: 'OTHER',
    nameLabel: 'Space description',
    codeLabel: 'Short name',
  },
  library: {
    name: 'library',
    type: 'LIBRARY',
    nameLabel: 'Space description',
    codeLabel: 'Short name',
  },
  other: {
    name: 'space',
    type: 'SPACE',
    nameLabel: 'Space description',
    codeLabel: 'Short name',
  },
};

export const TERMS = {
  fall: {
    key: 'fall',
    value: 'fall',
    text: 'Fall',
  },
  winter: {
    key: 'winter',
    value: 'winter',
    text: 'Winter',
  },
  spring: {
    key: 'spring',
    value: 'spring',
    text: 'Spring',
  },
  summer: {
    key: 'summer',
    value: 'summer',
    text: 'Summer',
  },
};
