import React from 'react';
import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';

import * as Toasts from '../components/util/toast';

export const INVITE_COOKIE_NAME = 'token';
const EMAIL_COOKIE_NAME = 'uet';

const UpdateEmailMutation = gql`
  mutation UpdateEmailMutation($token: String!) {
    updateEmail(token: $token)
  }
`;

const useAccountUpdate = ({ location }) => {
  const history = useHistory();
  const [cookies, , removeCookie] = useCookies([
    INVITE_COOKIE_NAME,
    EMAIL_COOKIE_NAME,
  ]);

  const [updateEmailMutation] = useMutation(UpdateEmailMutation, {
    onCompleted: (result) => {
      if (result.updateEmail) {
        Toasts.successToast('Email updated');
        removeCookie(EMAIL_COOKIE_NAME, {
          path: '/',
          domain: process.env.REACT_APP_COOKIE_DOMAIN,
        });
      }
    },
    onError: () => {},
  });

  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const updateEmailToken = searchParams.get('uet') || cookies[EMAIL_COOKIE_NAME];
    if (updateEmailToken) {
      updateEmailMutation({
        variables: {
          token: updateEmailToken,
        },
      });
    }
  }, [cookies, location]);

  React.useEffect(() => {
    const token = cookies[INVITE_COOKIE_NAME];

    if (token) {
      const formData = new FormData();
      formData.append('token', token);
      fetch(`${process.env.REACT_APP_API_URL}/invite`, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        body: formData,
      })
        .then((res) => {
          if (res.status === 200) {
            removeCookie(INVITE_COOKIE_NAME, {
              path: '/',
              domain: process.env.REACT_APP_COOKIE_DOMAIN,
            });
            history.push('/selectCourse');
            Toasts.successToast('You have been successfully enrolled');
          } else {
            console.error('Invite error', res);
          }
        })
        .catch((e) => console.error(e));
    }
  }, []);
};

export default useAccountUpdate;
