/* 
For each value in chlog with isRecent, add to array
*/

import changelog from '../../changelog.json';
const changelogVersions = Object.keys(changelog);

const CHANGELOG_SINGLE_LS_KEY = 'CHANGELOG_SINGLE';
const CHANGELOG_LS_KEY = 'CHANGELOG_MULTI';

export function getFullChangelog() {
  return changelog;
}

export function getLastChangelistLog() {
  return changelogVersions && changelogVersions.length
    ? changelogVersions[0]
    : null;
}

export function getLastViewedVersion() {
  return localStorage.getItem(CHANGELOG_SINGLE_LS_KEY);
}

export function setLastViewedVersion(version) {
  return localStorage.setItem(CHANGELOG_SINGLE_LS_KEY, version);
}

export function getChangelog() {
  return changelog.values.filter(changelog => changelog.isRecent);
}

export function getViewedChangelogs() {
  const rawLocalStorageValue = localStorage.getItem(CHANGELOG_LS_KEY);
  if (!rawLocalStorageValue) return [];
  return JSON.parse(rawLocalStorageValue);
}

export function changelogWasViewed(version) {
  const viewedVersions = [version, ...getViewedChangelogs()];
  localStorage.setItem(CHANGELOG_LS_KEY, JSON.stringify(viewedVersions));
}
