import React, { useContext, useState } from 'react';

import { Segment, Form, Button, Label, Popup } from 'semantic-ui-react';
import { Icon } from '@blueprintjs/core';

import './FAQItem.css';
import Tags from '../ConvoCard/Tags';
import gql from 'graphql-tag';
import { warningToast, successToast } from '../util/toast';
import { useMutation } from '@apollo/react-hooks';
import * as Logging from '../util/logging';
import { CourseIDContext } from '../Contexts';
import useTagMatch from '../util/useTagMatch';

const CreateFAQMutation = gql`
  mutation createFAQ(
    $courseID: String!
    $question: String!
    $answer: String!
    $tags: [String!]
  ) {
    createFaq(
      course: $courseID
      question: $question
      answer: $answer
      tags: $tags
    ) {
      id
    }
  }
`;

export interface FAQItem {
  id?: string;
  creator?: any;
  votes?: number;
  question?: string;
  answer?: string;
  tags?: any;
  voted?: boolean;
}

export interface AddFAQItem {
  formVariant?: boolean;
  addQuestionInputRef?: any;
  onClick?: () => void;
  course: any;
}

const onAddSuccess = () => {
  successToast('FAQ Item Added');
};

const onAddError = (error: any) => {
  Logging.error('Welcome', error);
  warningToast(
    <>
      <h4>Something went wrong...</h4>
      <div style={{ marginTop: '.5em' }}>Unable to create tag.</div>
    </>
  );
};

const tagOptions = (tags: any) =>
  tags
    ? tags.map(({ tag, id }: any) => ({ name: tag, value: id, text: tag }))
    : [];

export const AddFAQItem: React.FC<AddFAQItem> = ({
  formVariant = false,
  onClick = () => {},
  addQuestionInputRef,
  course
}) => {
  const courseID = useContext(CourseIDContext);
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [tags, setTags] = useState<any[]>([]);
  const [createFAQ] = useMutation(CreateFAQMutation, {
    onError: onAddError,
    onCompleted: onAddSuccess,
    refetchQueries: ['FAQQuery']
  });
  useTagMatch(course, tags, setTags, question, answer);

  return (
    <>
      <Segment onClick={onClick}>
        <div className="faqItemContainer">
          <div className={`voteContainer`}>
            <div className="addItemContent">
              <Icon iconSize={32} icon="plus" color={'#616161'} />
            </div>
          </div>
          <div className="faqContent addFAQItemContainer">
            {!formVariant && (
              <div className="questionContainer">Add another FAQ item</div>
            )}
            {!!formVariant && (
              <Form
                style={{ width: '100%' }}
                onSubmit={() => {
                  createFAQ({
                    variables: {
                      question,
                      answer,
                      tags,
                      courseID
                    }
                  });
                }}
              >
                <Form.Field>
                  <label>Question</label>
                  <input
                    required
                    ref={addQuestionInputRef}
                    placeholder="(Required) How do I clone the project 1 git repository?"
                    onChange={e => setQuestion(e.target.value)}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Answer</label>
                  <input
                    required
                    placeholder="(Required) Open a terminal and run `$ git clone https://projects.csschool.edu/p1`"
                    onChange={e => setAnswer(e.target.value)}
                  />
                </Form.Field>
                <div className="addTagsContainer">
                  <Form.Select
                    label="Topic tags"
                    aria-label="Topic tags"
                    placeholder="Tags will be auto-recognized as you complete the Q&A form"
                    fluid
                    multiple
                    search
                    selection
                    value={tags}
                    options={tagOptions(course?.tags)}
                    onChange={(ignore, { value }) => {
                      // @ts-ignore
                      setTags(value);
                    }}
                  />
                </div>
                <Button
                  type="submit"
                  color="blue"
                  disabled={!question || !answer}
                  style={{ marginTop: '.5em' }}
                >
                  Add new FAQ Item
                </Button>
              </Form>
            )}
          </div>
        </div>
      </Segment>
    </>
  );
};

export const FAQItem: React.FC<any> = props => {
  return (
    <>
      <Segment>
        <div className="faqItemContainer">
          <div className={`voteContainer ${props.voted ? 'voted' : ''}`}>
            <Popup
              content={'Voting coming soon'}
              trigger={
                <div
                  onClick={() => {
                    // mutate
                  }}
                  role="button"
                  className="voteContent clickable"
                >
                  <span className="voteCount">
                    {/* We want this to be 1 if 0 or null */}
                    {!props.votes ? 1 : props.votes}
                  </span>

                  <Icon
                    iconSize={20}
                    icon="thumbs-up"
                    color={props.voted ? 'white' : '#616161'}
                  />
                </div>
              }
            />
          </div>
          <div className="faqContent">
            <div className="questionContainer">
              {props.question || 'What is a Quuly frequently asked question?'}
            </div>
            <div className="answerContainer">{props.answer || 'No answer'}</div>
            <div className="tagsContainer">
              <Tags tags={props.tags || [{ tag: 'Intro to Quuly' }]} />
            </div>
          </div>
        </div>
      </Segment>
    </>
  );
};
