import React from 'react';
import { Button, Popup, Icon } from 'semantic-ui-react';
import { Mutation } from 'react-apollo';

import { InteractConversationMutation } from '../../api/queries';
import useWindowDimensions from '../util/useWindowDimensions';
import { INTERACTIONS } from '../../types';

const InteractionButton = ({
  convo,
  text,
  interactionType,
  onCompleted,
  onError,
  buttonProps = {},
  feedback = null,
  confirmMessage = null,
  tooltipText,
  comment = null,
  onClick = null,
  disabled = false,
}) => {
  const { width } = useWindowDimensions();
  const iconName = interactionType && INTERACTIONS[interactionType].icon;
  return (
    <Mutation
      mutation={InteractConversationMutation}
      onError={onError}
      onCompleted={onCompleted}
      refetchQueries={[
        'StaffLanderQuery',
        'StudentLanderQuery',
        'RoomListQuery',
        'ActiveTAsQuery',
      ]}
    >
      {(interact, { loading }) => (loading ? (
        <Icon name="circle notched" loading />
      ) : (
        <Popup
          on={width < 900 ? 'click' : 'hover'}
          content={tooltipText}
          disabled={!tooltipText}
          header={text}
          trigger={(
            <Button
              icon
              disabled={disabled}
              onClick={() => {
                const doInteract = () => {
                  if (loading) return;
                  interact({
                    variables: {
                      conversation: convo.id,
                      interactionType,
                      feedback,
                      comment,
                    },
                  });
                };
                if (loading) return () => {};
                if (onClick) return onClick(doInteract);
                if (confirmMessage) {
                  // eslint-disable-next-line no-restricted-globals, no-alert
                  const ok = window.confirm(confirmMessage);
                  if (!ok) return () => {};
                }
                doInteract();
                return () => {};
              }}
              {...buttonProps}
            >
              {text}
              {iconName && (
              <Icon style={{ paddingLeft: '5px' }} name={iconName} />
              )}
            </Button>
            )}
        />
      ))}
    </Mutation>
  );
};

export default InteractionButton;
