import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ProtectedRoute, LoginRoute } from './routing';

import { Login, Signup } from '../components/LoginViews';
import VerifyUser from '../components/Onboarding/VerifyUser';
import LoggedInContainer from '../EntryPoint/LoggedInContainer';
import LogoutWrapper from '../EntryPoint/LogoutWrapper';
import HealthView from '../components/HealthView/HealthView';
import Welcome from '../components/Onboarding/Welcome';

/* Test here https://pshrmn.github.io/route-tester/#/ */
/* Primary router, wraps entire app body */
export default ({ hasAuth, onLogout, ...rest }) => (
  <Switch>
    <Route path="/verify" component={VerifyUser} />
    <Route path="/health" component={HealthView} />
    <Route exact path="/logout" component={LogoutWrapper} />
    <LoginRoute path="/login" component={Login} hasAuth={hasAuth} {...rest} />
    <LoginRoute path="/signup" component={Signup} hasAuth={hasAuth} {...rest} />
    <ProtectedRoute
      path="/"
      component={LoggedInContainer}
      hasAuth={hasAuth}
      {...rest}
    />
    <Route component={() => <Redirect to="/" />} />
  </Switch>
);
