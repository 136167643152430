import React from 'react';
import { Message, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import DismissableMessage from '../components/common/DismissableMessage';

// TODO: remove and abstract out
const FaceliftMessage = () => (
  <DismissableMessage name="v3.0_desc" icon positive>
    <Icon name="check" />
    <Message.Content>
      <Message.Header>Welcome back! Quuly has a facelift</Message.Header>
      We&apos;ve heard your feedback and have redesigned the Quuly web UI from the
      ground up.
      <Link to="/changelog"> See what&apos;s new here</Link> including a new layout
      , updated menus, and more.
    </Message.Content>
  </DismissableMessage>
);
export default () => null;
