import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { H3, Spinner } from '@blueprintjs/core';

import { RouteComponentProps } from 'react-router';
import { CourseDrilldownQuery } from '../../api/queries';
import CannotFindCourseView from './CannotFindCourseView';
import ErrorView from '../common/ErrorView';
import CourseEditForm from '../AdminViews/Course/CourseEditForm';
import { MatchParamsProps } from './CourseData';

interface CoursePreferencesProps
  extends RouteComponentProps<MatchParamsProps> {}

const CoursePreferences: React.FC<CoursePreferencesProps> = props => {
  const { match } = props;
  const { loading, error, data } = useQuery(CourseDrilldownQuery, {
    variables: {
      id: (match && match.params && match.params.courseID) || ''
    }
  });
  if (!match || !match.params || !match.params.courseID) {
    return <CannotFindCourseView />;
  }
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '1em'
        }}
      >
        <H3>Edit Course Preferences</H3>
      </div>

      {!loading && !data && <ErrorView data={data} error={error} />}
      {!loading && !!data ? (
        <CourseEditForm course={data?.course} />
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default CoursePreferences;
