import React from 'react';
import {
  Modal, Form, Input, Container, Dropdown,
} from 'semantic-ui-react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import * as Toasts from '../util/toast';

const SubmitFeedbackMutation = gql`
  mutation SubmitQuulyFeedback($rating: Int!, $feedback: String!) {
    supplySiteFeedback(rating: $rating, feedback: $feedback) {
      success
      message
    }
  }
`;

const FeedbackMenu = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [rating, setRating] = React.useState(null);
  const [feedback, setFeedback] = React.useState('');
  const [submitFeedbackMutation] = useMutation(SubmitFeedbackMutation);

  const submitFeedback = () => {
    submitFeedbackMutation({
      variables: {
        feedback,
        rating,
      },
    });
    setIsOpen(false);
    setRating(null);
    setFeedback('');
    Toasts.successToast('Thanks for the feedback!');
  };

  return (
    <>
      <Modal open={isOpen} onClose={() => setIsOpen(false)}>
        <Modal.Header>Send the Quuly Team Feedback</Modal.Header>

        <Modal.Content scrolling>
          <p>
            Thank you for using Quuly. Any feedback you provide will be used to improve
            the experience of our users.
          </p>
          <Form>
            <p>
              From 1-10, where 1 is &quot;not at all&quot; and 10 is
              &quot;couldn&apos;t imagine my course without it,&quot;
              how much do you like Quuly?
            </p>
            <Form.Group>
              {new Array(10).fill('').map((a, i) => {
                const value = i + 1;
                return (
                  <Form.Radio
                    label={value}
                    value={value}
                    checked={rating === value}
                    onClick={() => setRating(value)}
                  />
                );
              })}
            </Form.Group>
            {rating ? (
              <>
                <Form.TextArea onChange={(e) => setFeedback(e.target.value)} label="Any feedback for us?" />
                <Form.Button color="blue" onClick={submitFeedback}>Submit</Form.Button>
              </>
            )
              : null}
          </Form>
        </Modal.Content>
      </Modal>
      <Dropdown.Item
        text="Provide Feedback"
        onClick={() => setIsOpen(true)}
      />
    </>
  );
};

export default FeedbackMenu;
