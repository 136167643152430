import React from 'react';
import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';

import { Button, Icon, Popup } from 'semantic-ui-react';
import { successToast, warningToast } from '../util/toast';
import * as Logging from '../util/logging';

const onCompleted = (data) => {
  successToast('Successfully unenrolled user');
};

const onError = (error) => {
  Logging.error('DeleteEnrollmentButton', error);
  warningToast('Failed to unenrolled user');
};

const DeleteEnrollmentMutation = gql`
  mutation DeleteEnrollmentMutation($courseID: String!, $userID: String!) {
    unenroll(course: $courseID, user: $userID) {
      success
      message
    }
  }
`;

const DeleteEnrollmentButton = ({ userID, courseID, ...rest }) => {
  const [deleteEnrollment] = useMutation(DeleteEnrollmentMutation, {
    onError,
    onCompleted,
    refetchQueries: ['UsersForInstitution', 'UsersForCourseWithInvites', 'UsersForCourse'],
  });

  return (
    <Popup
      content="Unenroll this user"
      trigger={(
        <Button
          onClick={() => {
            // eslint-disable-next-line no-restricted-globals
            const ok = window.confirm(
              'Are you sure you want to unenroll this user?',
            );
            if (!ok) return;
            deleteEnrollment({ variables: { userID, courseID } });
          }}
          icon
          color="red"
          {...rest}
        >
          <Icon name="trash" />
        </Button>
      )}
    />
  );
};

export default DeleteEnrollmentButton;
