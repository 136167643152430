import React, { useState } from 'react';
import gql from 'graphql-tag';
import { Mutation, useMutation, useQuery } from 'react-apollo';
import { Form } from 'semantic-ui-react';
import * as Toasts from '../../util/toast';
import * as Logging from '../../util/logging';

import { RoleOptions } from '../../../types';

// TODO: if course, add course-specific behavior (add role, add to mutation, etc)

const CreateUserMutation = gql`
  mutation createUser(
    $email: String!
    $fullname: String!
    $enrollment: EnrollmentInput
    $username: String
  ) {
    createUser(
      email: $email
      fullname: $fullname
      enrollment: $enrollment
      username: $username
    ) {
      externalid
    }
  }
`;

const ListCoursesQuery = gql`
  query ListCoursesQuery($institutionID: String!) {
    institution(ID: $institutionID) {
      courses {
        id
        name
        code
      }
    }
  }
`;

const onMutationError = (error) => {
  Toasts.warningToast('Error creating user');
  Logging.error(error);
};

const onAddUserSuccess = (addUser) => {
  Toasts.successToast('Successfully added and invited a user to your course');
  Logging.log(addUser);
};

const AddUserButton = ({
  createUser,
  fullname,
  email,
  enrollment,
  username,
  isAdmin,
}) => (
  <Mutation
    mutation={CreateUserMutation}
    onCompletion={onAddUserSuccess}
    onError={onMutationError}
    refetchQueries={['UsersForInstitution', 'UsersForCourse']}
  >
    {(createUserMutation) => (
      <Form.Button
        onClick={() => createUserMutation({
          variables: {
            fullname,
            email,
            username,
            enrollment,
          },
        })}
      >
        Add and invite user
      </Form.Button>
    )}
  </Mutation>
);

const createCourseOption = (course) => ({
  key: course.id,
  text: course.code,
  value: course.id,
});

const createEnrollment = (course, role) => (course ? { course, role } : null);

const UserAddForm = ({ course, institutionID }) => {
  const [fullname, setFullname] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [username, setUsername] = useState('');
  const [selectedCourse, setSelectedCourse] = useState();
  const [isAdmin, setIsAdmin] = useState(false);

  const [
    createUser,
    { loading: setSessionLoading, error: setSessionError },
  ] = useMutation(CreateUserMutation, {
    onCompleted: onAddUserSuccess,
    onError: onMutationError,
    refetchQueries: ['UsersForInstitution', 'UsersForCourse'],
  });

  const { data, loading: coursesLoading, error: coursesError } = useQuery(
    ListCoursesQuery,
    {
      variables: { institutionID },
      fetchPolicy: 'no-cache',
    },
  );

  if (coursesError || setSessionError) {
    Logging.error('UserAddForm', 'gql error: ', coursesError, setSessionError);
  }

  const courses = (data && data.institution && data.institution.courses) || [];

  const onSubmit = (e) => {
    e.preventDefault();
    const enrollment = selectedCourse || course
      ? createEnrollment(selectedCourse || course.id, role)
      : null;
    createUser({
      variables: {
        fullname,
        email,
        username,
        enrollment,
      },
    });
    Logging.info('UserAddForm', 'Creating user', {
      fullname,
      email,
      isAdmin,
      enrollment,
    });

    setFullname('');
    setEmail('');
    setUsername('');
  };
  return (
    <Form onSubmit={onSubmit}>
      <Form.Group widths="equal">
        <Form.Input
          fluid
          label="Full name"
          placeholder="Full name"
          required
          value={fullname}
          onChange={(e) => setFullname(e.target.value)}
        />
        <Form.Input
          fluid
          label="School username (i.e. directory ID)"
          placeholder="School username"
          required
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </Form.Group>

      <Form.Group widths="equal">
        <Form.Input
          fluid
          label="Email"
          placeholder="Email"
          required
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {(!!course || !!courses.length) && (
          <Form.Select
            fluid
            label="Role"
            options={RoleOptions}
            required
            onChange={(e, { value }) => setRole(value)}
            placeholder="Role"
          />
        )}
        {!course && !!courses.length && (
          <Form.Select
            fluid
            label="Courses"
            options={courses.map(createCourseOption)}
            onChange={(e, { value }) => setSelectedCourse(value)}
            placeholder="Courses"
          />
        )}
      </Form.Group>
      {!course && (
        <Form.Checkbox
          label="Admin user (coming soon)"
          disabled
          onChange={(e, { checked }) => setIsAdmin(checked)}
        />
      )}
      <Form.Button>Add and invite user</Form.Button>
    </Form>
  );
};

export default UserAddForm;
