import React, { useState } from 'react';
import { Mutation } from 'react-apollo';
import { Form } from 'semantic-ui-react';
import gql from 'graphql-tag';
import * as Toasts from '../../util/toast';

const CreateTagMutation = gql`
  mutation createTagMutation($courseID: String!, $tag: String!) {
    addTag(course: $courseID, tag: $tag) {
      success
    }
  }
`;

const onMutationError = (error) => {
  console.error(error);
  Toasts.warningToast('Something went wrong while adding a tag');
};

const TagAddForm = ({ course }) => {
  const [tag, setTag] = useState('');

  if (!course) {
    return null;
  }
  const onCompleted = (data) => {
    Toasts.successToast('Added new tag');
    setTag('');
  };

  return (
    <Form>
      <Form.Input
        fluid
        placeholder="eg: Project 1"
        required
        value={tag}
        onChange={(e) => setTag((e?.target?.value || '').substring(0, 18))}
      />
      <Mutation
        mutation={CreateTagMutation}
        onError={onMutationError}
        onCompleted={onCompleted}
        refetchQueries={['CourseDrilldownQuery']}
      >
        {(addTag) => (
          <Form.Button
            color="blue"
            disabled={!tag}
            onClick={() => {
              addTag({
                variables: {
                  tag,
                  courseID: course.id,
                },
              }).catch((e) => onMutationError(e));
            }}
          >
            Add tag
          </Form.Button>
        )}
      </Mutation>
    </Form>
  );
};

export default TagAddForm;
