import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { RoleGatedRoute } from './routing';

import AdminEntryView from '../components/AdminViews/AdminEntryView';
import AdminRoomView from '../components/AdminViews/Room/AdminRoomView';
import AdminCourseView from '../components/AdminViews/Course/AdminCourseView';
import AdminUserView from '../components/AdminViews/User/AdminUserView';
import UserDrilldownView from '../components/User/UserDrilldownView';
import EnrichCourseView from '../components/AdminViews/Course/EnrichCourseView';
import CourseDrilldownView from '../components/AdminViews/Course/CourseDrilldownView';

import InstructorUserManageView from '../components/InstructorViews/InstructorUserManageView';
import SharedLander from '../components/SharedLander/SharedLander';
import SelectRoomView from '../components/SelectRoom/SelectRoomView';
import ConvoHistoryContainer from '../components/ConvoHistoryViews/ConvoHistoryContainer';
import SelectCourseView from '../components/SelectCourse/SelectCourse';

import ViewAsStudent from '../components/Demo/ViewAsStudent';
import MyConvoHistory from '../components/ConvoHistoryViews/MyConvoHistory';
import ComingSoonView from '../components/ComingSoon/ComingSoonView';
import MyAccountView from '../components/MyAccount/MyAccountView';

import OnCourseSelected from '../EntryPoint/OnCourseSelected';
import CoursePreferences from '../components/CourseSettingsViews/CoursePreferences';
import CourseRoomsView from '../components/CourseSettingsViews/CourseRoomsView';
import CourseTagsView from '../components/CourseSettingsViews/CourseTagsView';
import FAQView from '../components/FAQ/FAQView';

const LegacyAdminViews = () => (
  <>
    <Route
      path="/admin/courses/:courseID/enrichCourse"
      component={EnrichCourseView}
    />
    <Route path="/admin/rooms" component={AdminRoomView} />
    <Route
      path="/admin/courses/:courseID/:action(edit)?"
      component={CourseDrilldownView}
    />
    <Route path="/admin/courses" component={AdminCourseView} />
    <Route path="/admin/users" component={AdminUserView} />
    <Route path="/admin" component={AdminEntryView} />
  </>
);

/* This is a nested router that renders the non-sidebar body content. Order matters */
export default ({ user, courseID }) => (
  <Switch>
    <Route path="/account" component={MyAccountView} />
    <Route path="/:courseID/faq" component={FAQView} />
    <Route
      path="/:courseID/calendar"
      component={() => <ComingSoonView featureName="Staff Calendar" />}
    />
    <Route path="/(selectCourse|allCourses)" component={SelectCourseView} />
    <Route
      exact
      path="/onCourseSelected/:courseID"
      component={OnCourseSelected}
    />
    <RoleGatedRoute
      ta
      instructor
      user={user}
      courseID={courseID}
      path="/:courseID/manage/viewAsStudent"
      component={ViewAsStudent}
    />
    <RoleGatedRoute
      ta
      instructor
      user={user}
      courseID={courseID}
      path="/users/:userID"
      component={UserDrilldownView}
    />
    <RoleGatedRoute
      instructor
      user={user}
      courseID={courseID}
      path="/:courseID/manage/users"
      component={InstructorUserManageView}
    />
    <RoleGatedRoute
      instructor
      user={user}
      courseID={courseID}
      path="/:courseID/manage/analytics"
      component={ComingSoonView}
    />
    <RoleGatedRoute
      instructor
      user={user}
      courseID={courseID}
      path="/:courseID/manage/preferences"
      component={CoursePreferences}
    />
    <RoleGatedRoute
      instructor
      user={user}
      courseID={courseID}
      path="/:courseID/manage/tags"
      component={CourseTagsView}
    />
    <RoleGatedRoute
      instructor
      user={user}
      courseID={courseID}
      path="/:courseID/manage/rooms"
      component={CourseRoomsView}
    />

    {/* TODO: should flip flop this route */}
    <RoleGatedRoute
      ta
      instructor
      user={user}
      courseID={courseID}
      path="/:courseID/allConversations"
      component={ConvoHistoryContainer}
    />
    <RoleGatedRoute
      ta
      instructor
      student
      user={user}
      courseID={courseID}
      path="/:courseID/myConversations"
      component={MyConvoHistory}
    />
    <RoleGatedRoute
      ta
      instructor
      student
      user={user}
      courseID={courseID}
      path="/selectRoom"
      component={SelectRoomView}
    />
    <RoleGatedRoute
      ta
      instructor
      student
      user={user}
      courseID={courseID}
      path="/:courseID?/(users)?/:userID?"
      exact
      component={SharedLander}
    />
    <Route component={() => <Redirect to="/" />} />
  </Switch>
);
