import React from 'react';
import { Card } from 'semantic-ui-react';
import { Callout } from '@blueprintjs/core';
import RoomCard from '../../common/RoomCard';

const RoomsListView = ({
  rooms,
  course = {},
  withOtherCourses = false,
  onCardClick = (room) => {},
}) => (rooms && rooms.length ? (
  <Card.Group>
    {rooms.map((room) => (
      <RoomCard
        key={room.id}
        onClick={onCardClick ? () => onCardClick(room) : null}
        room={room}
        withOtherCourses={withOtherCourses}
        deleteable={!!course}
        course={course}
      />
    ))}
  </Card.Group>
) : (
  <>No rooms</>
));

export default RoomsListView;
