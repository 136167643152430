import React from 'react';
import {
  Segment, Grid, Header, List,
} from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
import useDarkBg from '../util/useDarkBg';

const EnvItem = ({ envItem, envItemKey }) => (
  <List.Item>
    <strong>{envItemKey}</strong> : {envItem}
  </List.Item>
);
export default () => {
  if (!process.env.REACT_APP_IS_HEALTH_DASHBOARD_ENABLED) {
    return <Redirect to="/" />;
  }
  useDarkBg();
  return (
    <>
      <Grid
        textAlign="center"
        className="loginContainer"
        verticalAlign="middle"
      >
        <Grid.Column className="centerColumn">
          <Header style={{ color: 'white' }}>
            🔥🔥🔥 Quuly-Acadia is up 🔥🔥🔥
          </Header>
          <Segment>
            <Header>process.env configuration</Header>

            <List>
              {Object.keys(process.env).map((envItemKey) => (
                <EnvItem
                  envItem={process.env[envItemKey]}
                  key={envItemKey}
                  envItemKey={envItemKey}
                />
              ))}
            </List>
          </Segment>
        </Grid.Column>
      </Grid>
    </>
  );
};
