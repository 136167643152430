import React, { useContext } from 'react';
import { Menu } from '@blueprintjs/core';
import { useHistory } from 'react-router-dom';
import { UserContext, CourseIDContext } from '../Contexts';
import RoleGate from '../common/RoleGate';

const COURSE_ID_TOKEN = ':courseID';

const GatedMenuItem = ({
  roles = [],
  isExactRoute = false,
  route,
  onClick = null,
  ...props
}) => {
  const user = useContext(UserContext);
  const courseID = useContext(CourseIDContext);
  const history = useHistory();

  const formattedRoute = route && route.replace(COURSE_ID_TOKEN, courseID);

  const isActive = isExactRoute
    ? history.location.pathname === formattedRoute
    : history.location.pathname.startsWith(formattedRoute);

  return (
    <RoleGate user={user} roles={roles}>
      <Menu.Item
        onClick={() => {
          if (route) {
            history.push(formattedRoute);
          }
          if (onClick) {
            onClick();
          }
        }}
        active={isActive}
        {...props}
      />
    </RoleGate>
  );
};

const GatedMenuDivider = () => {
  const user = useContext(UserContext);

  return (
    <>
      <RoleGate user={user} ta instructor>
        <Menu.Divider title="Course settings" />
      </RoleGate>
    </>
  );
};

export default ({ onClose }) => (
  <>
    <Menu>
      <Menu.Divider title="Appointments" />
      <GatedMenuItem
        icon="inbox"
        roles={['ta', 'instructor', 'student']}
        text="Queue Inbox"
        route="/"
        isExactRoute
      />
      <GatedMenuItem
        icon="calendar"
        roles={['ta', 'instructor', 'student']}
        text="Staff Calendar"
        route="/:courseID/calendar"
      />
      <Menu.Divider title="Sessions & FAQ" />
      <GatedMenuItem
        icon="data-lineage"
        roles={['ta', 'instructor', 'student']}
        text="FAQ Feed"
        route="/:courseID/faq"
      />
      {/* <GatedMenuItem
        icon="search"
        roles={['ta', 'instructor']}
        text="Search Sessions"
        route="/:courseID/allConversations"
      /> */}
      <GatedMenuItem
        icon="projects"
        roles={['ta', 'instructor', 'student']}
        text="My Sessions"
        route="/:courseID/myConversations"
      />

      <GatedMenuDivider roles={['ta', 'instructor']} />

      <GatedMenuItem
        icon="cog"
        roles={['instructor']}
        text="Preferences"
        route="/:courseID/manage/preferences"
      />
      <GatedMenuItem
        icon="office"
        roles={['instructor']}
        text="Rooms"
        route="/:courseID/manage/rooms"
      />
      <GatedMenuItem
        icon="tag"
        roles={['ta', 'instructor']}
        text="Tags"
        route="/:courseID/manage/tags"
      />
      <GatedMenuItem
        icon="people"
        roles={['instructor']}
        text="Enrollments"
        route="/:courseID/manage/users"
      />
      <GatedMenuItem
        icon="chart"
        roles={['ta', 'instructor']}
        text="Analytics"
        route="/:courseID/manage/analytics"
      />
      <GatedMenuItem
        icon="eye-open"
        roles={['ta', 'instructor']}
        text="View as Student"
        route="/:courseID/manage/viewAsStudent"
      />

      <Menu.Divider title="Overview" />
      <GatedMenuItem
        icon="grid-view"
        roles={['ta', 'instructor', 'student']}
        text="My Courses & Spaces"
        route="/selectCourse"
      />
      <GatedMenuItem
        icon="user"
        roles={['ta', 'instructor', 'student']}
        text="My Account"
        route="/account"
      />
    </Menu>
  </>
);
