import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { H3, Code, Spinner, Callout, H5 } from '@blueprintjs/core';

import { RouteComponentProps } from 'react-router';
import { CourseDrilldownQuery } from '../../api/queries';
import CannotFindCourseView from './CannotFindCourseView';
import ErrorView from '../common/ErrorView';
import { MatchParamsProps, CourseDataProps } from './CourseData';
import TagsListView from './TagsListView';
import TagAddForm from '../AdminViews/Course/TagAddForm';

const TagsEditView: React.FC<CourseDataProps> = ({ course }) => {
  return (
    <>
      <div style={{ marginBottom: '1em' }}>
        <H5>Add a new tag</H5>
        <TagAddForm course={course} />
      </div>

      <div style={{ margin: '1em 0' }}>
        <H5>Available {course.code} Tags</H5>
        <TagsListView course={course} />
      </div>
      {course?.tags?.length < 3 && (
        <Callout
          icon="warning-sign"
          title="Tip: Add at least three tags"
          intent="warning"
        >
          Effective Quuly courses and groups have at least three tags to let
          students categorize questions in the session creation form. Use the
          form above to add tags to your course.
        </Callout>
      )}
    </>
  );
};

const CourseTagsView: React.FC<RouteComponentProps<
  MatchParamsProps
>> = props => {
  const { match } = props;
  const { loading, error, data } = useQuery(CourseDrilldownQuery, {
    variables: {
      id: (match && match.params && match.params.courseID) || ''
    }
  });
  if (!match || !match.params || !match.params.courseID) {
    return <CannotFindCourseView />;
  }
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '1em'
        }}
      >
        <H3>Edit Course Topic Tags</H3>
      </div>
      <div style={{ marginBottom: '1em' }}>
        <Callout title="What are tags?" icon="info-sign" intent="primary">
          Students add topic tags to categorize sessions/questions asked to
          staff. Tags let staff and instructors cluster common questions and
          analyze frequency. <br />
          Examples of effective tags include course topics and project, exam, or
          quiz names: <Code>Java</Code>, <Code>Project 3</Code>,
          <Code>Midterm 1</Code>
        </Callout>
      </div>

      {!loading && !data && <ErrorView data={data} error={error} />}
      {!loading && !!data ? <TagsEditView course={data.course} /> : <Spinner />}
    </>
  );
};

export default CourseTagsView;
