import React, { useContext, useState } from 'react';
import { useQuery, useMutation } from 'react-apollo';
import { useInfiniteScroll } from 'react-infinite-scroll-hook';
import { Route } from 'react-router-dom';

import { Header, Icon, Input } from 'semantic-ui-react';
import { useDebouncedCallback } from 'use-debounce';
import { Callout } from '@blueprintjs/core';
import {
  UsersForCourseWithInvites,
  CourseOverviewQuery,
} from '../../api/queries';
import UserTable from '../User/UserTable';
import UserAddForm from '../AdminViews/User/UserAddForm';
import UserDrilldownView from '../User/UserDrilldownView';
import CSVUpload from './CSVUpload';
import * as Contexts from '../Contexts';
import * as Logging from '../util/logging';
import ErrorView from '../common/ErrorView';
import UserSortDropdown from '../ConvoHistoryViews/ConvoSortDropdown';
import InviteCodeSection from '../InviteCode/InviteCodeSection';
import { successToast } from '../util/toast';

const CannotFindCourseView = () => (
  <Header>Whoops, looks like we can&apos;t find this space</Header>
);

const PAGE_SIZE = 100;

const UserTableWithQuery = ({ courseID, history }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoadingNextPage, setIsLoadingNextPage] = useState(false);
  const [sortOrder, setSortOrder] = useState('createdAsc');
  const [hasNextPage, setHasNextPage] = useState(true);

  const user = useContext(Contexts.UserContext);

  const {
    loading, error, data, fetchMore,
  } = useQuery(
    UsersForCourseWithInvites,
    {
      variables: {
        courseID,
        offset: 0,
        limit: PAGE_SIZE,
        sortOrder,
      },
    },
  );

  let enrollments = [];

  if (data?.course?.enrollments?.length) {
    enrollments = data.course.enrollments;
  }

  const pendingInvites = data?.course?.pendingInvites || [];

  const infiniteRef = useInfiniteScroll({
    loading: loading || isLoadingNextPage,
    hasNextPage,
    onLoadMore: () => {
      const nextPage = currentPage + 1;
      setCurrentPage(nextPage);
      setIsLoadingNextPage(true);
      fetchMore({
        variables: {
          offset: nextPage * PAGE_SIZE,
          limit: PAGE_SIZE,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          setIsLoadingNextPage(false);
          if (!fetchMoreResult || !fetchMoreResult.course || !prev.course) return prev;
          const newEnrollments = fetchMoreResult.course.enrollments || [];
          if (!newEnrollments.length || newEnrollments.length < PAGE_SIZE) {
            setHasNextPage(false);
          }
          const course = {
            ...prev.course,
            enrollments: [...prev.course.enrollments, ...newEnrollments],
          };
          return { ...prev, course };
        },
      });
    },
  });

  if (error) Logging.error('InstructorUserManageView', error);
  if (!data || !data.course) return <Icon name="circle notched" loading />;

  return (
    <>
      <div style={{ display: 'flex', flexFlow: 'column', marginBottom: '1em' }}>
        {/* TODO: search input */}
      </div>
      <div
        style={{
          display: 'flex',
          flexFlow: 'row',
          marginBottom: '1em',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <p>
            Results (showing {enrollments.length + pendingInvites.length})
          </p>
        </div>
        <UserSortDropdown setSortOrder={setSortOrder} sortOrder={sortOrder} />
      </div>
      <div style={{ width: '100%' }} ref={infiniteRef}>
        <UserTable
          tableScope={data.course.code}
          enrollments={enrollments}
          pendingInvites={pendingInvites}
          currentUserID={user.externalid}
          history={history}
          courseID={courseID}
        />
        {isLoadingNextPage && <div>Loading more users...</div>}
      </div>
    </>
  );
};

const InstructorUserManage = ({ courseID, history }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedFunction] = useDebouncedCallback(
    (value) => {
      setSearchQuery(value);
    },
    300,
    // The maximum time func is allowed to be delayed before it's invoked:
    { maxWait: 1500 },
  );

  const {
    loading, error, data, refetch,
  } = useQuery(CourseOverviewQuery, {
    variables: {
      courseID,
    },
  });

  if (error) Logging.error('InstructorUserManageView', error);

  if (loading) return <Icon name="circle notched" loading />;
  if (!data) return <ErrorView data={data} error={error} />;
  if (!data.course) return <CannotFindCourseView />;
  return (
    <>
      <Route
        path="/:courseID/manage/users/:userID"
        component={UserDrilldownView}
      />
      <h2>Manage space enrollments</h2>
      <div style={{ marginBottom: '1em' }}>
        <Callout title="How to enroll" icon="info-sign" intent="primary">
          Adding students and staff to your space is easy. Quuly provides three
          mechanisms to add enrollments:
          <ol>
            <li>
              <strong>Entry codes</strong>
            </li>
            <li>
              <strong>CSV roster upload</strong>
            </li>
            <li>
              <strong>Manual entry</strong>
            </li>
          </ol>
        </Callout>
      </div>
      <InviteCodeSection refreshButton />
      {process.env.REACT_APP_DISABLE_USER_ADD_FORM !== 'true' && (
        <>
          <Header as="h3">Add new user to space manually</Header>
          <UserAddForm
            course={data.course}
            institutionID={data.course.institution.id}
          />
        </>
      )}
      {process.env.REACT_APP_ENABLE_CSV_UPLOAD === 'true' && (
        <>
          <Header as="h3">Upload CSV</Header>

          <CSVUpload
            course={data.course.id}
            onUpload={() => {
              setTimeout(() => {
                successToast('Finished uploading CSV');
                // $eslint-ignore-next-line
                window.location.reload();
              }, 4000);
            }}
          />
        </>
      )}

      <Header as="h3">All users</Header>
      <p>
        Pending users can log in and accept an invite to your space next time
        they log in
      </p>
      {/* TODO: Finish this
      <div style={{ display: 'flex', flexFlow: 'column', marginBottom: '1em' }}>
        <Input
          fluid
          icon="search"
          placeholder={`Search ${data.course.code} enrollments`}
          onChange={(ignore, { value }) => debouncedFunction(value)}
        />
      </div>
         */}

      <UserTableWithQuery history={history} courseID={courseID} />
    </>
  );
};

const InstructorUserManageView = ({ match, history }) => {
  if (!match || !match.params || !match.params.courseID) {
    Logging.warning('InstructorUserManageView', 'No course match ID');
    return <CannotFindCourseView />;
  }

  return (
    <InstructorUserManage courseID={match.params.courseID} history={history} />
  );
};

export default InstructorUserManageView;
