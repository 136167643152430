import React, { useState } from 'react';
import gql from 'graphql-tag';
import { Icon, Input, Header } from 'semantic-ui-react';
import { useQuery } from 'react-apollo';
import { useInfiniteScroll } from 'react-infinite-scroll-hook';
import { useDebouncedCallback } from 'use-debounce';

import ErrorView from '../common/ErrorView';
import ConvoStateDropdown from './ConvoStateDropdown';
import * as Logging from '../util/logging';
import { ConvoWithInteractionsFragment } from '../../api/queries';
import ConvoListView from '../ConvoCard/ConvoListView';
import ConversationTable from '../ConvoCard/ConversationTable';
import Fade from '../common/Fade';
import ConvoSortDropdown from './ConvoSortDropdown';
import useCourseInfo from '../util/useCourseInfo';

const ConvoHistoryAsTable = ({ convos }) => (
  <ConversationTable convos={convos} actions={[]} />
);

const ConvoHistoryAsCards = ({ convos }) => (
  <ConvoListView two column conversations={convos} actions={[]} />
);

const PAGE_SIZE = 25;

const ConvoSearchQuery = gql`
  ${ConvoWithInteractionsFragment}
  query ConvoSearchQuery(
    $courseID: String!
    $offset: Int!
    $limit: Int!
    $sortOrder: SortOrder
    $searchQuery: String!
  ) {
    searchConversations(
      query: $searchQuery
      limit: $limit
      offset: $offset
      sortOrder: $sortOrder
      course: $courseID
    ) {
      ...ConvoWithInteractionsFragment
    }
  }
`;

const ConvoHistoryQuery = gql`
  ${ConvoWithInteractionsFragment}
  query ConvoHistoryQuery(
    $courseID: String!
    $offset: Int!
    $limit: Int!
    $convoState: ConversationState!
    $sortOrder: SortOrder
  ) {
    course(ID: $courseID) {
      id
      conversations(
        state: $convoState
        page: { offset: $offset, limit: $limit }
        sortOrder: $sortOrder
      ) {
        ...ConvoWithInteractionsFragment
      }
    }
  }
`;

const ConvoHistoryHeader = () => {
  const [courseData, loadingCourseData, courseID, courseError] = useCourseInfo(
    'ConvoHistoryForCourse',
  );

  if (loadingCourseData && !courseData) return <Icon name="circle notched" loading />;

  if (!courseData || !courseData.course) return <ErrorView data={courseData} error={courseError} />;

  const { course } = courseData;

  return (
    <Header>
      Browse and search sessions
      <Header.Subheader>
        For {course.name} ({course.code})
      </Header.Subheader>
    </Header>
  );
};

const ConvoBrowseContainer = ({ courseID }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoadingNextPage, setIsLoadingNextPage] = useState(false);
  const [convoStateForQuery, setConvoStateForQuery] = useState('any');
  const [sortOrder, setSortOrder] = useState('createdDesc');
  const [hasNextPage, setHasNextPage] = useState(true);
  const {
    loading, error, data: convoHistoryData, fetchMore,
  } = useQuery(
    ConvoHistoryQuery,
    {
      variables: {
        courseID,
        offset: 0,
        limit: PAGE_SIZE,
        convoState: convoStateForQuery,
        sortOrder,
      },
      skip: !courseID,
    },
  );
  // TODO: useEffect if we change to searchQuery

  const infiniteRef = useInfiniteScroll({
    loading: loading || isLoadingNextPage,
    hasNextPage,
    onLoadMore: () => {
      const nextPage = currentPage + 1;
      setCurrentPage(nextPage);
      setIsLoadingNextPage(true);
      fetchMore({
        variables: {
          offset: nextPage * PAGE_SIZE,
          limit: PAGE_SIZE,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          setIsLoadingNextPage(false);
          if (!fetchMoreResult || !fetchMoreResult.course || !prev.course) return prev;
          const newConvs = fetchMoreResult.course.conversations || [];
          if (!newConvs.length || newConvs.length < PAGE_SIZE) {
            setHasNextPage(false);
          }
          const course = {
            ...prev.course,
            conversations: [...prev.course.conversations, ...newConvs],
          };
          return { ...prev, course };
        },
      });
    },
  });

  if (error) Logging.error('ConvoHistory', 'Browse', error);

  const { conversations = [] } = (!!convoHistoryData && convoHistoryData.course) || {};

  return (
    <>
      <div
        ref={infiniteRef}
        style={{
          display: 'flex',
          flexFlow: 'row',
          marginBottom: '1em',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <p>
            Results (showing {conversations.length})
          </p>
        </div>
        <ConvoStateDropdown
          convoStateForQuery={convoStateForQuery}
          setConvoStateForQuery={setConvoStateForQuery}
        />
        &nbsp;
        <ConvoSortDropdown setSortOrder={setSortOrder} sortOrder={sortOrder} />
      </div>

      {/* View as: <Icon name="bars" /> <Icon name="bars" /> */}
      <ConvoHistoryAsCards convos={conversations} />
      {isLoadingNextPage && <Fade>Loading Sessions...</Fade>}
    </>
  );
};

// I am so sorry for the copy pasta
const ConvoSearchContainer = ({ courseID, searchQuery }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoadingNextPage, setIsLoadingNextPage] = useState(false);
  const [sortOrder, setSortOrder] = useState('createdDesc');
  const [hasNextPage, setHasNextPage] = useState(true);

  const {
    loading, error, data: convoSearchData, fetchMore,
  } = useQuery(
    ConvoSearchQuery,
    {
      variables: {
        courseID,
        offset: 0,
        limit: PAGE_SIZE,
        searchQuery,
        sortOrder,
      },
      skip: !courseID || !searchQuery,
    },
  );
  // TODO: useEffect if we change to searchQuery

  const infiniteRef = useInfiniteScroll({
    loading: loading || isLoadingNextPage,
    hasNextPage,
    onLoadMore: () => {
      const nextPage = currentPage + 1;
      setCurrentPage(nextPage);
      setIsLoadingNextPage(true);
      fetchMore({
        variables: {
          offset: nextPage * PAGE_SIZE,
          limit: PAGE_SIZE,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          setIsLoadingNextPage(false);
          if (
            !fetchMoreResult
            || !fetchMoreResult.searchConversations
            || !prev
            || !prev.searchConversations
          ) return prev;
          const newConvs = fetchMoreResult.searchConversations || [];
          if (!newConvs.length || newConvs.length < PAGE_SIZE) {
            setHasNextPage(false);
          }
          const newPrev = {
            ...prev,
            searchConversations: [...prev.searchConversations, ...newConvs],
          };
          return newPrev;
        },
      });
    },
  });

  Logging.debug('ConvoHistory', convoSearchData);
  if (error) Logging.error('ConvoHistory', 'Search', error);

  const { searchConversations: conversations = [] } = convoSearchData || {};

  return (
    <>
      <div
        ref={infiniteRef}
        style={{
          display: 'flex',
          flexFlow: 'row',
          marginBottom: '1em',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <p>
            Search Results (showing
            {conversations.length}
            )
          </p>
        </div>
        <ConvoSortDropdown setSortOrder={setSortOrder} sortOrder={sortOrder} />
      </div>

      {/* View as: <Icon name="bars" /> <Icon name="bars" /> */}
      <ConvoHistoryAsCards convos={conversations} />
      {isLoadingNextPage && <Fade>Loading Sessions...</Fade>}
    </>
  );
};

/**
 * ConvoHistory, provide a course ID
 *
 * @param courseID string
 */
const ConvoHistoryForCourse = ({ courseID }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedFunction] = useDebouncedCallback(
    (value) => {
      setSearchQuery(value);
    },
    300,
    // The maximum time func is allowed to be delayed before it's invoked:
    { maxWait: 1500 },
  );

  return (
    <div style={{ width: '100%' }}>
      <ConvoHistoryHeader />
      <div style={{ display: 'flex', flexFlow: 'column', marginBottom: '1em' }}>
        <Input
          fluid
          icon="search"
          placeholder="Search sessions topics and descriptions..."
          onChange={(ignore, { value }) => debouncedFunction(value)}
        />
      </div>
      {searchQuery ? (
        <ConvoSearchContainer courseID={courseID} searchQuery={searchQuery} />
      ) : (
        <ConvoBrowseContainer courseID={courseID} />
      )}
    </div>
  );
};

export default ConvoHistoryForCourse;
