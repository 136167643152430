import React from 'react';
import ConvoListView from '../ConvoCard/ConvoListView';
import BrowseConvsButton from '../common/BrowseConvsButton';
import MyConvsButton from '../common/MyConvsButton';

export default ({ resolvedConvs, courseID, staffVariant }) => (
  <>
    <h3>Resolved sessions</h3>
    <p>Keep track of your previous questions</p>
    {resolvedConvs.length ? (
      <ConvoListView conversations={resolvedConvs} actions={[]} />
    ) : (
      <p>No resolved sessions</p>
    )}
    {staffVariant && courseID && resolvedConvs.length === 5 && (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexFlow: 'column',
        }}
      >
        <p>...See the rest of your course&apos;s resolved sessions here</p>
      </div>
    )}
    <div>
      {staffVariant && !!courseID && <BrowseConvsButton courseID={courseID} />}
      {!!courseID && <MyConvsButton courseID={courseID} />}
    </div>
  </>
);
