/* App body content for an authenticated user */

import React, { useContext, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';

import { Responsive, Icon } from 'semantic-ui-react';
import ErrorBoundary from '../components/ErrorBoundary/ErrorBoundary';
import { CourseIDContext, UserContext } from '../components/Contexts';
import LoggedInBodyRouter from '../routes/LoggedInBodyRouter';
import RoleGate from '../components/common/RoleGate';
import ActiveTAsViewContainer from '../components/ActiveTAsView/ActiveTAsViewContainer';
import UpperMessages from './UpperMessages';
import NotificationsView from '../components/NotificationsView/NotificationsView';

import InviteCodeSection from '../components/InviteCode/InviteCodeSection';
import RequestPushSection from './RequestPushSection';
import { UserTaglineForm } from '../components/User/UserTaglineForm';
import * as Toasts from '../components/util/toast';

const CenterContent = ({ courseID, user }) => (
  <main className="centerContentBody">
    <div className="loggedInBody">
      <UpperMessages />
      <NotificationsView />
      <LoggedInBodyRouter user={user} courseID={courseID} />
    </div>
  </main>
);

const CollapseSidebar = ({ onCollapse }) => (
  <div tabIndex={0} role="button" onKeyDown={onCollapse} onClick={onCollapse} className="rightRailCollapse">
    Collapse sidebar <Icon name="chevron right" />
  </div>
);

const RightRail = ({ user }) => {
  const [isCollapsed, setCollapsed] = useState(false);

  if (isCollapsed) {
    return (
      <Responsive
        as="div"
        minWidth={920}
        onClick={() => setCollapsed(false)}
        className="rightRailCollapsed"
      >
        <Icon name="chevron left" />
      </Responsive>
    );
  }

  return (
    <>
      <Responsive as="div" minWidth={920} className="rightRail">
        <div className="rightRailContents">
          <RequestPushSection />
          <UserTaglineForm />
          <RoleGate user={user} ta instructor>
            <InviteCodeSection />
          </RoleGate>
          <ActiveTAsViewContainer />
        </div>
        <CollapseSidebar onCollapse={() => setCollapsed(true)} />
      </Responsive>
    </>
  );
};
const LoggedInBody = ({ renderSidebar }) => {
  const courseID = useContext(CourseIDContext);
  const user = useContext(UserContext);
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);

  const isZoomConf = searchParams.get('zoom');

  if (isZoomConf === 'true') {
    Toasts.successToast('Connected to Zoom');
    searchParams.delete('zoom');
    history.replace({
      ...location,
      search: searchParams.toString(),
    });
  }

  return (
    <>
      {renderSidebar && renderSidebar()}
      <ErrorBoundary>
        <CenterContent courseID={courseID} user={user} />
      </ErrorBoundary>
      {courseID && (
        <>
          <ErrorBoundary>
            <RightRail user={user} />
          </ErrorBoundary>
        </>
      )}
    </>
  );
};

export default LoggedInBody;
