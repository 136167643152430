import React, { useContext } from 'react';
import { useMutation } from '@apollo/react-hooks';

import { Button, Icon, Popup } from 'semantic-ui-react';
import {
  DequeueConversationMutation,
  InteractConversationMutation,
} from '../../api/queries.jsx';
import * as Contexts from '../Contexts.jsx';
import * as Logging from '../util/logging';
import { warningToast } from '../util/toast';
import { IConversation } from '../SharedLander/UnifiedInbox';

// TODO: logging
const onDequeueComplete = (data: any) => {
  Logging.info('DequeueButton', 'Session dequeue mutation complete', data);
};

const onDequeueError = (error: any) => {
  Logging.error('DequeueButton', 'Dequeue button mutation error', error);
  warningToast(
    'Something went wrong removing a session from the queue, please refresh the page and try again.'
  );
};

const dequeueMutationOptions = {
  onCompleted: onDequeueComplete,
  onError: onDequeueError,
  refetchQueries: [
    'StaffLanderQuery',
    'StudentLanderQuery',
    'RoomListQuery',
    'ActiveTAsQuery',
  ],
};

interface IDequeueButtonProps {
  convos?: IConversation[];
  disabled?: boolean;
  [x: string]: any;
  currentRoom?: any;
}

const DequeueButton: React.FC<Partial<IDequeueButtonProps>> = ({
  convos,
  disabled,
  currentRoom,
  ...rest
}) => {
  const courseID = useContext(Contexts.CourseIDContext);
  const roomID = useContext(Contexts.RoomIDContext);
  const user: any = useContext(Contexts.UserContext);

  const [interact, { loading: loadingInteractionMutation }] = useMutation(
    InteractConversationMutation,
    dequeueMutationOptions
  );

  const [
    dequeueConversation,
    { loading: loadingDequeueMutation },
  ] = useMutation(DequeueConversationMutation, dequeueMutationOptions);

  const loading = loadingInteractionMutation || loadingDequeueMutation;

  const onDequeueClick = () => {
    if (!convos?.length) {
      const variables = {
        room: roomID,
        course: courseID,
      };
      if (roomID && courseID) {
        dequeueConversation({ variables });
      }
    } else {
      const [convo] = convos;
      interact({
        variables: {
          conversation: convo?.id,
          interactionType: 'dequeue',
        },
      });
    }
  };

  return (
    <Popup
      open
      disabled={
        !currentRoom ||
        currentRoom.type !== 'virtual' ||
        user.isRegisteredForVOH
      }
      trigger={
        <Button
          onClick={() => {
            onDequeueClick();
          }}
          icon
          disabled={loading || disabled || user?.isAccountInfoRequired}
          {...rest}
        >
          Dequeue &nbsp; {!!convos?.length && `(${convos.length})`}
          {loading ? (
            <Icon name="circle notched" loading />
          ) : (
            <Icon name="check circle" />
          )}
        </Button>
      }
    >
      <a
        href={`https://zoom.us/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_ZOOM_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_API_URL}/voh`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src="https://marketplacecontent.zoom.us/zoom_marketplace/img/add_to_zoom.png"
          height="32"
          alt="Add to ZOOM"
        />
      </a>
    </Popup>
  );
};

export default DequeueButton;
