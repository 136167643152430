import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { generateRouteForRedirect } from './routing-utils';
import hasRole from '../components/util/role-utils';

export const RoleGatedRoute = ({
  component: Component,
  user,
  courseID,
  instructor,
  ta,
  student,
  exact,
  ...rest
}) => (
  <Route
    {...rest}
    exact={exact}
    render={(props) => {
      if (!courseID || !user || !user.role) {
        return (
          <Redirect
            to={{
              pathname: '/selectCourse',
              search: props.location.search,
              state: { from: props.location },
            }}
          />
        );
      }
      if (!hasRole(user, { instructor, ta, student })) {
        return (
          <Redirect
            to={{
              pathname: '/',
              search: props.location.search,
              state: { from: props.location },
            }}
          />
        );
      }
      // We're gucci if the user has the right role
      return <Component {...props} />;
    }}
  />
);

export const ProtectedRoute = ({ component: Component, hasAuth, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (hasAuth ? (
      <Component {...props} />
    ) : (
      <Redirect
        to={{
          pathname: generateRouteForRedirect('/login', props.location),
          search: props.location.search,
          state: { from: props.location },
        }}
      />
    ))}
  />
);

export const LoginRoute = ({ component: Component, hasAuth, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (!hasAuth ? (
      <Component {...props} />
    ) : (
      <Redirect
        to={{
          pathname: generateRouteForRedirect('/', props.location),
          search: props.location.search,
          state: { from: props.location },
        }}
      />
    ))}
  />
);
