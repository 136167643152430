import * as React from 'react';
import './UnifiedInbox.css';

import DequeueButton from '../common/DequeueButton';
import ClearAllButton from '../common/ClearAllButton';
import RoomsDropdown from '../Menus/RoomsDropdown';
import { SelectedConvView } from './SelectedConvView';
import DutyToggle from '../common/DutyToggle';
import { InboxItemCard } from './InboxItemCard';
import useWindowDimensions from '../util/useWindowDimensions';
import { Icon, Popup } from 'semantic-ui-react';

// TODO Abstract
export interface IConversation {
  id: string;
  room: any;
  tags: [any];
  created: Date;
  lastUpdated?: Date;
  topic: string;
  description: string;
  creator: any;
  interactions: [any];
}

interface IUnifiedInboxProps {
  enqueuedConversations: IConversation[];
  course: any;
  currentConversations?: IConversation[];
  currentRoom?: any;
}

interface IActionBarProps {
  checkboxedConvs: Set<IConversation>;
  isCurrentlyHelping: boolean;
  currentRoom?: any;
}

const ActionBar: React.FC<IActionBarProps> = ({
  checkboxedConvs,
  isCurrentlyHelping,
  currentRoom
}) => {
  return (
    <div className="action-bar">
      <div className="top-container">
        <div className="centeredFlex">
          <div style={{ marginRight: '.75em', display: 'flex' }}>
            <RoomsDropdown
              dropdownProps={{
                pointing: true,
                item: true,
                direction: 'right'
              }}
            />
          </div>
        </div>
        <div className="centeredFlex">
          <div style={{ marginRight: '.75em' }}>On duty:</div>
          <DutyToggle />
        </div>
      </div>
      <div className="action-buttons">
        <>
          <ClearAllButton
            labelPosition="right"
            size="tiny"
            disabled={isCurrentlyHelping || !checkboxedConvs?.size}
            convos={Array.from(checkboxedConvs)}
          />
          <DequeueButton
            currentRoom={currentRoom}
            labelPosition="right"
            size="tiny"
            disabled={isCurrentlyHelping || !checkboxedConvs?.size}
            color="blue"
            convos={Array.from(checkboxedConvs)}
          />
        </>
      </div>
    </div>
  );
};

export const UnifiedInbox: React.FC<IUnifiedInboxProps> = ({
  enqueuedConversations,
  course,
  currentConversations = [],
  currentRoom
}) => {
  const [checkboxedConvs, setCheckboxedConvs] = React.useState<
    Set<IConversation>
  >(new Set([]));

  React.useEffect(() => {
    const newConvs = new Set(
      Array.from(checkboxedConvs).filter(
        c =>
          currentConversations.map(c => c?.id).includes(c.id) ||
          enqueuedConversations.map(c => c?.id).includes(c.id)
      )
    );
    setCheckboxedConvs(newConvs);
  }, [currentConversations, enqueuedConversations, setCheckboxedConvs]);
  const { width } = useWindowDimensions();
  const isMobile = width < 768;

  // TODO: These are stopgaps until group dequeue/checkboxes are ready to go
  const [currentConversation] = currentConversations;
  const [selectedConv] = Array.from(checkboxedConvs);

  const onConvClick = (wasChecked: boolean, c: IConversation) => {
    const lWasChecked = checkboxedConvs.has(c);
    /* TODO: when we're ready for group dequeue, this is how we should filter the selected conv
    if (!lWasChecked) {
      setCheckboxedConvs(new Set([c, ...Array.from(checkboxedConvs)]));
    } else {
        setCheckboxedConvs(new Set([c, ...Array.from(checkboxedConvs)]));
      setCheckboxedConvs(
        new Set(
          Array.from(checkboxedConvs).filter(
            checkboxedConv => c.id !== checkboxedConv.id
          )
        )
      );
    }
    */
    if (!lWasChecked) {
      setCheckboxedConvs(new Set([c]));
    } else {
      setCheckboxedConvs(new Set());
    }
  };

  // TODO: change checkboxedConvs to be SOT
  const displayConv = selectedConv || currentConversation;

  return (
    <>
      {!isMobile && (
        <ActionBar
          checkboxedConvs={checkboxedConvs}
          isCurrentlyHelping={!!currentConversation}
          currentRoom={currentRoom}
        />
      )}
      <div className="inbox-container">
        <div className="conversation-list">
          {isMobile && (
            <ActionBar
              checkboxedConvs={checkboxedConvs}
              isCurrentlyHelping={!!currentConversation}
            />
          )}
          <div>
            {!!currentConversations.length && (
              <p className="inbox-label-container">
                <span className="inbox-label-inner">Current session</span>
              </p>
            )}
            <li>
              {currentConversations
                .filter(c => !!c && !!c.id)
                .map(c => (
                  <ul key={c.id}>
                    <InboxItemCard
                      conversation={c}
                      onClick={onConvClick}
                      active={c.id === displayConv?.id}
                    />
                  </ul>
                ))}
            </li>
            <p className="inbox-label-container">
              <span className="inbox-label-inner">
                New sessions ({enqueuedConversations.length || '0'})
              </span>
            </p>
            <li>
              {enqueuedConversations
                .filter(c => !!c && !!c.id)
                .map(c => (
                  <ul key={c.id}>
                    <InboxItemCard
                      conversation={c}
                      onClick={onConvClick}
                      active={c.id === displayConv?.id}
                    />
                  </ul>
                ))}
            </li>
            <div style={{ opacity: '0.8' }}>
              <InboxItemCard />
            </div>
            <div style={{ opacity: '0.55' }}>
              <InboxItemCard />
            </div>
            <div style={{ opacity: '0.3' }}>
              <InboxItemCard />
            </div>
          </div>
        </div>
        <div className="current-convo-container">
          {/* TODO: use the currentConversations list */}
          {
            !!displayConv && (currentConversation?.id === displayConv?.id ? 
            <div className="session-help-copy"><h3>Current session</h3> 
            
            <Popup
                  trigger={<div className="tooltip-icon"><Icon circular name="question" size="small" /></div>}
                  header="You are currently assisting this student"
                  content="You can not dequeue other conversations while helping this student."
                />
            </div>:            <div className="session-help-copy"><h3>Previewing session</h3> 
            
            <Popup
                  trigger={<div className="tooltip-icon"><Icon circular name="question" size="small" /></div>}
                  header="You are previewing a session"
                  content="You may dequeue this session if you are not currently helping any other student in an ongoing session."
                />
            </div> )
            
          }
          {!!displayConv && (
            <SelectedConvView
              conversation={displayConv}
              course={course}
              active={currentConversation?.id === displayConv?.id}
            />
          )}
          {!displayConv && !isMobile && (
            <div className="select-convo-box">
              <h3 className="next-action-box">
                {!enqueuedConversations?.length ? (
                  <>🥳 Your inbox is empty</>
                ) : (
                  <>👈 Preview a session</>
                )}
              </h3>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
