import React, { useContext } from 'react';
import {
  List, Header, Icon, Segment,
} from 'semantic-ui-react';
import { useQuery, useMutation } from 'react-apollo';

import { Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { PendingEnrollmentsQuery, ActivateEnrollment } from '../../api/queries';
import { NotifTypes, RoleOptions } from '../../types';
import * as Toasts from '../util/toast';
import * as Logging from '../util/logging';
import { UserContext } from '../Contexts';
import RoleGate from '../common/RoleGate';

import { INVITE_COOKIE_NAME } from '../../EntryPoint/AccountUpdate';

const onEnrollmentActivated = () => {
  Toasts.successToast("You've been successfully enrolled");
};

const onError = (error) => {
  console.error(error);
  Toasts.warningToast('Something went wrong ');
};

// TODO: remove type since it was supposed to be generalized
export const PendingEnrollmentNotification = ({ enrollment: e, type, onAccept }) => {
  const [activateEnrollment] = useMutation(ActivateEnrollment, {
    onCompleted: onEnrollmentActivated,
    onError,
    refetchQueries: [
      'PendingEnrollmentsQuery',
      'RoomListQuery',
      'ActiveTAsQuery',
      'SelectCourseQuery',
      'StudentLanderQuery',
      'StaffLanderQuery',
    ],
  });
  const roleOptionList = RoleOptions.filter((val) => val.key === e.type);
  const prettyRole = roleOptionList.length ? roleOptionList[0].text : '';

  const onAcceptWrapper = React.useCallback(() => {
    activateEnrollment({ variables: { courseID: e.course.id } });
    if (onAccept) {
      onAccept();
    }
  }, [activateEnrollment, onAccept, e]);

  if (!e && e.course && e.course.code && type) {
    return null;
  }

  return (
    <List.Item>
      <List.Icon name={type.iconName} size="large" verticalAlign="middle" />
      <List.Content>
        <List.Header>
          <strong>{e.course.code}</strong> {e.course.name && `(${e.course.name})`} course invite pending
        </List.Header>
        <List.Description>{prettyRole}</List.Description>
        <List.Description style={{ marginTop: '5px' }}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            onClick={onAcceptWrapper}
            onKeyDown={onAcceptWrapper}
            role="button"
            tabIndex={0}
          >
            <Icon name="check" /> Accept
          </a>
          {/* TODO
                      <a style={{ marginLeft: '15px' }}>
            <Icon name="delete" /> Decline
          </a>
          */}
        </List.Description>
      </List.Content>
    </List.Item>
  );
};

const CompleteYourAccountNotification = ({ user, type }) => (
  <Segment as="li">
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        lineHeight: '2',
      }}
    >
      <div>
        <div>
          <Icon name={type.iconName} color="orange" /> &nbsp;
          <strong>Please finish setting up your account</strong>
        </div>
        <RoleGate user={user} student>
          You won&apos;t be able to be seen until you finish setting up your
          account
        </RoleGate>
        <RoleGate user={user} ta instructor>
          You wont be able to assist students until you finish setting up
          your account
        </RoleGate>
      </div>
      <div>
        <Link to="/account">
          Update account <Icon name="right arrow" />
        </Link>
      </div>
    </div>
    {/* TODO
                  <a style={{ marginLeft: '15px' }}>
        <Icon name="delete" /> Decline
      </a>
      */}
  </Segment>
);

export const useNotificationData = () => {
  const user = useContext(UserContext);
  const cookies = useCookies(INVITE_COOKIE_NAME);
  const { data, error, refetch } = useQuery(PendingEnrollmentsQuery);

  React.useEffect(() => {
    refetch();
  }, [cookies, refetch]);

  if (error) {
    Logging.error('Pending enrollments error', error);
  }

  return [data?.pendingEnrollments, user];
};

const NotificationsView = ({ hideHeader, hideCompleteAccount, onAccept }) => {
  const [pendingEnrollments, user] = useNotificationData();

  if (!pendingEnrollments?.length && (hideCompleteAccount || !user?.isAccountInfoRequired)) {
    return null;
  }

  return (
    <>
      {!hideHeader ? <Header as="h3">Notifications</Header> : null}
      <List divided relaxed>
        {!!pendingEnrollments
          && pendingEnrollments.map((enrollment) => (
            <PendingEnrollmentNotification
              enrollment={enrollment}
              type={NotifTypes.enrollmentInvite}
              key={`${enrollment.type} ${enrollment.course
                && enrollment.course.name}`}
              onAccept={onAccept}
            />
          ))}
        {!!user.isAccountInfoRequired && !hideCompleteAccount && (
          <CompleteYourAccountNotification
            type={NotifTypes.completeAccount}
            user={user}
          />
        )}
      </List>
    </>
  );
};

export default NotificationsView;
