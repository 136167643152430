import React, { useState } from 'react';
import gql from 'graphql-tag';
import { Icon, Input } from 'semantic-ui-react';
import { useQuery } from 'react-apollo';
import { useInfiniteScroll } from 'react-infinite-scroll-hook';

import ErrorView from '../common/ErrorView';
import ConvoStateDropdown from './ConvoStateDropdown';
import * as Logging from '../util/logging';
import { ConvoHistoryFragment } from '../../api/queries';
import ConvoListView from '../ConvoCard/ConvoListView';
import ConversationTable from '../ConvoCard/ConversationTable';
import Fade from '../common/Fade';
import TagFilterDropdown from './TagFilterDropdown';
import ConvoSortDropdown from './ConvoSortDropdown';

const ConvoHistoryAsTable = ({ convos }) => (
  <ConversationTable convos={convos} actions={[]} />
);

const ConvoHistoryAsCards = ({ convos }) => (
  <ConvoListView two column conversations={convos} actions={[]} />
);

const PAGE_SIZE = 25;

const ConvoHistoryForUserQuery = gql`
  ${ConvoHistoryFragment}
  query ConvoHistoryQuery(
    $userID: String!
    $offset: Int!
    $limit: Int!
    $convoState: ConversationState!
    $courseID: String
    $sortOrder: SortOrder
  ) {
    user(ID: $userID) {
      __typename
      externalid
      fullname
      email
      username
      conversations(
        course: $courseID
        state: $convoState
        page: { offset: $offset, limit: $limit }
        sortOrder: $sortOrder
      ) {
        ...ConvoHistoryFragment
      }
    }
  }
`;

/**
 * ConvoHistory, provide a user ID and a course ID
 *
 * @param userID string
 * @param courseID string
 */
const ConvoHistoryForUser = ({ userID, courseID = null }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoadingNextPage, setIsLoadingNextPage] = useState(false);
  const [convoStateForQuery, setConvoStateForQuery] = useState('any');
  const [sortOrder, setSortOrder] = useState('createdAsc');
  const [hasNextPage, setHasNextPage] = useState(true);

  const {
    loading, error, data, fetchMore,
  } = useQuery(
    ConvoHistoryForUserQuery,
    {
      variables: {
        userID,
        courseID,
        offset: 0,
        limit: PAGE_SIZE,
        convoState: convoStateForQuery,
        sortOrder,
      },
      skip: !userID,
    },
  );

  const infiniteRef = useInfiniteScroll({
    loading: loading || isLoadingNextPage,
    hasNextPage,
    onLoadMore: () => {
      const nextPage = currentPage + 1;
      setCurrentPage(nextPage);
      setIsLoadingNextPage(true);
      fetchMore({
        variables: {
          offset: nextPage * PAGE_SIZE,
          limit: PAGE_SIZE,
          userID,
          courseID,
          convoState: convoStateForQuery,
          sortOrder,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          setIsLoadingNextPage(false);
          if (!fetchMoreResult || !fetchMoreResult.user || !prev.user) return prev;
          const newConvs = fetchMoreResult.user.conversations || [];
          if (!newConvs.length || newConvs.length < PAGE_SIZE) {
            setHasNextPage(false);
          }

          const user = { ...prev.user, conversations: [...prev.user.conversations, ...newConvs] };
          return { ...prev, user };
        },
      });
    },
  });

  if (error) Logging.error('ConvoHistoryForUser', error);
  if (loading && !data) return <Icon name="circle notched" loading />;

  if (!data?.user) return <ErrorView data={data} error={error} />;

  const { user } = data;
  const { conversations } = user;

  return (
    <div style={{ width: '100%' }} ref={infiniteRef}>
      {/*
      <div style={{ display: 'flex', flexFlow: 'column', marginBottom: '1em' }}>
        <Input
          fluid
          icon='search'
          disabled
          placeholder='(Coming soon) Search session topics and descriptions for this user...'
        />
      </div>
      */}
      <div
        style={{
          display: 'flex',
          flexFlow: 'row',
          marginBottom: '1em',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <p>
            Results (showing {conversations.length})
          </p>
        </div>
        <ConvoStateDropdown
          convoStateForQuery={convoStateForQuery}
          setConvoStateForQuery={setConvoStateForQuery}
        />
        &nbsp;
        <ConvoSortDropdown setSortOrder={setSortOrder} sortOrder={sortOrder} />
      </div>
      {/* View as: <Icon name="bars" /> <Icon name="bars" /> */}
      <ConvoHistoryAsCards convos={conversations} />
      {isLoadingNextPage && <Fade>Loading Sessions...</Fade>}
    </div>
  );
};

export default ConvoHistoryForUser;
