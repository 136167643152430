import React, { useState, useContext } from 'react';
import {
  Card,
  Header,
  Responsive,
  Form,
  Button,
  Icon,
} from 'semantic-ui-react';
import distanceInWordsToNow from 'date-fns/distance_in_words_to_now';
import { useMutation } from 'react-apollo';
import { Callout } from '@blueprintjs/core';
import { InteractConversationMutation } from '../../api/queries';

import * as Constants from '../../constants';
import DequeueButton from '../common/DequeueButton';
import UserName from '../common/UserName';
import InteractionButton from './InteractionButton';
import InteractionList from './InteractionList';
import * as Toasts from '../util/toast';
import Tags from './Tags';
import StudentTokensView from '../Tokens/StudentTokensView';
import useTokens from '../Tokens/useTokens';
import { UserContext } from '../Contexts';
import { ROLES, INTERACTIONS } from '../../types';

const onResolveError = (error) => {
  console.error(error);
  Toasts.warningToast('Something went wrong while leaving the queue');
};
const onResolve = () => Toasts.successToast('Your ticket is resolved.');

const onLeaveError = (error) => {
  console.error(error);
  Toasts.warningToast('Something went wrong while leaving the queue');
};
const onLeave = () => Toasts.successToast('You\'ve left the queue.');

const onRequeueError = (error) => {
  console.error(error);
  Toasts.warningToast('Something went wrong while requeueing a session');
};
const onRequeue = () => Toasts.successToast('Conversation requeued.');

export const CARD_ACTIONS = {
  dequeue: 'dequeue',
  leave: 'leave',
  resolve: 'resolve',
  requeue: 'requeue',
  reopen: 'reopen',
};

const StudentFollowupCopy = () => <p>Please describe your followup question</p>;
const StaffFollowupCopy = () => (
  <p>If applicable, please describe the follow up reason</p>
);

const RequeueForm = ({ convo }) => {
  const { role } = useContext(UserContext) || {};
  const { loading, tokens } = useTokens(role);
  const [comment, setComment] = useState('');
  const isStudent = role === ROLES.student;
  // TODO: toast?
  const [
    interact,
    { loading: loadingInteractionMutation, error },
  ] = useMutation(InteractConversationMutation, {
    refetchQueries: ['UsersForInstitution', 'UsersForCourse'],
  });

  return (
    <>
      {isStudent ? <StudentFollowupCopy /> : <StaffFollowupCopy />}
      <Form
        onSubmit={() => interact({
          variables: {
            conversation: convo.id,
            interactionType: 'requeue',
            comment,
          },
        })}
      >
        <Form.Input
          fluid
          loading={loadingInteractionMutation}
          action={{
            primary: true,
            basic: true,
            content: 'Re-queue Thread',
          }}
          disabled={loading || (isStudent && tokens != null && tokens <= 0)}
          value={comment}
          onChange={(ignore, { value }) => {
            setComment(value);
          }}
          placeholder={
            isStudent
              ? '(Optional) What do you need help with next?'
              : '(Optional) What is the follow up reason?'
          }
        />
      </Form>
      {isStudent && !loading && (
        <div style={{ marginTop: '10px' }}>
          <StudentTokensView numTokens={tokens} />
        </div>
      )}
    </>
  );
};

const CancelRequeueButton = (props) => (
  <Button basic floated="right" {...props}>
    Cancel re-queue <Icon style={{ paddingLeft: '6px' }} name="remove" />
  </Button>
);

const buttonProps = { primary: true, basic: true, floated: 'right' };

const CardActionButtons = ({ convo, actions, user }) => {
  const [isRequeueFormOpen, setIsRequeueFormOpen] = useState(false);

  return actions && actions.length ? (
    <>
      {isRequeueFormOpen ? (
        <Card.Content>
          <RequeueForm convo={convo} />
        </Card.Content>
      ) : null}

      <Card.Content>
        {user?.activeConversation ? (
          <>
            <Callout intent="warning">
              To re-queue, first resolve your active session in
              {' '}
              <strong>
                {user.activeConversation?.course?.name
                    || user.activeConversation?.course?.code}
              </strong>
              . You can only have one
              active help session at a time.
            </Callout>
            <br />
          </>
        ) : null}
        {actions.includes(CARD_ACTIONS.leave) ? (
          <InteractionButton
            onError={onLeaveError}
            interactionType="leave"
            text="Leave queue"
            convo={convo}
            onCompleted={onLeave}
            buttonProps={buttonProps}
          />
        ) : null}
        {actions.includes(CARD_ACTIONS.resolve) ? (
          <InteractionButton
            confirmMessage="Are you sure this issue is resolved?"
            onError={onResolveError}
            interactionType="resolve"
            text="Resolve"
            convo={convo}
            onCompleted={onResolve}
            buttonProps={buttonProps}
          />
        ) : null}

        {actions.includes(CARD_ACTIONS.requeue) && (
          <>
            {isRequeueFormOpen ? (
              <CancelRequeueButton
                onClick={() => setIsRequeueFormOpen(false)}
              />
            ) : (
              <InteractionButton
                onError={onRequeueError}
                interactionType="requeue"
                text="Re-queue"
                convo={convo}
                onCompleted={onRequeue}
                onClick={() => setIsRequeueFormOpen(true)}
                buttonProps={buttonProps}
                disabled={!!user?.activeConversation}
              />
            )}
          </>
        )}
      </Card.Content>
    </>
  ) : null;
};

const ConversationCard = ({
  convo,
  user = null,
  shouldDisplayInputs = false,
  actions = [],
  compact = false,
}) => {
  const {
    tags, created, topic, description, creator, interactions,
  } = convo;
  const createdDate = distanceInWordsToNow(new Date(created));
  const creatorName = (creator && creator.fullname) || Constants.DEFAULT_STUDENT_NAME;
  const creatorUserName = creator ? creator.externalid : null;
  const sortedInteractions = (interactions || []).sort(
    (c1, c2) => new Date(c2.created) - new Date(c1.created),
  );
  return (
    <Card fluid>
      <Card.Content>
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <Header style={{ marginTop: '-0.21425em', marginBottom: '0px' }}>
            {topic}
          </Header>
          {!!convo?.location && (
            <Responsive minWidth={480}>
              <Card.Meta style={{ whiteSpace: 'nowrap', marginLeft: '10px' }}>
                {convo.location}
              </Card.Meta>
            </Responsive>
          )}
        </div>
        <Card.Meta>
          {`Created ${createdDate} ago by `}
          <strong>
            <UserName
              style={{ color: 'gray' }}
              userName={creatorName}
              userID={creatorUserName}
            />
          </strong>
        </Card.Meta>
        <Card.Description>{description}</Card.Description>
        <br />
        {tags && tags.length ? (
          <Card.Description>
            <Tags tags={tags} />
          </Card.Description>
        ) : null}

        {actions && actions.length && actions[CARD_ACTIONS.dequeue] ? (
          <Card.Content extra>
            <DequeueButton basic color="blue" />
          </Card.Content>
        ) : null}
      </Card.Content>
      {shouldDisplayInputs ? (
        <Card.Content>
          <DequeueButton basic color="blue" />
        </Card.Content>
      ) : null}
      {!!interactions && !!interactions.length && (
        <Card.Content>
          <Header as="h5" style={{ marginBottom: '6px' }}>
            Latest session activity
          </Header>
          <InteractionList
            interactions={sortedInteractions}
            minSize={compact ? 1 : 3}
          />
        </Card.Content>
      )}
      {actions && actions.length ? (
        <CardActionButtons convo={convo} actions={actions} user={user} />
      ) : null}
    </Card>
  );
};

export default ConversationCard;
