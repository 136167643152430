import React, { useState, useEffect, useContext } from 'react';
import { useQuery, useMutation } from 'react-apollo';
import {
  Header,
  Loader,
  Form,
  Button,
  Statistic,
  Modal,
} from 'semantic-ui-react';

import UserStatusSection from './UserStatusSection';
import ConvoHistoryForUser from '../ConvoHistoryViews/ConvoHistoryForUser';
import FeedbackHistory from '../Feedback/FeedbackHistory';
import * as Contexts from '../Contexts';
import RoleGate from '../common/RoleGate';
import ErrorView from '../common/ErrorView';
import Collapsable from '../common/Collapsable';
import { UserDrilldownQuery, CourseOverviewQuery } from '../../api/queries';
import * as Logging from '../util/logging';
import { RoleOptions } from '../../types';
import { bifurcate, maybePrepend } from '../util/copy';
import UserEditForm from './UserEditForm';
import useUpdateUser from './useUpdateUser';

const CannotFindUserView = () => (
  <Header>Whoops, looks like we can&apos;t find this user</Header>
);

const UserAnalytics = () => (
  <Statistic.Group size="small" widths={3} style={{ margin: '1.5em 0' }}>
    <Statistic>
      <Statistic.Value>n/a</Statistic.Value>
      <Statistic.Label>Total visits</Statistic.Label>
    </Statistic>

    <Statistic>
      <Statistic.Value>n/a</Statistic.Value>
      <Statistic.Label>Visits per week</Statistic.Label>
    </Statistic>

    <Statistic>
      <Statistic.Value>n/a</Statistic.Value>
      <Statistic.Label>Knowledgeability</Statistic.Label>
    </Statistic>
  </Statistic.Group>
);

const UserEditButton = (props) => (
  <Button basic color="blue" {...props}>
    Edit
  </Button>
);

const Separator = () => <div style={{ padding: '.5em' }} />;

const itemStyles = { margin: '1em 0' };

const UserDrilldown = ({
  me,
  user,
  user: {
    email, username, fullname, enrollments = [], externalid,
  },
  course: { id: courseID, code } = {},
}) => {
  const [localUser, setLocalUser] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const { pending, type } = enrollments.find((e) => e.course.id === courseID) || {};
  const displayName = fullname || username || email;
  const [editUser] = useUpdateUser();
  return (
    <>
      <Header size="large">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {displayName} <UserStatusSection user={user} pending={pending} />
        </div>
        {!!type && (
          <Header.Subheader>
            {code} {(RoleOptions.find((r) => r.key === type) || {}).text}
          </Header.Subheader>
        )}
      </Header>
      <Collapsable
        name="About"
        expandedOnMount
        itemStyles={itemStyles}
        renderRightContent={() => (
          <RoleGate user={me} instructor>
            <div>
              <UserEditButton
                disabled={isEditing}
                onClick={() => setIsEditing(true)}
              />
            </div>
          </RoleGate>
        )}
      >
        <UserEditForm
          isEditing={isEditing}
          user={user}
          onEditingCanceled={() => setIsEditing(false)}
          onSubmitted={() => {
            editUser(localUser);
            setIsEditing(false);
          }}
          onChange={(newUser) => setLocalUser(newUser)}
          user={user}
        />
      </Collapsable>
      <Separator />
      <Collapsable name={maybePrepend(code, 'Analytics')} expandedOnMount>
        <UserAnalytics user={user} />
      </Collapsable>
      <Collapsable
        name="Feedback"
        expandedOnMount={!pending}
        itemStyles={itemStyles}
      >
        {!pending ? (
          <FeedbackHistory student={user} />
        ) : (
          <p>
            This user has not accepted their course invite yet. Users cannot
            create sessions if they have not accepted their course invite
          </p>
        )}
      </Collapsable>
      <Separator />
      <Collapsable
        name={bifurcate(`${displayName}'s`, code, 'Sessions')}
        expandedOnMount
        itemStyles={itemStyles}
      >
        {!pending ? (
          <ConvoHistoryForUser userID={externalid} courseID={courseID} />
        ) : (
          <p>
            This user has not accepted their course invite yet. Users cannot
            create sessions if they have not accepted their course invite
          </p>
        )}
      </Collapsable>
      <RoleGate instructor />
      {/* <div>TODO: add admin switch option</div> */}
    </>
  );
};

const hasUserDrilldownPrivs = (me) => me && me.role && (me.role === 'instructor' || me.role === 'ta');

/* Usage: must have React router props: <Route component={UserDrilldownView} /> */
const UserDrilldownView = ({
  history,
  match: { params: { userID, courseID } } = { params: {} },
}) => {
  const me = useContext(Contexts.UserContext);
  const { loading: loadingUser, error: errorUser, data: dataUser } = useQuery(
    UserDrilldownQuery,
    {
      variables: { id: userID },
      errorPolicy: 'all',
      skip: !userID || !hasUserDrilldownPrivs(me),
    },
  );

  const {
    loading: loadingCourse,
    error: errorCourse,
    data: dataCourse,
  } = useQuery(CourseOverviewQuery, {
    variables: { courseID },
    errorPolicy: 'all',
    skip: !courseID,
  });

  if (errorUser) Logging.error('UserDrilldown', 'UserQuery', errorUser);
  if (errorCourse) Logging.error('UserDrilldown', 'CourseQuery', errorCourse);

  if (!userID) {
    return <CannotFindUserView />;
  }
  if (loadingUser || loadingCourse) return <Loader />;
  if (!dataUser) {
    return (
      <ErrorView
        error={errorUser}
        data={({ ...dataUser, ...dataCourse })}
      />
    );
  }
  if (!dataUser.user) {
    return <CannotFindUserView />;
  }
  const { user } = dataUser;
  const { course } = dataCourse || {};
  return (
    <>
      <Modal
        open
        closeIcon
        onClose={() => {
          history.goBack();
        }}
      >
        <Modal.Content scrolling style={{ maxHeight: 'calc(90vh - 10em)' }}>
          <UserDrilldown me={me} user={user} course={course} />
        </Modal.Content>
      </Modal>
    </>
  );
};

export default UserDrilldownView;
