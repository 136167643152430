import React from 'react';
import { Segment, Icon } from 'semantic-ui-react';
import ConvoListView from '../ConvoCard/ConvoListView';

export default ({ ongoingConvs, text, user }) => (
  <>
    <h3>{text || 'Your ongoing onversations'}</h3>
    {ongoingConvs.length ? (
      <>
        <ConvoListView
          conversations={ongoingConvs}
          actions={['requeue', 'resolve']}
          user={user}
        />
      </>
    ) : (
      <Segment padded>
        <div style={{ display: 'flex' }}>
          <Icon name="check" color="green" /> You&apos;re all caught up!
        </div>
      </Segment>
    )}
  </>
);
