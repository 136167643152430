import * as Logging from '../util/logging';

const doFlow = (email, recaptcha, regEndpoint, verifyEndpoint) => {
  const formData = new FormData();
  formData.append('email', email);
  formData.append('recaptcha', recaptcha);

  fetch(regEndpoint, {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    body: formData,
  })
    .then((res) => {
      if (res.status === 200) {
        window.location.href = verifyEndpoint;
      } else {
        Logging.error('Registration', res);
      }
    })
    .catch((e) => Logging.error('Registration', 'caught exception', e));
};

export const doSignup = (email, recaptcha) => {
  doFlow(
    email,
    recaptcha,
    `${process.env.REACT_APP_API_URL}/register`,
    `${process.env.PUBLIC_URL}/verify?email=${email}`,
  );
};

export const doLogin = (email, recaptcha) => {
  doFlow(
    email,
    recaptcha,
    `${process.env.REACT_APP_API_URL}/emailLogin`,
    `${process.env.PUBLIC_URL}/verify?type=login&email=${email}`,
  );
};
