import React from 'react';
import { hydrate, render } from 'react-dom';
import './index.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import 'semantic-ui-less/semantic.less';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/browser';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://04295a13120e48f19246b19dbd3ee674@sentry.io/2276347'
  });
}

const rootElement = document.getElementById('q-root');
if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}

serviceWorker.unregister();
