import React from 'react';
import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';

import { Button, Icon, Popup } from 'semantic-ui-react';
import { successToast, warningToast } from '../util/toast';
import * as Logging from '../util/logging';

const onCompleted = (data) => {
  successToast('Successfully removed room from course');
};

const onError = (error) => {
  Logging.error('UnassocRoomButton', error);
  warningToast('Something went wrong removing this room from this course');
};

const UnassignRoom = gql`
  mutation UnassignRoomMutation($courseID: String!, $roomID: String!) {
    unassignRoom(course: $courseID, room: $roomID) {
      success
      message
    }
  }
`;

const UnassocRoomButton = ({ roomID, courseID, ...rest }) => {
  const [unassignRoom] = useMutation(UnassignRoom, {
    onError,
    onCompleted,
    refetchQueries: ['CourseDrilldownQuery'],
  });

  return roomID && courseID ? (
    <Button
      onClick={() => {
        // eslint-disable-next-line no-restricted-globals
        const ok = window.confirm(
          'Are you sure you want to unassign this room from this course?',
        );
        if (!ok) return;
        unassignRoom({ variables: { roomID, courseID } });
      }}
      icon
      {...rest}
    >
      {'Remove room from course '}
      <Icon name="trash" />
    </Button>
  ) : null;
};

export default UnassocRoomButton;
