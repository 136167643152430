import React, { useContext } from 'react';
import {
  List, Segment, Header, Icon,
} from 'semantic-ui-react';
import { distanceInWords } from 'date-fns';
import Collapsable from '../common/Collapsable';
import { UserContext } from '../Contexts';
import MaybeNoRoomsView from '../common/MaybeNoRoomsView';
import { DEFAULT_COURSE_STAFF } from '../../constants';

const getDuration = (date) => {
  const time = date.split('.')[0];
  const dur = new Date(`1970-01-01T${time}Z`);
  const base = new Date('1970-01-01T00:00:00Z');

  return distanceInWords(dur, base);
};

const RoomListItem = ({ room }) => (
  <>
    <Segment style={{ margin: '.75em 0' }}>
      <div
        style={{
          display: 'flex',
          flexFlow: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Header
          size="small"
          icon="map marker alternate"
          content={room?.location}
          subheader={
              room?.queue?.size === 0
                ? 'No enqueued students'
                : `Students in queue: ${room.queue.size}`
            }
        />
      </div>
      {room?.queue?.waitTime != null && (
      <div style={{ paddingTop: '.5em' }}>
        {`Wait time: ~${getDuration(room.queue.waitTime)}`}
      </div>
      )}

      <List>
        {!!room.tas && !!room.tas.length && (
        <List.Item>Available staff:</List.Item>
        )}
        {room.tas && room.tas.length ? (
          room.tas.map((ta) => (
            <List.Item
              style={{ marginTop: '.5em' }}
              key={`ta-list-${room.id}-${ta.externalid}`}
            >
              <Icon name="user" style={{ marginRight: '.25em' }} />
              {ta.fullname || ta.username || DEFAULT_COURSE_STAFF}
            </List.Item>
          ))
        ) : (
          <List.Item>No staff on duty</List.Item>
        )}
      </List>
    </Segment>
  </>
);

const SelectACourse = () => (
  <Segment style={{ margin: '.75em 0' }}>
    Please select a course to view its rooms
  </Segment>
);

const ActiveTAsView = ({ rooms, course }) => {
  const user = useContext(UserContext);
  return (
    <div>
      <Collapsable hiddenIcon expandedOnMount name="Space rooms & staff">
        {!rooms.length && <MaybeNoRoomsView user={user} course={course} />}
        {rooms.map((room) => (
          <RoomListItem key={`room-${room.id}`} room={room} />
        ))}
        {!course && <SelectACourse />}
      </Collapsable>
    </div>
  );
};

export default ActiveTAsView;
