import React, { useState, useContext } from 'react';
import { Segment, Icon } from 'semantic-ui-react';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';

import { Link } from 'react-router-dom';
import * as Logging from '../util/logging';
import { CourseIDContext, UserContext } from '../Contexts';
import Collapsable from '../common/Collapsable';
import RoleGate from '../common/RoleGate';

const CreateInviteCodeMutation = gql`
  mutation CreateInviteCode($course: String!, $role: EnrollmentType!) {
    createInviteCode(course: $course, role: $role)
  }
`;

const GetInviteCodesQuery = gql`
  query GetInviteCode($course: String!) {
    student: getInviteCode(course: $course, role: student)
    ta: getInviteCode(course: $course, role: ta)
    instructor: getInviteCode(course: $course, role: instructor)
  }
`;

/**
 *
 * @param {string} type "Student", "TA", "Advisor", "Organizor", etc
 * @param {string} inviteCode Will display "Click to reveal" if this is falsey/null
 * @param {(string) => void} onRevealClick Invite code reveal requested
 */
const InviteCodeBlock = ({
  type = 'Student',
  inviteCode,
  onRevealClick = () => {},
  refreshButton = false,
}) => (
  <Segment padded>
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      {type} entry code
      {inviteCode ? (
        <div style={{ alignItems: 'right' }}>
          <span style={{ fontSize: '1.2em', fontWeight: 'bold' }}>
            {inviteCode}
          </span>
          {refreshButton && (
          <Icon
            name="sync"
            style={{
              marginLeft: '.5em',
              cursor: 'pointer',
              fontSize: '0.75em',
            }}
            onClick={onRevealClick}
          />
          )}
        </div>
      ) : (
        <span
          onClick={() => onRevealClick(type)}
          onKeyDown={() => onRevealClick(type)}
          className="darkgray"
          role="button"
          tabIndex={0}
          style={{ fontSize: '0.9em', cursor: 'pointer' }}
        >
          Click to reveal
        </span>
      )}
    </div>
  </Segment>
);

export default ({ refreshButton = false }) => {
  const courseID = useContext(CourseIDContext);
  const user = useContext(UserContext);

  const [createCode] = useMutation(CreateInviteCodeMutation, {
    refetchQueries: ['GetInviteCode'],
  });
  const { loading, data } = useQuery(GetInviteCodesQuery, {
    variables: { course: courseID, role: 'student' },
    skip: !courseID,
  });

  const [studentInviteCode, setStudentInviteCode] = useState(null);
  const [taInviteCode, setTaInviteCode] = useState(null);
  const [instrInviteCode, setInstrInviteCode] = useState(null);

  if (loading || !courseID) {
    return null;
  }

  return (
    <>
      <Collapsable expandedOnMount name="Invite staff & students">
        <div>
          Users can enter this course using an invite code upon registration.
          {' '}
          {refreshButton && (
            <span>
              Press refresh to <strong>permanently invalidate</strong> an existing code.
            </span>
          )}
        </div>
        <InviteCodeBlock
          refreshButton={refreshButton}
          type="Student"
          inviteCode={studentInviteCode || data?.student}
          onRevealClick={() => {
            // eslint-disable-next-line no-alert
            const ok = window.confirm(
              'Are you sure you want to permanently invalidate this invite code and generate a new one?',
            );
            if (!ok) return;
            createCode({
              variables: { course: courseID, role: 'student' },
            })
              .then((resp) => setStudentInviteCode(resp.data.createInviteCode))
              .catch((err) => Logging.error('Error creating invite code', err));
          }}
        />
        <RoleGate user={user} instructor>
          <InviteCodeBlock
            refreshButton={refreshButton}
            type="Assistant"
            inviteCode={taInviteCode || data?.ta}
            onRevealClick={() => {
              createCode({
                variables: { course: courseID, role: 'ta' },
              })
                .then((resp) => setTaInviteCode(resp.data.createInviteCode))
                .catch((err) => Logging.error('Error creating invite code', err));
            }}
          />
          <InviteCodeBlock
            refreshButton={refreshButton}
            type="Instructor"
            inviteCode={instrInviteCode || data?.instructor}
            onRevealClick={() => {
              createCode({
                variables: { course: courseID, role: 'instructor' },
              })
                .then((resp) => setInstrInviteCode(resp.data.createInviteCode))
                .catch((err) => Logging.error('Error creating invite code', err));
            }}
          />
        </RoleGate>

        {!refreshButton && (
          <Link to={`/${courseID}/manage/users`}>
            Manage invite codes and users
          </Link>
        )}
      </Collapsable>
    </>
  );
};
