import React, { useContext } from 'react';
import {
  Header, Item, Icon, Segment,
} from 'semantic-ui-react';
import { Query, useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import { SetOffDutyMutation } from '../../api/queries';

import * as Toasts from '../util/toast';
import * as Contexts from '../Contexts';
import ErrorView from '../common/ErrorView';
import MaybeNoRoomsView from '../common/MaybeNoRoomsView';

const SwitchRooms = ({ course }) => (
  <Header as="h2">
    Switch rooms in
    {course.code}
  </Header>
);

const JoinARoom = ({ course }) => (
  <Header as="h2">
    Join a {course.code} room
  </Header>
);

const switchRoomToast = (roomLoc) => Toasts.successToast(`You've joined ${roomLoc}`);

const RoomOption = ({
  room,
  course,
  currentRoomID,
  onRoomSelected,
  isOnDuty,
  history,
}) => {
  const [setOffDutyRemote] = useMutation(SetOffDutyMutation, {
    onCompleted: () => isOnDuty && Toasts.successToast('You\'re now off duty.'),
    refetchQueries: ['RoomListQuery', 'ActiveTAsQuery'],
  });

  return (
    <Item
      as="a"
      onClick={() => {
        onRoomSelected(room.id);
        switchRoomToast(room.location);
        if (isOnDuty) {
          setOffDutyRemote({
            variables: {
              roomId: currentRoomID,
              courseId: course && course.id,
            },
          });
        }

        history.push('/');
      }}
    >
      <Item.Content>
        <Item.Header>{room.location || ''}</Item.Header>
        {!!room.name && <Item.Meta>{room.name}</Item.Meta>}
      </Item.Content>
    </Item>
  );
};

const SelectRoom = ({
  rooms,
  currentRoomID,
  onRoomSelected,
  course,
  user,
  isOnDuty,
  history,
}) => (
  <>
    {currentRoomID ? (
      <SwitchRooms course={course} />
    ) : (
      <JoinARoom course={course} />
    )}
    <Item.Group divided>
      {rooms
        && rooms.map((room) => (
          <RoomOption
            onRoomSelected={onRoomSelected}
            history={history}
            room={room}
            course={course}
            currentRoomID={currentRoomID}
            isOnDuty={isOnDuty}
            key={`room-opt-${room.id}`}
          />
        ))}
    </Item.Group>
    <MaybeNoRoomsView
      user={user}
      course={course}
      hasRooms={rooms && rooms.length}
    />
  </>
);

const SelectRoomQuery = gql`
  query SelectRoomQuery($courseID: String!) {
    user {
      isOnDuty(course: $courseID)
    }
    course(ID: $courseID) {
      id
      name
      code
      rooms {
        location
        name
        id
        queue {
          size
          conversations {
            id
            created
            creator {
              externalid
              fullname
            }
            topic
            description
            interactions {
              created
              id
              type
            }
          }
        }
      }
    }
  }
`;

const SelectRoomView = (externalProps) => {
  const courseID = useContext(Contexts.CourseIDContext);
  const currentRoomID = useContext(Contexts.RoomIDContext);
  const user = useContext(Contexts.UserContext);
  const { onRoomSelected } = useContext(Contexts.AppContext);
  return (
    <Query query={SelectRoomQuery} variables={{ courseID }}>
      {({ loading, error, data }) => {
        if (loading) return <Icon name="circle notched" loading />;
        if (!data || !data.course || !data.course.rooms) {
          return <ErrorView error={error} data={data} />;
        }
        const { course, user: dataUser } = data;
        const isOnDuty = dataUser && dataUser.isOnDuty;

        return (
          <SelectRoom
            onRoomSelected={onRoomSelected}
            currentRoomID={currentRoomID}
            course={course}
            rooms={course.rooms}
            user={({ ...user, ...dataUser })}
            isOnDuty={isOnDuty}
            {...externalProps}
          />
        );
      }}
    </Query>
  );
};

export default SelectRoomView;
