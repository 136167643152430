import React, { useState, useEffect } from 'react';
import { useMutation } from 'react-apollo';
import { Popup } from 'semantic-ui-react';
import { SetOnDutyMutation, SetOffDutyMutation } from '../../api/queries';

import * as Logging from '../util/logging';
import * as Toasts from '../util/toast';

import Toggle from './Toggle';
import useOnDuty from '../util/useOnDuty';

const onSetOnDuty = () => Toasts.successToast('You\'re now on duty.');
const onSetOffDuty = () => Toasts.successToast('You\'re now off duty.');

const OFF_DUTY_MESSAGE = "Please go off duty when you're done hosting office hours";
const createTooltipText = (currentRoomID, isOnDuty) => {
  if (!currentRoomID) return ['Please select a room to change duty status'];
  if (!isOnDuty) {
    return [
      "Let students know you're available in this room.",
      OFF_DUTY_MESSAGE,
    ];
  }
  return ["Let students know you've left this room.", OFF_DUTY_MESSAGE];
};

const DutyToggle = ({ currentRoomID, currentCourseID, isOnDuty }) => {
  const [isOnDutyLocal, setIsOnDutyLocal] = useState(isOnDuty);

  useEffect(() => {
    setIsOnDutyLocal(isOnDuty);
  }, [isOnDuty]);

  const [setOnDutyRemote, { error: onDutyError }] = useMutation(
    SetOnDutyMutation,
    {
      onCompleted: onSetOnDuty,
      refetchQueries: ['DutyQuery', 'RoomsQuery', 'ActiveTAsQuery'],
    },
  );
  const [setOffDutyRemote, { error: offDutyError }] = useMutation(
    SetOffDutyMutation,
    {
      onCompleted: onSetOffDuty,
      refetchQueries: ['DutyQuery', 'RoomsQuery', 'ActiveTAsQuery'],
    },
  );

  if (onDutyError) {
    Logging.error('DutyToggle', 'onDutyError', onDutyError);
  }

  if (offDutyError) {
    Logging.error('DutyToggle', 'offDutyError', offDutyError);
  }

  // TODO: consider pulling out this func
  const onDutyToggle = React.useCallback(() => {
    if (!currentRoomID) return;
    if (!isOnDutyLocal) {
      setOnDutyRemote({
        variables: {
          roomId: currentRoomID,
          courseId: currentCourseID,
        },
      });
    } else {
      setOffDutyRemote({
        variables: {
          roomId: currentRoomID,
          courseId: currentCourseID,
        },
      });
    }
    setIsOnDutyLocal(!isOnDutyLocal);
  }, [currentRoomID, isOnDutyLocal, currentCourseID, setOffDutyRemote, setOnDutyRemote]);

  const [header, content] = createTooltipText(currentRoomID, isOnDuty);
  return (
    <Popup
      position="bottom center"
      hoverable
      header={header}
      content={content}
      trigger={(
        <div>
          <Toggle
            label="Toggle duty status"
            onClick={onDutyToggle}
            toggled={isOnDutyLocal}
          />
        </div>
      )}
    />
  );
};

const DefaultToggle = () => (
  <Popup
    position="bottom center"
    hoverable
    header="Please select a room"
    content="Let students know you're available by going on duty. You must select your room first."
    trigger={(
      <div>
        <Toggle label="Toggle duty status" toggled={false} />
      </div>
    )}
  />
);
export default () => {
  const [loading, error, data, currentCourseID, currentRoomID] = useOnDuty();

  if (error) Logging.error('DutyToggle', error, data);

  if (loading || !currentCourseID || !currentRoomID || !data || !data.user) {
    return <DefaultToggle />;
  }

  const { isOnDuty } = data.user;

  return (
    <DutyToggle
      currentCourseID={currentCourseID}
      currentRoomID={currentRoomID}
      isOnDuty={isOnDuty}
    />
  );
};
