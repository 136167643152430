import React, { useState, useEffect } from 'react';
import { useSubscription } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import useSound from 'use-sound';
import { Responsive, Segment, Icon } from 'semantic-ui-react';
import ConvoListView from '../ConvoCard/ConvoListView';
import CreateConversation from '../CreateConversationViews/CreateConversation';
import CourseHeader from '../common/CourseHeader';
import OngoingConvs from './OngoingConvs';
import ResolvedConvs from './ResolvedConvs';
import * as Logging from '../util/logging';
import ConversationState from '../util/conv-utils';
import ActiveTAsViewContainer from '../ActiveTAsView/ActiveTAsViewContainer';
import Collapsable from '../common/Collapsable';
import ConversationCard from '../ConvoCard/ConversationCard';
import { successToast } from '../util/toast';
import { QueueSubscription } from '../../api/queries';
import { localPushNotification } from '../../api/realtime';
import { CurrentHelperHeader } from './CurrentHelperHeader';
import notificationSound from './notification.mp3';

export const ConvoSubscription = gql`
  subscription convo($convoID: String!) {
    convoSubscription(convoID: $convoID)
  }
`;

const getConversations = (convs) => {
  const conversations = [...convs];
  conversations.sort((c1, c2) => new Date(c2.created) - new Date(c1.created));

  const enqueuedConvs = conversations.filter(
    (c) => c.state === ConversationState.in_queue,
  );
  const beingHelpedConvs = conversations.filter(
    (c) => c.state === ConversationState.being_helped,
  );

  const ongoingConvs = conversations.filter(
    (c) => c.state === ConversationState.ongoing,
  );
  const resolvedConvs = conversations.filter(
    (c) => c.state === ConversationState.resolved,
  );

  return [enqueuedConvs, beingHelpedConvs, ongoingConvs, resolvedConvs];
};

const StudentUpperContent = ({
  ongoingConvs,
  user,
  positionInQueue,
  course,
  enqueuedConvs,
  beingHelpedConvs,
  currentResponder,
  currentRoomForConversation,
  demo,
}) => {
  // If the student already has a conversation in the queue, don't let them join it.
  if (ongoingConvs && ongoingConvs.length) return null;

  const currentRoomLocation = (currentRoomForConversation && currentRoomForConversation.location) || '';

  const currentRoomName = (currentRoomForConversation && currentRoomForConversation.name) || '';

  if (beingHelpedConvs && beingHelpedConvs.length) {
    return (
      <>
        <CurrentHelperHeader
          ta={currentResponder}
          meetingURL={beingHelpedConvs[0] && beingHelpedConvs[0].meetingURL}
        />
        <div style={{ marginTop: '1em' }}>
          <ConversationCard actions={['resolve']} convo={beingHelpedConvs[0]} />
        </div>
      </>
    );
  } if (enqueuedConvs && enqueuedConvs.length) {
    /* const roomCopy =
      currentRoomName !== currentRoomLocation
        ? `${currentRoomLocation} (${currentRoomName})`
        : currentRoomLocation;
        */
    const roomCopy = currentRoomName || currentRoomLocation;
    if (!positionInQueue) {
      Logging.error('StudentLander', 'No position in queue');
    }
    let positionCopy = '';
    if (positionInQueue === 1) {
      positionCopy = ' next';
    } else {
      positionCopy = ` #${positionInQueue}`;
    }
    return (
      <>
        <Segment>
          <div
            style={{
              fontSize: '1.2em',
              justifyContent: 'space-between',
              display: 'flex',
            }}
          >
            <div>
              <Icon name="bullhorn" style={{ marginRight: '.5em' }} />
              You are <strong style={{ color: 'black' }}>{positionCopy}</strong> in the queue
            </div>
            <div style={{ color: 'gray' }}>{roomCopy}</div>
          </div>
        </Segment>
        <ConvoListView conversations={enqueuedConvs} actions={['leave']} />
      </>
    );
  }
  return <CreateConversation course={course} user={user} demo={demo} />;
};

const StudentLanderContents = ({
  user = {},
  data = { course: {} },
  courseID,
  demo,
  refetch,
}) => {
  const { course } = data;
  const { rooms = [], session } = course;
  const convs = user.conversations || [];

  const [
    enqueuedConvs,
    beingHelpedConvs,
    ongoingConvs,
    resolvedConvs,
  ] = getConversations(convs);

  const [firstEnqueuedConvo] = enqueuedConvs;

  const roomIDFromQueue = firstEnqueuedConvo && firstEnqueuedConvo.room
    ? firstEnqueuedConvo.room.id
    : null;

  const [currentRoomForConversation] = rooms.filter(
    (room) => room.id === roomIDFromQueue,
  );

  useSubscription(QueueSubscription, {
    variables: { courseID, roomID: roomIDFromQueue },
    fetchPolicy: 'no-cache',
    onSubscriptionData: () => {
      try {
        if (typeof refetch === 'function') refetch();
      } catch (err) {
        Logging.error('StudentLanderContents', 'Subscription error', err);
      }
    },
    skip: roomIDFromQueue === null,
  });

  const positionInQueue = (currentRoomForConversation
      && currentRoomForConversation.queue
      && currentRoomForConversation.queue.position)
    || -1;

  const [lastPositionInQueue, setLastPositionInQueue] = useState(
    positionInQueue,
  );

  const [firstBeingHelpedConvo] = beingHelpedConvs;
  const [firstOngoingConvo] = ongoingConvs;

  const currentResponder = firstBeingHelpedConvo && firstBeingHelpedConvo.currentResponder;

  useSubscription(ConvoSubscription, {
    variables: { convoID: firstBeingHelpedConvo?.id || firstOngoingConvo?.id },
    fetchPolicy: 'no-cache',
    onSubscriptionData: () => {
      try {
        if (typeof refetch === 'function') refetch();
      } catch (err) {
        Logging.error('StudentLanderContents', 'Subscription error', err);
      }
    },
    skip: !firstBeingHelpedConvo && !firstOngoingConvo,
  });

  const [playNotification] = useSound(notificationSound);

  useEffect(() => {
    if (currentResponder && positionInQueue < 0 && lastPositionInQueue > 0) {
      localPushNotification(
        `You're ready to be seen${
          currentResponder?.fullname ? ` by ${currentResponder.fullname}` : ''} in ${course.code}`,
      );
      successToast(
        <>
          <h4>You&apos;ve been dequeued!</h4>
          <div style={{ marginTop: '.5em' }}>
            {(currentResponder && currentResponder.fullname)
              || 'A staff member'} is ready to see you.
          </div>
        </>,
      );
      playNotification();
    }
    setLastPositionInQueue(positionInQueue);
  }, [positionInQueue]);

  return (
    <>
      <CourseHeader course={course} session={session} />
      <StudentUpperContent
        course={course}
        user={user}
        enqueuedConvs={enqueuedConvs}
        ongoingConvs={ongoingConvs}
        beingHelpedConvs={beingHelpedConvs}
        positionInQueue={positionInQueue}
        currentResponder={currentResponder}
        currentRoomForConversation={currentRoomForConversation}
        demo={demo}
      />
      <Responsive as={ActiveTAsViewContainer} maxWidth={919} />
      <OngoingConvs
        text="Sessions awaiting your action"
        ongoingConvs={ongoingConvs}
        user={user}
      />
      {!!resolvedConvs?.length && (
        <ResolvedConvs
          courseID={course && course.id}
          resolvedConvs={resolvedConvs}
        />
      )}
    </>
  );
};

export default StudentLanderContents;
