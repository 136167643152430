import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Popup } from 'semantic-ui-react';

import RoleGate from './RoleGate';
import { createUserURL } from '../util/url-utils';
import { UserContext, CourseIDContext } from '../Contexts';
import useWindowDimensions from '../util/useWindowDimensions';

const UserName = ({ userName = 'Unnamed student', userID = null, style }) => {
  const me = useContext(UserContext);
  const courseID = useContext(CourseIDContext);
  const { width } = useWindowDimensions();
  // Not enough context to create a clickable name but enough to return just the username
  if (!me || !userID) {
    return <>{userName}</>;
  }
  return (
    <>
      <RoleGate user={me} ta instructor>
        <Popup
          disabled={width < 1024}
          header={userName}
          content="View previous questions and user info"
          trigger={(
            <Link style={style} to={createUserURL(userID, courseID)}>
              {userName}
            </Link>
          )}
        />
      </RoleGate>
      <RoleGate user={me} student>
        {userName}
      </RoleGate>
    </>
  );
};

export default UserName;
