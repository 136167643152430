import * as React from 'react';
import UserName from '../common/UserName';
import TATimer, { isPastTimeLimit } from './TATimer';
import UserImage from '../User/UserImage';

const TEN_MINS = 10 * 60 * 1000; /* ms */
const TWENTY_MINS = TEN_MINS * 2;

interface ICurrentUserHeader {
  currentStudentName: string;
  dequeueTime?: Date;
  waitTime?: Date;
  student: any;
  withModal?: boolean;
}

export const CurrentUserHeader: React.FC<Partial<ICurrentUserHeader>> = ({
  currentStudentName,
  dequeueTime,
  waitTime,
  student,
  withModal = false
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap'
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <UserImage width={100} url={student.photoUrl} />
        <div style={{ marginLeft: '1em' }}>
          <h3>
            <UserName
              style={
                withModal ? { color: 'black', textDecoration: 'underline' } : {}
              }
              userName={currentStudentName}
              userID={!!withModal && student.externalid}
            />
          </h3>
          {student.tagline || 'Student'}
          {student.location || ''}
        </div>
      </div>

      {!!dequeueTime && (
        <TATimer
          initialTime={dequeueTime}
          isExtraStrong={isPastTimeLimit(dequeueTime, TEN_MINS)}
        />
      )}
      {!!waitTime && <TATimer initialTime={waitTime} state="Entered" />}
    </div>
  );
};
