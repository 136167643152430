import React from 'react';
import { Card } from 'semantic-ui-react';
import UnassocRoomButton from './UnassocRoomButton';

const CoursesForRoom = ({ courses }) => (
  <Card.Description>
    Courses using this room:
    <br />
    {courses.map((course) => (
      <span key={course.id}>{`${course.code} `}</span>
    ))}
  </Card.Description>
);

const NoCoursesRoom = () => (
  <Card.Description>No courses are using this room</Card.Description>
);

const OtherCourseInfo = ({ room }) => (room.courses && room.courses.length ? (
  <CoursesForRoom courses={room.courses} />
) : (
  <NoCoursesRoom />
));

// ... rest is just for the onClick
const RoomCard = ({
  room,
  withOtherCourses,
  course: { id: courseID } = {},
  deleteable,
  ...rest
}) => (
  <Card color="blue" {...rest}>
    <Card.Content>
      <Card.Header>{room.location}</Card.Header>
      {!!room.name && <Card.Meta>{room.name}</Card.Meta>}
      {!!withOtherCourses && <OtherCourseInfo room={room} />}
    </Card.Content>
    {deleteable && courseID && (
      <Card.Content extra>
        <UnassocRoomButton courseID={courseID} roomID={room.id} fluid basic />
      </Card.Content>
    )}
  </Card>
);

export default RoomCard;
