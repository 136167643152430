import React, { useState } from 'react';
import { Feed, Header, Icon } from 'semantic-ui-react';
import distanceInWordsToNow from 'date-fns/distance_in_words_to_now';
import { INTERACTIONS } from '../../types';

const InteractionSummary = ({ intType, responderName }) => {
  const { text, orientation } = INTERACTIONS[intType] || {
    text: null,
    orientation: null,
  };

  if (!text) return null;

  if (orientation === 'after') {
    return (
      <>
        <strong>{responderName}</strong> {text}
      </>
    );
  }

  return (
    <>
      {text} <strong>{responderName}</strong>
    </>
  );
};

const Interaction = ({
  interaction: {
    type, responder, created, comment,
  },
  // This is hacky, should be in css
  withBorder,
}) => (
  <Feed.Event
    style={
      withBorder
        ? { borderBottom: '1px solid #eaeaef', paddingBottom: '.4em' }
        : {}
    }
  >
    <Feed.Label>
      <Icon name={INTERACTIONS[type].icon} />
    </Feed.Label>
    <Feed.Content style={{ margin: '.27em' }}>
      <InteractionSummary
        intType={type}
        responderName={
          responder && responder.fullname !== ''
            ? responder.fullname
            : 'Unnamed student '
        }
      />
      <span style={{ color: 'gray' }}>
        {` ${distanceInWordsToNow(
          created,
        )} ago`}
      </span>
      {comment && (
        <Feed.Extra text>
          <span style={{ color: 'gray' }}>Description: </span>
          {comment}
        </Feed.Extra>
      )}
    </Feed.Content>
  </Feed.Event>
);

const COLLAPSE_INTERACTION_SIZE = 3;

const InteractionList = ({
  interactions = [],
  minSize = COLLAPSE_INTERACTION_SIZE,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const displayInteractions = interactions.slice(
    0,
    isExpanded ? interactions.length : minSize,
  );
  return (
    <>
      <Feed>
        {displayInteractions.length
          && displayInteractions.map((inter, index) => (
            <Interaction
              key={inter.id}
              interaction={inter}
              withBorder={index < displayInteractions.length - 1}
            />
          ))}
        {interactions.length > minSize && (
          <Feed.Event style={{ fontSize: '.9em' }}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              style={{ marginLeft: '8px', marginTop: '.5em' }}
              onClick={() => {
                setIsExpanded(!isExpanded);
              }}
              onKeyDown={() => {
                setIsExpanded(!isExpanded);
              }}
              tabIndex={0}
              role="button"
            >
              {(!isExpanded && 'View More...') || 'View Less'}
            </a>
          </Feed.Event>
        )}
      </Feed>
    </>
  );
};

export default InteractionList;
