export const localPushNotification = (body, courseCode) => {
  if (!('Notification' in window)) return;

  // TODO: Use ServiceWorkerRegistration.showNotification()
  try {
    new Notification(`${courseCode} Appointment | Quuly`, {
      body
    });
  } catch (e) {
    if (e.name == 'TypeError') return false;
  }
};
