import React, { useState, useContext } from 'react';
import ReactCodeInput from 'react-code-input';
import { Grid, Segment, Header } from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
import useDarkBg from '../util/useDarkBg';
import LogoSignature from '../common/LogoSignature';

import './VerifyUser.css';
import { UserContext } from '../Contexts';

const SHARED_VERIFICATION_PROPS = {
  fontFamily: 'monospace',
  margin: '4px',
  MozAppearance: 'textfield',
  width: '40px',
  borderRadius: '7px',
  fontSize: '30px',
  height: '50px',
  paddingLeft: '7px',
};
const verificationProps = {
  inputStyle: {
    color: 'black',
    border: '1px solid lightskyblue',
    ...SHARED_VERIFICATION_PROPS,
  },
  inputStyleInvalid: {
    color: 'red',
    border: '1px solid red',
    ...SHARED_VERIFICATION_PROPS,
  },
};

const ValidateInput = (props) => {
  const [verificationCode, setVerificationCode] = useState();
  const [hasError, setHasError] = useState(false);
  const user = useContext(UserContext);

  const doVerify = (code, token, regType) => {
    const formData = new FormData();
    formData.append('token', token);
    formData.append('regCode', code);

    const endpoint = regType === 'login' ? 'confirmLogin' : 'confirmReg';

    fetch(`${process.env.REACT_APP_API_URL}/${endpoint}`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      body: formData,
    })
      .then((res) => {
        if (res.status === 200) {
          window.location.href = `${process.env.PUBLIC_URL}${
            regType === 'login' ? '/' : '/welcome'
          }`;
        } else {
          setHasError(true);
        }
      })
      .catch((e) => setHasError(true));
  };

  const { location: { search } } = props;
  const searchParams = new URLSearchParams(search);
  const token = searchParams.get('token');
  const regType = searchParams.get('type');
  if (!token || !!user) {
    return <Redirect to="/" />;
  }

  return (
    <Segment>
      <Grid columns={1} stackable textAlign="center">
        <Grid.Row verticalAlign="middle">
          <Grid.Column width={10}>
            <Header>
              Please check your email
              <Header.Subheader>
                You should have received an email with a verificaion code.
              </Header.Subheader>
            </Header>
            {hasError && <div>Something went wrong...</div>}
            <Header as="h5">Verification Code</Header>
            <ReactCodeInput
              type="number"
              value={verificationCode}
              forceUpperCase
              autocomplete={false}
              fields={6}
              onChange={(value) => {
                setVerificationCode(value);
                if (value > 99999) {
                  doVerify(value, token, regType);
                }
              }}
              {...verificationProps}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

export default (props) => {
  useDarkBg();

  return (
    <div style={{ width: '100%' }}>
      <Grid
        textAlign="center"
        className="loginContainer"
        verticalAlign="middle"
      >
        <Grid.Column className="centerColumn">
          <LogoSignature
            className="loginHeader"
            centered
            style={{ width: '12.75em', marginBottom: '1em' }}
          />

          <ValidateInput {...props} />
        </Grid.Column>
      </Grid>
    </div>
  );
};
