import React from 'react';
import { Segment, Dimmer, Loader } from 'semantic-ui-react';
import LogoSignature from '../components/common/LogoSignature';
import Fade from '../components/common/Fade';

const LoadingView = ({ children }) => (
  <Dimmer.Dimmable
    as={Segment}
    dimmed
    className="appContainer"
    style={{ backgroundColor: '#044462' }}
  >
    <Dimmer active style={{ backgroundColor: '#044462' }}>
      <Loader size="big">
        <div
          style={{
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
          }}
        >
          <LogoSignature style={{ width: '6em', marginBottom: '.5em' }} />
          {children || 'Cleaning up the virtual classroom...'}
        </div>
      </Loader>
    </Dimmer>
  </Dimmer.Dimmable>
);

export default LoadingView;
