import React, { useContext, useEffect } from 'react';
import { AppContext } from '../components/Contexts';
import LoadingView from './LoadingView';

// Simple wrapper over our logout function
export default () => {
  const { onLogout } = useContext(AppContext);
  useEffect(() => {
    onLogout();
  }, [onLogout]);

  return <LoadingView>Logging you out...</LoadingView>;
};
