/* Toastify Docs: https://github.com/apvarun/toastify-js */
import { useEffect } from 'react';
import { Position, Toaster } from '@blueprintjs/core';

import debounce from './debounce';
import {
  getLastChangelistLog,
  getLastViewedVersion,
  setLastViewedVersion
} from './changelog';

/** Singleton toaster instance. Create separate instances for different options. */
export const AppToaster = Toaster.create({
  className: 'recipe-toaster',
  position: Position.BOTTOM,
  maxToasts: 3
});

const onSuccessToast = (message, timeout = 5000) => {
  AppToaster.show({ message, intent: 'success', icon: 'tick', timeout });
};

export const successToast = debounce(onSuccessToast, 100);

const onWarningToast = message => {
  AppToaster.show({ message, intent: 'warning', icon: 'warning sign' });
};

export const warningToast = debounce(onWarningToast, 100);

// TODO: connect onToastClick
const dismissableToast = (message, onToastClick) => {
  AppToaster.show({ message, intent: 'primary', icon: 'tick', timeout: 5000 });
};

export const useChangelogToast = history => {
  useEffect(() => {
    const lastChangelog = getLastChangelistLog();
    // Don't show a new version message if it's already been shown
    if (getLastViewedVersion() === lastChangelog) {
      return;
    }
    setLastViewedVersion(getLastChangelistLog());
    dismissableToast(`We've updated Quuly. Click here to learn more`, () => {
      if (history) history.push('/changelog');
    });
  }, []);
};
