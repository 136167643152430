import React from 'react';
import ConversationCard from './ConversationCard';

// actions: interaction types ['leave', 'enqueue', 'requeue', 'resolve', ...]

const createStyle = (two, column) => (two && column ? { marginBottom: '20px' } : {});
const createCardStyle = (two, column) => (two && column ? { marginBottom: '20px' } : { marginBottom: '20px' });
const ConvoListView = ({
  conversations, actions, two, column, user,
}) => (conversations && conversations.length ? (
  <div style={createStyle(two, column)}>
    {conversations
      .filter((convo) => !!convo)
      .map((convo) => (
        <div key={convo.id} style={createCardStyle(two, column)}>
          <ConversationCard user={user} convo={convo} actions={actions} />
        </div>
      ))}
  </div>
) : (
  <div
    style={{ display: 'flex', justifyContent: 'center', marginTop: '5em' }}
  >
    No sessions
  </div>
));

export default ConvoListView;
