import React, { useContext } from 'react';
import { Header, Icon } from 'semantic-ui-react';
import { Query } from 'react-apollo';

import gql from 'graphql-tag';

import * as Contexts from '../../Contexts.jsx';
import RoomsListView from './RoomsListView';
import RoomAddForm from './RoomAddForm';

const RoomsQuery = gql`
  query RoomsQuery($id: String!) {
    institution(ID: $id) {
      rooms {
        id
        name
        location
        type
        courses {
          id
          name
          code
        }
      }
    }
  }
`;

const AdminRoomView = ({ history }) => {
  const { institution: id } = useContext(Contexts.AppContext);
  return (
    <>
      <Header as="h2">Manage rooms</Header>
      <Header as="h3">Create a new room</Header>
      <RoomAddForm institutionID={id} />
      <Header as="h3">All rooms</Header>
      <Query query={RoomsQuery} variables={{ id }}>
        {({ loading, error, data }) => {
          if (loading) return <Icon name="circle notched" loading />;
          if (!data || error) return <p>GraphQL error</p>;
          let rooms = [];
          if (
            data
            && data.institution
            && data.institution.rooms
            && data.institution.rooms.length
          ) {
            rooms = data.institution.rooms;
          }
          return <RoomsListView rooms={rooms} />;
        }}
      </Query>
    </>
  );
};

export default AdminRoomView;
