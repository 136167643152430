import React from 'react';
import {
  Modal, Grid, Form, Icon, Popup,
} from 'semantic-ui-react';
import { Select, ItemRenderer } from '@blueprintjs/select';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { MenuItem, Button } from '@blueprintjs/core';
import { toSentenceCase } from '../util/string-utils';
import { SPACE_TYPES, TERMS } from '../../types';

import OnboardingActions from './OnboardingActions';
import { tokenOptions } from '../AdminViews/Course/CourseEditForm';

const SearchInstitutionQuery = gql`
  query SearchInstitutions($query: String!) {
    searchInstitutions(query: $query, limit: 10, offset: 0) {
      name
    }
  }
`;

declare type Institution = {
  name: string;
};

const InstitutionSelect = Select.ofType<Institution>();

interface ConfigureSpaceProps {
  setStage: (arg: string) => any;
  spaceConfig: any;
}
const ConfigureSpace: React.FC<ConfigureSpaceProps> = ({
  setStage,
  spaceConfig,
}) => {
  const [query, setQuery] = React.useState('');
  const [institutionError, setInstitutionError] = React.useState(false);
  const { data } = useQuery(SearchInstitutionQuery, {
    variables: { query },
  });
  const [
    [name, setName],
    [spaceType, setType],
    [code, setCode],
    [term, setTerm],
    [tokens, setTokens],
    [enableOpenEnrollment, setEnableOpenEnrollment],
    [year, setYear],
    [institution, setInstitution],
    finalize,
  ] = spaceConfig;

  const institutionRenderer: ItemRenderer<Institution> = (institution) => (
    <MenuItem
      key={institution.name}
      text={institution.name}
      onClick={() => setInstitution(institution.name)}
    />
  );

  const createInstitutionRenderer = (input: string) => (
    <MenuItem
      text={`Create ${input}`}
      key={input}
      onClick={() => setInstitution(input)}
    />
  );

  // @ts-ignore
  const { name: spaceName = 'course', nameLabel, codeLabel }: any = SPACE_TYPES[
    spaceType
  ];
  return (
    <>
      <Modal.Header>Configure your {spaceName}</Modal.Header>

      <Modal.Content>
        <Grid container stackable centered>
          <Grid.Column width={12}>
            {process.env.REACT_APP_IS_SAAS === 'true' ? (
              <>
                <h3>Institution</h3>
                {institutionError && (
                  <div style={{ color: 'orange' }}>
                    Please select an institution
                  </div>
                )}
                <InstitutionSelect
                  filterable
                  initialContent={
                    <MenuItem disabled text="Search institutions..." />
                  }
                  items={(data && data.searchInstitutions) || []}
                  createNewItemFromQuery={(input) => setInstitution(input)}
                  createNewItemRenderer={createInstitutionRenderer}
                  itemRenderer={institutionRenderer}
                  onQueryChange={(query) => setQuery(query)}
                  onItemSelect={(item) => {
                    if (typeof item === 'string') {
                      setInstitutionError(false);
                      setInstitution(item);
                    }
                  }}
                  popoverProps={{ usePortal: false }}
                >
                  <Button
                    style={{ width: '100%' }}
                    text={institution || 'Select an institution'}
                    rightIcon="double-caret-vertical"
                  />
                </InstitutionSelect>
              </>
            ) : null}
            <h3>{toSentenceCase(spaceName)} details</h3>
            <Form>
              <Form.Input
                fluid
                aria-label={nameLabel}
                label={nameLabel}
                placeholder="Organization of Programming Languages"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              <Form.Group widths="equal">
                <Form.Input
                  fluid
                  aria-label={codeLabel}
                  label={codeLabel}
                  placeholder="CMSC330"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  required
                />
                <Form.Select
                  fluid
                  aria-label="Academic Year"
                  label="Academic Year"
                  value={year}
                  options={[
                    { text: year, value: year },
                    { text: year + 1, value: year + 1 },
                  ]}
                  onChange={(e, { value }) => setYear(value)}
                />

                <Form.Select
                  fluid
                  aria-label="Term"
                  label="Term"
                  defaultValue="spring"
                  options={Object.values(TERMS)}
                  onChange={(e, term) => setTerm(term)}
                />
              </Form.Group>
              <h3>Preferences</h3>
              <Form.Group>
                <Form.Select
                  aria-label="Student daily visit limit"
                  label="Student daily visit limit"
                  options={tokenOptions}
                  value={tokens}
                  onChange={(e, { value }) => {
                    setTokens(value);
                  }}
                />
                <Popup
                  trigger={<Icon circular name="question" size="small" />}
                  header="Student visit limits"
                  content="Encourage student independence by limiting the number of times a student can ask a question each day"
                />
              </Form.Group>
              <Form.Group>
                <Form.Checkbox
                  aria-label="Invite code enrollment"
                  label="Invite code enrollment"
                  checked={enableOpenEnrollment}
                  disabled
                  onChange={() => setEnableOpenEnrollment(!enableOpenEnrollment)}
                />{' '}
                <Popup
                  trigger={<Icon circular name="question" size="small" />}
                  header="Open enrollment"
                  content="Allow students to join your space with an invite code"
                />
              </Form.Group>
              <Form.Checkbox
                aria-label="In-person passcode (coming soon)"
                label="In-person passcode (coming soon)"
                disabled
              />
            </Form>
          </Grid.Column>
        </Grid>
      </Modal.Content>
      <OnboardingActions
        onBack={() => {
          setStage('CREATE_SPACE');
        }}
        onProceed={() => {
          if (institution) {
            setStage('FINALIZE_SPACE');
          } else {
            setInstitutionError(true);
          }
        }}
        proceedDisabled={!(name && name.length && code && code.length)}
      />
    </>
  );
};

export default ConfigureSpace;
