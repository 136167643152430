import * as React from 'react';

/* This component takes a user and will conditionally render children by
   by any specified role */
const RoleGate = (props) => (props.user
  && props.user.role
  && (Object.keys(props).includes(props.user.role)
    || (props.roles || []).includes(props.user.role))
  ? props.children
  : null);

export default RoleGate;
