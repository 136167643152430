import * as React from 'react';
import { Tab, Tabs, TabId } from '@blueprintjs/core';
import { IConversation } from './UnifiedInbox';
import TAConversationInProgress from './TAConversationInProgress';
import FeedbackHistory from '../Feedback/FeedbackHistory';
import ConvoHistoryForUser from '../ConvoHistoryViews/ConvoHistoryForUser';
import ManagedUserEditForm from '../User/ManagedUserEditForm';
import { CurrentUserHeader } from './CurrentUserHeader';
import ConversationCard from '../ConvoCard/ConversationCard';
import useWindowDimensions from '../util/useWindowDimensions';

interface ISelectedConvViewProps {
  conversation: IConversation;
  active?: boolean;
  course: any;
}

const getDequeueTime = (convo: IConversation) => {
  if (!convo || !convo.interactions || !convo.interactions.length) return null;

  const [dequeue] = convo.interactions
    .filter(inter => inter.type === 'dequeue')
    .sort((a, b) => +new Date(b.created) - +new Date(a.created));

  if (!dequeue) return null;

  return dequeue.created;
};

const CurrentConversationView: React.FC<ISelectedConvViewProps> = ({
  conversation: convo,
  course,
  active
}) => {
  return active ? (
    <TAConversationInProgress convo={convo} courseID={course?.id} />
  ) : (
    <ConversationCard convo={convo} />
  );
};

export const SelectedConvView: React.FC<ISelectedConvViewProps> = ({
  conversation: convo,
  active = false,
  course
}) => {
  const [selectedTab, setSelectedTab] = React.useState<TabId>('currentConv');
  const { width } = useWindowDimensions();

  const currentStudent = convo?.creator;
  const currentStudentName = currentStudent
    ? currentStudent.fullname || currentStudent.email
    : 'Unnamed Student';
  const dequeueTime = convo ? getDequeueTime(convo) : null;
  const isMobile = width < 768;
  return (
    <>
      <CurrentUserHeader
        currentStudentName={currentStudentName}
        dequeueTime={dequeueTime}
        student={currentStudent}
        withModal={isMobile}
      />
      {!isMobile ? (
        <Tabs
          selectedTabId={selectedTab}
          onChange={(newTabId: TabId) => {
            setSelectedTab(newTabId);
          }}
          renderActiveTabPanelOnly
        >
          <Tab
            id="currentConv"
            title="Overview"
            panel={
              <CurrentConversationView
                conversation={convo}
                course={course}
                active={active}
              />
            }
          />
          <Tab
            id="feedbackHistory"
            title="Feedback History"
            panel={<FeedbackHistory student={currentStudent} />}
          />
          {/*Disabling until I fix the dupe bug */}
          <Tab
            id="prevConvs"
            title="Previous sessions"
            disabled
            panel={
              <ConvoHistoryForUser
                userID={currentStudent?.externalid}
                courseID={course.id}
              />
            }
          />
          <Tab
            id="profile"
            title={`Profile`}
            panel={<ManagedUserEditForm user={currentStudent} />}
          />
        </Tabs>
      ) : (
        <CurrentConversationView
          conversation={convo}
          course={course}
          active={active}
        />
      )}
    </>
  );
};
