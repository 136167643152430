import * as React from 'react';
import UserEditForm from './UserEditForm';
import useUpdateUser from './useUpdateUser';

export default ({ user }) => {
  if (!user) return null;
  const [editUser] = useUpdateUser();
  const [localUser, setLocalUser] = React.useState();
  return (
    <>
      <UserEditForm
        isEditing={false}
        user={user}
        onSubmitted={() => {
          editUser(localUser);
        }}
        onChange={(newUser) => setLocalUser(newUser)}
        user={user}
        withCompletionWarning={false}
      />
    </>
  );
};
