import React, { useContext } from 'react';
import { H5, ProgressBar } from '@blueprintjs/core';
import { Segment, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { UserContext } from '../Contexts';
import CollapsableSegment from '../CollapsableSegment/CollapsableSegment';

const SETUP_STEPS = [
  'createCourse',
  'accountSetup',
  'rooms',
  'tags',
  'students',
  'tas',
];
const NUM_STEPS = Object.keys(SETUP_STEPS).length;

const getSteps = () => {};

const TutorialStep = ({ url, text, complete = false }) => (
  <li
    style={{
      padding: '1em 0',
      borderTop: '1px solid #eaeaef',
    }}
  >
    <div style={{ display: 'flex' }}>
      {complete && (
      <Icon
        style={{ minWidth: '30px' }}
        name="check circle"
        size="large"
        color="blue"
      />
      )}
      {!complete && (
      <Icon
        style={{ minWidth: '30px' }}
        name="check"
        className="darkgray"
      />
      )}
      {!!complete && (
      <span
        className="darkgray"
        style={{
          fontSize: '1.05em',
          textDecoration: 'line-through',
        }}
      >
        {text}
      </span>
      )}
      {!complete && (
      <Link to={url} style={{ fontSize: '1.05em' }}>
        {text}
      </Link>
      )}
    </div>
  </li>
);

const StaffTutorial = ({ course }) => {
  const user = useContext(UserContext);
  const isAccountSetupComplete = !user?.isAccountInfoRequired;
  const hasEnoughTags = course?.tags?.length >= 3;
  // Now that we're virtual keep this true.
  const hasRooms = true; // course?.rooms?.length > 0;
  const hasStudents = course?.studentEnrollmentCount > 0;
  const hasTAs = course?.taEnrollmentCount > 0;

  const stepsRemaining = 1
    + [
      hasEnoughTags,
      hasRooms,
      hasStudents,
      hasTAs,
      isAccountSetupComplete,
    ].filter((step) => !!step).length;
  const percentRemaining = stepsRemaining / NUM_STEPS;

  // Only display this tutorial if the user is not done setup
  if (percentRemaining === 1) {
    return null;
  }

  return (
    <>
      <CollapsableSegment title="Getting started with Quuly">
        Hi {user.fullname || user.username || user.email}
        , please complete the
        following steps to make sure your Quuly space is ready for students and
        staff.
        <div style={{ marginTop: '1em' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              fontWeight: 'bold',
              margin: '0.5em 0',
            }}
          >
            <span>Progress</span>
            <span>
              {stepsRemaining} / {NUM_STEPS} tasks
            </span>
          </div>
          <ProgressBar
            animate={false}
            intent="success"
            value={percentRemaining}
            stripes={false}
          />
        </div>
        <div style={{ marginTop: '1.5em' }}>
          <ul>
            <TutorialStep
              text="Complete your account"
              complete={isAccountSetupComplete}
              url="/account"
            />
            <TutorialStep text="Create a space" complete />
            <TutorialStep
              text="Add a room"
              url={`/${course.id}/manage/rooms`}
              complete={hasRooms}
            />
            <TutorialStep
              text="Add three or more topic tags"
              complete={hasEnoughTags}
              url={`/${course.id}/manage/tags`}
            />
            <TutorialStep
              text="Invite additional staff (assistants, instructors, space leaders)"
              complete={hasTAs}
              url={`/${course.id}/manage/users`}
            />
            <TutorialStep
              text="Invite students"
              complete={hasStudents}
              url={`/${course.id}/manage/users`}
            />
          </ul>
        </div>
      </CollapsableSegment>
    </>
  );
};

export default StaffTutorial;
