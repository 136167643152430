import React from 'react';
import { Modal, Button, Grid } from 'semantic-ui-react';
import OnboardingActions from './OnboardingActions.tsx';
import { SPACE_TYPES } from '../../types';

const FinalizeSpace = ({ setStage, spaceConfig, onDone }) => {
  const [
    [name, setName],
    [spaceType, setType],
    [code, setCode],
    [term, setTerm],
    [tokens, setTokens],
    [enableInvites, setEnableInvites],
    [year, setYear],
    [institution, setInstitution],
  ] = spaceConfig;

  return (
    <>
      <Modal.Header>
        Finalize {SPACE_TYPES[spaceType].name} {code}
      </Modal.Header>

      <Modal.Content>
        <Grid container stackable centered>
          <Grid.Column verticalAlign="middle" width={10}>
            <h3>Does this look right?</h3>
            <div>
              {process.env.REACT_APP_IS_SAAS === 'true' && (
                <>
                  <h4>Institution</h4>
                  <p>{institution}</p>
                </>
              )}

              <h4>{SPACE_TYPES[spaceType].codeLabel}</h4>
              <p>{code}</p>
              <h4>{SPACE_TYPES[spaceType].nameLabel}</h4>
              <p>{name}</p>
              {!!term && (
                <>
                  <h4>Term:</h4>
                  <p>
                    {term.text} {year}
                  </p>
                </>
              )}
              <h5>
                Daily visit limit:
                {tokens || 'Unlimited'}
              </h5>
            </div>
          </Grid.Column>
        </Grid>
      </Modal.Content>
      <OnboardingActions
        onBack={() => {
          setStage('CONFIGURE');
        }}
        /*
        onProceed={() => {
          setStage('ABOUT_YOU');
        }}
        */
        onFinish={() => {
          onDone(spaceConfig);
        }}
      />
    </>
  );
};

export default FinalizeSpace;
