import React, { useContext } from 'react';
import { Header } from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
import { UserContext, CourseIDContext } from '../Contexts';
import ConvoHistoryForUser from './ConvoHistoryForUser';

export default () => {
  const user = useContext(UserContext);
  const courseID = useContext(CourseIDContext);

  if (!user || !courseID) return <Redirect to="/" />;

  return (
    <>
      <Header>My sessions</Header>
      <ConvoHistoryForUser userID={user.externalid} courseID={courseID} />
    </>
  );
};
