import React from 'react';
import UserPlaceholderImage from './UserPlaceholderImage';

interface UserImageProps {
  url?: string | null;
  width?: number;
  onUploadClick?: () => void;
}

/**
 * General user image component.
 * TODO: support reponsive image sizes
 *
 * @param url Renders a placeholder if no url is passed
 * @param width optional in px
 * @param onUploadClick
 */
const UserImage: React.FC<UserImageProps> = ({
  url,
  width = 140,
  onUploadClick
}) => {
  const imgWidth = width ? `${width}px` : '100%';
  const additionalProps = onUploadClick ? { cursor: 'pointer' } : {};

  if (!url)
    return <UserPlaceholderImage width={width} onUpload={onUploadClick} />;

  return (
    <>
      <img
        src={url}
        alt="Your photo"
        style={{
          borderRadius: '50%',
          height: imgWidth,
          width: imgWidth,
          ...additionalProps
        }}
        onClick={() => {
          if (onUploadClick) onUploadClick();
        }}
      />
    </>
  );
};

export default UserImage;
