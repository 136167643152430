import React from 'react';
import PrettyPrintJson from './PrettyPrintJson';

const ProdGqlErrorView = () => <></>;

// TODO: Conditional render on appEntryPoint
const DevGqlErrorView = ({ data, error, appEntryPoint }) => (
  <>
    <h3>Something went wrong. Please contact us at officehours-help@cs.umd.edu</h3>
    <p>Error:</p>
    <PrettyPrintJson data={error || 'No gql error'} />
    <p>Data:</p>
    <PrettyPrintJson data={data || 'No gql data'} />
  </>
);

// proptypes: data, error
const ErrorView = (props) => (process.env.NODE_ENV === 'production' ? (
  <ProdGqlErrorView {...props} />
) : (
  <DevGqlErrorView {...props} />
));

export default ErrorView;
