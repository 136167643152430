import React from 'react';
import { Modal, Button, Icon } from 'semantic-ui-react';

interface OnboardingProps {
  onBack?: () => void;
  onProceed?: () => void;
  renderProceedButton?: () => React.Component;
  onFinish?: () => void;
  proceedDisabled: boolean;
}

const OnboardingActions: React.FC<OnboardingProps> = ({
  onBack,
  onProceed,
  renderProceedButton,
  onFinish,
  proceedDisabled = false,
}) => (
  <>
    <Modal.Actions
      style={{
        textAlign: 'left',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      {!!onBack && (
      <Button basic onClick={onBack}>
        <Icon name="arrow left" /> Back
      </Button>
      )}
      {!!onProceed
          && (!renderProceedButton ? (
            <Button
              color="blue"
              disabled={!!proceedDisabled}
              onClick={onProceed}
            >
              Next
              <Icon name="arrow right" />{' '}
            </Button>
          ) : (
            renderProceedButton()
          ))}
      {!!onFinish && (
      <Button color="blue" onClick={onFinish}>
        <Icon name="checkmark" /> Finish
      </Button>
      )}
    </Modal.Actions>
  </>
);

export default OnboardingActions;
