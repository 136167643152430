import React, { useContext } from 'react';
import { Header, Icon, Button } from 'semantic-ui-react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { Link } from 'react-router-dom';

import CourseTable from './CourseTable';
import ErrorView from '../../common/ErrorView';
import * as Contexts from '../../Contexts.jsx';

const HeaderSection = () => (
  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    <Header as="h2">Manage courses</Header>
    <span>
      <Button
        as={Link}
        to="/admin/createCourse"
        content="+ New Course"
        primary
      />
    </span>
  </div>
);

const CoursesForInstitution = gql`
  query($id: String!) {
    institution(ID: $id) {
      id
      courses {
        name
        id
        code
        session {
          term
          year
        }
      }
    }
  }
`;

const AdminCourseView = ({ history }) => {
  const currentCourseID = useContext(Contexts.CourseIDContext);
  const { institution: id } = useContext(Contexts.AppContext);

  return (
    <>
      <HeaderSection />
      <Header as="h3">All courses</Header>

      <Query query={CoursesForInstitution} variables={{ id }}>
        {({ loading, error, data }) => {
          if (loading) return <Icon name="circle notched" loading />;
          if (!data || !data.institution || error) return <ErrorView data={data} error={error} />;
          const { institution } = data;

          return (
            <CourseTable
              institutionName={institution.name}
              courses={institution.courses}
              currentCourseID={currentCourseID}
              history={history}
            />
          );
        }}
      </Query>
    </>
  );
};

export default AdminCourseView;
