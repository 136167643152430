import { useEffect, useRef } from 'react';
import { shallowEqualArrays } from 'shallow-equal';

export default (course, tags, setTags, topic, desc) => {
  useEffect(() => {
    if (!course?.tags || !course?.tags?.length) return;
    const matches = [
      ...course.tags.filter(
        ({ tag }) =>
          topic.toLowerCase().includes(tag.toLowerCase()) ||
          desc.toLowerCase().includes(tag.toLowerCase())
      )
    ].map(t => t.id);
    const allTags = [...new Set([...matches, ...tags])];

    if (!shallowEqualArrays(tags, allTags) && tags?.length <= allTags.length) {
      setTags(allTags);
    }
  }, [topic, desc, course, setTags]);
};
