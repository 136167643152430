import React, { useState } from 'react';
import { Form, Input } from 'semantic-ui-react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import * as Logging from '../util/logging';
import ErrorMessage from '../common/ErrorMessage';
import { successToast } from '../util/toast';

const EnrollFromCode = gql`
  mutation enrollFromCode($code: String!) {
    enrollFromCode(code: $code) {
      success
      message
    }
  }
`;

const onEnrollCompleted = (history, data) => {
  successToast('You have been enrolled');
  history.replace(history.location.pathname.replace('/welcome', ''));
};

export default () => {
  const history = useHistory();
  const [inviteError, setInviteError] = useState('');
  const [inviteCode, setInviteCode] = useState('');
  const [enroll] = useMutation(EnrollFromCode, {
    onError: (data) => {
      Logging.error('InviteCode', data);
      setInviteError('Something went wrong');
    },
    onCompleted: (data) => {
      if (!data?.enrollFromCode?.success) {
        setInviteError('Invalid invite code');
      } else {
        onEnrollCompleted(history, data);
      }
    },
    refetchQueries: ['RootQuery', 'StudentLanderQuery', 'SelectCourseQuery'],
  });
  return (
    <div>
      <Form onSubmit={() => enroll({ variables: { code: inviteCode } })}>
        <Input
          fluid
          aria-label="Invite code"
          name="inviteCode"
          placeholder="Invite code"
          autoComplete="off"
          required
          action={{
            content: 'Enter',
            color: 'blue',
          }}
          value={inviteCode}
          onChange={(e) => setInviteCode(e.target.value.trim())}
        />
        {!!inviteError && <ErrorMessage message={inviteError} />}
      </Form>
    </div>
  );
};
