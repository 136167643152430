import React from 'react';
import { UserType } from './UserTypes';
import { Form } from 'semantic-ui-react';
import { successToast } from '../util/toast';

interface UserPasswordResetProps {
  user: UserType;
}

const UserPasswordReset: React.FC<UserPasswordResetProps> = ({ user }) => {
  const [oldPassword, setOldPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [error, setError] = React.useState('');

  const updatePassword = async () => {
    const formData = new FormData();
    formData.append('oldPassword', oldPassword);
    formData.append('newPassword', newPassword);
    formData.append('confirmPassword', confirmPassword);

    const res = await fetch(`${process.env.REACT_APP_API_URL}/updatePassword`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      body: formData
    });

    if (res.status == 200) {
      setOldPassword('');
      setNewPassword('');
      setConfirmPassword('');
      setError('');
      successToast('Password updated');
    } else {
      const errorText = await res.text();
      setError(errorText);
    }
  };

  return (
    <>
      <Form>
        {error ? (
          <div style={{ color: 'red', marginBottom: '1rem' }}>{error}</div>
        ) : null}
        <Form.Group widths="equal">
          <Form.Input
            onChange={e => setOldPassword(e.target.value)}
            type="password"
            fluid
            value={oldPassword}
            label="Current password"
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Input
            onChange={e => setNewPassword(e.target.value)}
            type="password"
            fluid
            value={newPassword}
            label="New password"
          />
          <Form.Input
            onChange={e => setConfirmPassword(e.target.value)}
            type="password"
            fluid
            value={confirmPassword}
            label="Confirm new password"
          />
        </Form.Group>
        <Form.Button onClick={updatePassword} type="submit">
          Update password
        </Form.Button>
      </Form>
    </>
  );
};

export default UserPasswordReset;
