import React from 'react';
import { Icon, Label } from 'semantic-ui-react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Callout } from '@blueprintjs/core';
import * as Toasts from '../util/toast';

const RemoveTagMutation = gql`
  mutation RemoveTag($tagID: String!, $courseID: String!) {
    removeTag(tagID: $tagID, course: $courseID) {
      message
    }
  }
`;

const onTagDeleteSuccess = () => Toasts.successToast('Tag successfully deleted');
const onTagDeleteError = () => Toasts.warningToast('Could not delete tag');

const DeleteableTag = ({ tag, courseID }) => {
  const [
    onRemoveTag,
    { loading: loadingOnDuty, error: onDutyError },
  ] = useMutation(RemoveTagMutation, {
    onCompleted: onTagDeleteSuccess,
    onError: onTagDeleteError,
    refetchQueries: ['CourseDrilldownQuery'],
  });
  return (
    <Label size="large" color="blue">
      {tag.tag}
      <Icon
        name="close"
        onClick={() => {
          // eslint-disable-next-line no-restricted-globals
          const ok = window.confirm(
            `Are you sure you want to delete tag ${tag.tag}?`,
          );
          if (!ok) return;
          onRemoveTag({ variables: { tagID: tag.id, courseID } });
        }}
      />
    </Label>
  );
};

// TODO: make an edit tag view, and unhardcode # of sessions with this tag
const TagsListView = ({ course }) => (
  <div style={{ marginBottom: '10px' }}>
    {course.tags.length ? (
      course.tags.map((tag) => (
        <span key={`tag-${tag.id}`} style={{ marginRight: '5px' }}>
          <DeleteableTag tag={tag} courseID={course.id} />
        </span>
      ))
    ) : (
      <>
        <div style={{ marginBottom: '1em' }}>No tags added.</div>
      </>
    )}
  </div>
);

export default TagsListView;
