import React, { useContext, useEffect } from 'react';
import gql from 'graphql-tag';
import { Icon } from 'semantic-ui-react';
import { useQuery, useSubscription } from 'react-apollo';
import ActiveTAsView from './ActiveTAsView';
import * as Contexts from '../Contexts';
import { RoomFragment } from '../../api/queries';
import ErrorView from '../common/ErrorView';

import * as Logging from '../util/logging';

const ActiveTAsQuery = gql`
  ${RoomFragment}
  query ActiveTAsQuery {
    user {
      enrollments {
        pending
        course {
          name
          id
          code
          message
          ...RoomParts
        }
      }
    }
  }
`;

const DutySubscription = gql`
  subscription roomDuty($courseID: String!) {
    dutySubscription(courseID: $courseID)
  }
`;

const ActiveTAsViewContainer = () => {
  const currentCourseID = useContext(Contexts.CourseIDContext);

  /* do not change fetchPolicy, otherwise we will get cache mismatches */
  const {
    loading, error, data, refetch,
  } = useQuery(ActiveTAsQuery, {
    variables: {},
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
  });

  useSubscription(DutySubscription, {
    variables: { courseID: currentCourseID },
    fetchPolicy: 'no-cache',
    onSubscriptionData: () => {
      try {
        if (typeof refetch === 'function') refetch();
      } catch (err) {
        Logging.error('ActiveTAsViewContainer', 'Subscription error', err);
      }
    },
  });

  if (loading) {
    return <Icon name="circle notched" loading />;
  }
  if (error) {
    Logging.error('ActiveTAsViewContainer', error, data);
  }
  if (!data || !data.user || !data.user.enrollments) {
    return <ErrorView error={error} data={data} />;
  }

  const courses = data.user.enrollments.map((e) => e.course);
  const [currentCourse] = courses.filter(
    (course) => course.id === currentCourseID,
  );

  if (!currentCourse || !currentCourse.rooms) {
    return null;
  }

  return <ActiveTAsView rooms={currentCourse.rooms} course={currentCourse} />;
};

export default ActiveTAsViewContainer;
