import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Message, List, Header } from 'semantic-ui-react';
import DismissableMessage from '../common/DismissableMessage';
import * as Toasts from '../util/toast';
import * as Logging from '../util/logging';

const CSV_MESSAGE_NAME = 'CSV_WELCOME_MESSAGE';
const CSV_UPLOAD_HEADER = 'How to upload a CSV';
const CSVUploadMessage = () => (
  <div style={{ padding: '8px' }}>
    <Header as="h4">{CSV_UPLOAD_HEADER}</Header>
    <List ordered>
      <List.Item>
        <List.Content style={{ marginBottom: '5px' }}>
          <strong>Required: </strong> Add a header to your CSV case insensitive:
        </List.Content>
        <List.Description>
          &quot;email,role&quot;
        </List.Description>
      </List.Item>

      <List.Item>
        <List.Content style={{ marginBottom: '5px' }}>
          Add student, TA, and/or instructor emails to your CSV
        </List.Content>
        <List.Description>
          We will use this to let them know you&apos;ve added them to your course
        </List.Description>
      </List.Item>
      <List.Item>
        <List.Content style={{ marginBottom: '5px' }}>
          Add their corresponding role
        </List.Content>
        <List.Description>
          Either: instructor, TA, or Student (case insensitive)
        </List.Description>
      </List.Item>
      <List.Item>
        Double check your columns and entries before uploading your CSV
      </List.Item>
    </List>
  </div>
);

function doUpload(file, course) {
  return new Promise((resolve, reject) => {
    const req = new XMLHttpRequest();
    req.withCredentials = true;

    req.addEventListener('progress', (event) => {
      Logging.info('CSVUpload', 'progress', event);
      resolve(req.response);
    });

    req.addEventListener('load', (event) => {
      Logging.info('CSVUpload', 'load', event, req.status, req.response);
      resolve(req.response);
    });

    req.addEventListener('error', (event) => {
      Logging.error('CSVUpload', event, req.response);
      reject(req.response);
    });

    const formData = new FormData();
    formData.append('file', file, file.name);
    if (course) formData.append('courseID', course);

    req.open('POST', `${process.env.REACT_APP_API_URL}/uploadCSV`);
    req.send(formData);
  });
}

const CSVUpload = ({ course, onUpload }) => {
  const [uploadError, setUploadError] = useState('');

  const onDrop = useCallback(
    (acceptedFiles) => {
      // Do something with the files
      Logging.info('CSVUpload', acceptedFiles);
      if (!acceptedFiles || !acceptedFiles.length) {
        Toasts.warningToast("We can't upload that file, sorry");
      }

      doUpload(acceptedFiles[0], course)
        .then(JSON.parse)
        .then((result) => {
          if (result.Success && !result.Warnings) {
            Toasts.successToast('CSV successfully read, uploading...');
          } else if (result.Success && result.Warnings) {
            Toasts.successToast(
              'CSV uploaded with warnings, please refresh to see new users',
            );
          } else {
            Toasts.warningToast('Error: Malformed CSV');
            setUploadError(
              'Please check the header and every row in the CSV to ensure each column is present and accurate',
            );
          }
          if (result.Warnings) {
            // TODO: sanitize
            setUploadError(result.Message.replace(/\n/g, '<br /><br />'));
          }
          Logging.info('CSVUpload', result.Message);
          if (result.Success && !result.Warnings) {
            onUpload();
          }
        })
        .catch((error) => Logging.error('CSVUpload', error));
    },
    [uploadError, setUploadError],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <>
      <DismissableMessage info name={CSV_MESSAGE_NAME}>
        <CSVUploadMessage />
      </DismissableMessage>
      {!!uploadError && (
        <Message warning>
          <div dangerouslySetInnerHTML={{ __html: uploadError }} />
        </Message>
      )}
      <a href="/example.csv">View sample CSV</a>
      <Message>
        <div style={{ height: '180px' }} {...getRootProps()}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop your comma separated CSV here ...</p>
          ) : (
            <p>Drag &apos;n&apos; drop a comma separated CSV here, or click to select</p>
          )}
        </div>
      </Message>
    </>
  );
};

export default CSVUpload;
