import React, { useState, useEffect } from 'react';
import { Transition } from 'react-transition-group';

const DEFAULT_DURATION = 300;

const createStyle = (duration = DEFAULT_DURATION) => ({
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
  width: '100%',
});

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

// TODO debug this shit
const Fade = ({ timeout = DEFAULT_DURATION, children, ...rest }) => {
  const [transitioned, setTransitioned] = useState(false);
  useEffect(() => {
    setTransitioned(true);
  }, []);
  return (
    <Transition in={transitioned} timeout={timeout} appear {...rest}>
      {(state) => (
        <div
          style={{
            ...createStyle(timeout),
            ...transitionStyles[state],
          }}
        >
          {children}
        </div>
      )}
    </Transition>
  );
};

export default Fade;
