const REDIRECT_PASSTHROUGH_ROUTES = ['/changelog'];

export function doesPathEndWithPassthru(pathname) {
  return REDIRECT_PASSTHROUGH_ROUTES.find(partialPath =>
    pathname.endsWith(partialPath)
  );
}

export function generateRouteForRedirect(targetPath, location) {
  // Falsey check
  if (location && location.pathname) {
    // Check to see if any passthrough whitelist paths are in the route
    const maybePassthru = doesPathEndWithPassthru(location.pathname);
    return maybePassthru ? `${targetPath}${maybePassthru}` : targetPath;
  }
  return targetPath;
}
