import React, { useContext } from 'react';
import { Header, Icon, Message } from 'semantic-ui-react';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import StudentLanderContents from '../SharedLander/StudentLanderContents';

import {
  SharedLanderFragment,
  StudentLanderCourseFragment,
} from '../../api/queries';
import * as Logging from '../util/logging';
import { CourseIDContext } from '../Contexts';

const DemoViewQuery = gql`
  ${SharedLanderFragment}
  ${StudentLanderCourseFragment}
  query DemoViewQuery($courseID: String!) {
    course(ID: $courseID) {
      ...SharedLanderFragment
      ...StudentLanderCourseFragment
    }
  }
`;

const DemoMessage = () => (
  <Message info>
    <Message.Header>Demoing Student View</Message.Header>
    <p>
      You are viewing the Quuly room inbox as a student for this space. Students
      will not see the queue breakdown
    </p>
  </Message>
);

export default () => {
  const courseID = useContext(CourseIDContext);
  const { loading, error, data } = useQuery(DemoViewQuery, {
    variables: { courseID },
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  });

  if (error) {
    Logging.error('ViewAsStudent', error);
  }

  if (loading) return <Icon name="circle notched" loading />;

  if (!data || !data.course) {
    return <p>Something went wrong</p>;
  }

  return (
    <>
      <DemoMessage />
      <StudentLanderContents data={data} demo />
    </>
  );
};
