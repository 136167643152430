import React from 'react';
import { Header } from 'semantic-ui-react';

import { TERMS } from '../../types';

const CourseHeader = ({ course, session, typeCopy }) => (
  <Header as="h2">
    {course.code}
    :
    {course.name} {typeCopy || ''}
    {!!session && (
      <Header.Subheader>
        {`${TERMS[session.term].text} ${
          session.year
        }`}
      </Header.Subheader>
    )}
  </Header>
);

export default CourseHeader;
