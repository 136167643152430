import React from 'react';
import Helmet from 'react-helmet';

export default ({ courseCode }) => {
  if (!courseCode) return null;
  return (
    <>
      <Helmet>
        <title>
          {courseCode} | Quuly
        </title>
      </Helmet>
    </>
  );
};
