import React from 'react';

const CannotFindCourseView: React.FC<any> = () => {
  return (
    <>
      <h2 className="bp3-heading">
        Whoops, looks like we can't find this course
      </h2>
    </>
  );
};

export default CannotFindCourseView;
