import React from 'react';
import { useMutation } from 'react-apollo';
import {
  Header, Table, Icon, Button, Popup,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';

import { createUserURLTable } from '../util/url-utils';
import { RoleOptions } from '../../types';
import * as Constants from '../../constants';
import { successToast, warningToast } from '../util/toast';
import EnrollmentDropdown from '../common/EnrollmentDropdown';
import UserStatusSection from './UserStatusSection';
import DeleteEnrollmentButton from '../common/DeleteEnrollmentButton';
import Dropdown from '../common/Dropdown';

const UpdateInviteMutation = gql`
  mutation UpdateInvite($identifier: String!, $course: String!, $newRole: EnrollmentType!) {
    updateInvite(identifier: $identifier, course: $course, newRole: $newRole) {
      identifier
      role
    }
  }
`;

const DeleteInviteMutation = gql`
  mutation DeleteInvite($identifier: String!, $course: String!) {
    deleteInvite(identifier: $identifier, course: $course) {
      success
      message
    }
  }
`;

const HeaderSection = ({
  enrollment: {
    pending,
    user = {},
    user: {
      externalid, fullname, username, email,
    } = {},
  } = {},
  isMe,
  courseID,
}) => (
  <Table.Cell>
    <Header as="h4" image>
      <Icon name="user" />
      <Header.Content as={Link} to={createUserURLTable(externalid, courseID)}>
        {fullname || email || Constants.DEFAULT_USER_NAME}

        <Header.Subheader>
          {email || Constants.DEFAULT_USER_EMAIL}
        </Header.Subheader>
        <Header.Subheader>
          {username || Constants.DEFAULT_USER_USERNAME}
        </Header.Subheader>
      </Header.Content>
    </Header>
    <span style={{ paddingLeft: '10px' }}>
      <UserStatusSection pending={pending} user={user} isMe={isMe} />
    </span>
  </Table.Cell>
);

const EnrollmentDropdownSection = ({ enrollment, isMe }) => (enrollment && enrollment.type ? (
  <>
    <Table.Cell collapsing>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ marginRight: '7px' }}>Role: </span>
        <EnrollmentDropdown enrollment={enrollment} disabled={isMe} />
      </div>
    </Table.Cell>
  </>
) : null);

const RightHandSection = ({ enrollment, courseID }) => (
  <>
    <Table.Cell collapsing>
      <Button
        basic
        as={Link}
        to={createUserURLTable(enrollment.user.externalid, courseID)}
      >
        View User
      </Button>
    </Table.Cell>

    <Table.Cell collapsing>
      <DeleteEnrollmentButton
        userID={enrollment.user.externalid}
        courseID={courseID}
      />
    </Table.Cell>
  </>
);

const InviteRow = ({ invite, courseID }) => {
  const [updateInvite] = useMutation(UpdateInviteMutation, {
    onError: () => warningToast('Failed to update invite'),
    onCompleted: () => successToast('Successfully updated invite'),
    refetchQueries: ['UsersForCourseWithInvites'],
  });
  const [deleteInvite] = useMutation(DeleteInviteMutation, {
    onError: () => warningToast('Failed to delete invite'),
    onCompleted: (data) => (data?.deleteInvite?.success
      ? successToast('Successfully deleted invite')
      : warningToast('Failed to delete invite')),
    refetchQueries: ['UsersForCourseWithInvites'],
  });

  if (!invite || !invite.identifier || !invite.role) {
    return null;
  }

  return (
    <Table.Row>
      <Table.Cell>
        <Header>
          <Icon name="user" />
          <Header.Content>
            <Header.Subheader>
              {invite.identifier}
            </Header.Subheader>
            <Header.Subheader>
              <div style={{ marginTop: '4px', marginLeft: '-8px' }}>
                <UserStatusSection pending />
              </div>
            </Header.Subheader>
          </Header.Content>
        </Header>
      </Table.Cell>
      <Table.Cell collapsing>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ marginRight: '7px' }}>Role: </span>
          <Dropdown
            aria-label="Enrollment Dropdown"
            placeholder=" Role"
            fluid
            selection
            options={RoleOptions}
            value={invite.role}
            onChange={(e, { value }) => {
              updateInvite({
                variables: {
                  course: courseID,
                  identifier: invite.identifier,
                  newRole: value,
                },
              });
            }}
          />
        </div>
      </Table.Cell>
      <Table.Cell>
        <Popup
          content="Delete this invite"
          trigger={(
            <Button
              onClick={() => {
                // eslint-disable-next-line no-restricted-globals
                const ok = window.confirm('Are you sure you want to delete this invite?');
                if (!ok) return;
                deleteInvite({
                  variables: {
                    course: courseID,
                    identifier: invite.identifier,
                  },
                });
              }}
              icon
              color="red"
            >
              <Icon name="trash" />
            </Button>
            )}
        />
      </Table.Cell>
    </Table.Row>
  );
};

const UserRow = ({
  enrollment, isMe, onClick, courseID,
}) => {
  if (!enrollment || !enrollment.user) {
    return null;
  }

  return (
    <Table.Row onClick={onClick}>
      <HeaderSection enrollment={enrollment} isMe={isMe} courseID={courseID} />
      <EnrollmentDropdownSection enrollment={enrollment} isMe={isMe} />
      <RightHandSection enrollment={enrollment} courseID={courseID} />
    </Table.Row>
  );
};

const UserTable = ({
  tableScope, enrollments, pendingInvites, currentUserID, courseID,
}) => ( // TODO: if enrollments filter and return
  <Table>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell colSpan="5">
          Users in {tableScope}
          {/* ({users.length}) */}
        </Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {pendingInvites?.length
        ? pendingInvites.map((invite, index) => (invite?.identifier && invite?.role ? (
          <InviteRow key={`Row${index + 1}`} invite={invite} courseID={courseID} />
        ) : null)) : null}
      {enrollments?.length
        ? enrollments.map((enrollment, index) => (enrollment
            && enrollment.user
            && enrollment.user.externalid ? (
              <UserRow
                key={enrollment.user.externalid}
                enrollment={enrollment}
                index={index}
                courseID={courseID}
                isMe={
                currentUserID
                === (enrollment.user && enrollment.user.externalid)
              }
              />
          ) : null)) : null}
      {!pendingInvites?.length && !enrollments?.length ? <div>No users</div> : null}
    </Table.Body>
  </Table>
);
export default UserTable;
