import React from 'react';
import { Dropdown } from 'semantic-ui-react';

const options = [
  { name: 'Created (newest)', value: 'createdDesc', text: 'Created (newest)' },
  { name: 'Created (oldest)', value: 'createdAsc', text: 'Created (oldest)' },
  { name: 'Updated (newest)', value: 'updatedDesc', text: 'Updated (newest)' },
  { name: 'Updated (oldest)', value: 'updatedAsc', text: 'Updated (oldest)' },
];

export default ({ setSortOrder, sortOrder }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    Sort by: &nbsp;
    <Dropdown
      selection
      options={options}
      value={sortOrder}
      onChange={(ignore, { value }) => setSortOrder(value)}
    />
  </div>
);
