import React, { useState, useEffect } from 'react';
import {
  Form, Button, Icon, Popup,
} from 'semantic-ui-react';
import { doesAccountRequireCompletion } from '../util/user-utils';
import UserImageUpload from './UserImageUpload';
import useUpdateUser from './useUpdateUser';

const USERNAME_LABEL = process.env.REACT_APP_USERNAME_COPY || 'Directory ID';
const SHOULD_WARN_USERNAME = false;

const REQUIRES_USERNAME = process.env.REACT_APP_IS_SAAS !== 'true';

const WarningIcon = () => <Icon name="exclamation triangle" color="orange" />;

const AttentionRequired = () => (
  <>
    <p>
      <Icon name="exclamation" /> Please enter missing account data
    </p>
  </>
);

const UserInfoSection = ({
  user: {
    username, fullname, email, isAdmin, isEditing,
  },
  user,
}) => (
  <>
    {!!doesAccountRequireCompletion(user) && <AttentionRequired />}
    <Form>
      <Form.Field>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label>Full Name</label>
        <p>{fullname || 'Please enter your name'}</p>
      </Form.Field>
      <Form.Field>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label>
          Email
          {!email && !REQUIRES_USERNAME && <WarningIcon />}
        </label>
        <p>{email || 'Please enter an email'}</p>
      </Form.Field>
      <Form.Field>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label>
          {USERNAME_LABEL} {SHOULD_WARN_USERNAME && !username && <WarningIcon />}
        </label>
        <p>{username || `No ${USERNAME_LABEL} on record`}</p>
      </Form.Field>
    </Form>
  </>
);

const UserEditForm = ({
  isEditing,
  onEditingCanceled,
  onSubmitted,
  onChange,
  renderAdditionalInstructions,
  withCancel = true,
  withCompletionWarning = true,
  withDone = true,
  editUser,
  isEmailDisabled,
  user: {
    fullname, email, isAdmin, externalid, photoUrl,
  } = {},
  user,
}) => {
  const [localFullname, setLocalFullname] = useState(fullname);
  const [localEmail, setLocalEmail] = useState(email);
  const [localIsAdmin, setLocalIsAdmin] = useState(isAdmin);

  // Update local state on update
  useEffect(() => {
    setLocalFullname(fullname);
    setLocalEmail(email);
  }, [
    fullname,
    email,
    setLocalFullname,
    setLocalEmail,
  ]);

  useEffect(() => {
    onChange({
      variables: {
        userID: externalid,
        email: !isEmailDisabled && localEmail ? localEmail : null,
        fullname: localFullname || null,
      },
    });
  }, [localEmail, localFullname]);

  if (!isEditing) return <UserInfoSection user={user} />;
  // Return other view here

  return (
    <>
      {!!withCompletionWarning && !!doesAccountRequireCompletion(user) && (
        <AttentionRequired />
      )}
      <div
        style={{
          display: 'flex',
          flexFlow: 'row',
          flexWrap: 'wrap',
          padding: '0.5em',
        }}
      >
        <div
          style={{
            display: 'flex',
            flex: '2 1 170px',
            justifyContent: 'center',
          }}
        >
          <UserImageUpload userFullname={fullname} userImageUrl={photoUrl} />
        </div>
        <div style={{ display: 'flex', flex: '3 1 250px' }}>
          <div style={{ width: '100%' }}>
            {!!renderAdditionalInstructions && renderAdditionalInstructions()}
            <Form
              id="userEdit"
              onSubmit={(e) => {
                e.preventDefault();
                if (onSubmitted) {
                  onSubmitted({
                    variables: {
                      userID: externalid,
                      email: localEmail || null,
                      fullname: localFullname || null,
                    },
                  });
                }
              }}
            >
              <Form.Field>
                <label htmlFor="name">
                  Full Name
                  {!localFullname && <WarningIcon />}
                </label>
                <Form.Input
                  type="text"
                  name="name"
                  placeholder="Jon Snow"
                  value={localFullname}
                  onChange={(e) => setLocalFullname(e.target.value)}
                />
              </Form.Field>
              <Form.Field>
                <label htmlFor="email">Email</label>
                <Form.Input
                  type="email"
                  name="email"
                  placeholder="your-email@your-school.edu"
                  value={localEmail}
                  onChange={(e) => setLocalEmail(e.target.value)}
                  disabled={!!isEmailDisabled}
                />
              </Form.Field>
              {/*
        <Form.Field>
          <Checkbox label="Is Admin" checked={!!localIsAdmin} />
        </Form.Field>
        */}
              {!!withCancel && (
                <Button onClick={onEditingCanceled} basic type="button">
                  Cancel
                </Button>
              )}

              {!!withDone && (
                <Button primary type="submit">
                  Done
                </Button>
              )}
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserEditForm;
