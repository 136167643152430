import React from 'react';
import { Segment, Header } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import RoleGate from './RoleGate';

export default ({ user, course, hasRooms }) => {
  if (!user || !course) return null;
  return (
    <>
      {!hasRooms && (
        <div>
          {course.code || 'This course'} has
          <strong> no rooms.</strong>
          <RoleGate user={user} student> Please contact course staff.</RoleGate>
        </div>
      )}
      <RoleGate user={user} instructor>
        <Segment padded>
          <Header as="h4">Add a room</Header>
          Head to <Link to={`/${course.id}/manage/rooms`}>{course.code} room setup</Link> to add more rooms
        </Segment>
      </RoleGate>
    </>
  );
};
