import React from 'react';
import { Label } from 'semantic-ui-react';

const Tags = ({ tags }) => (
  <>
    {tags.map((t) => (
      <React.Fragment key={`tag-${t.id}`}>
        <Label
          size="small"
          color="purple"
          horizontal
          style={{ margin: '0 5px 5px 0' }}
        >
          {t.tag}
        </Label>
      </React.Fragment>
    ))}
  </>
);

export default Tags;
