import React, { useState } from 'react';
import { Message } from 'semantic-ui-react';

const DISMISSED = 'DISMISSED';

export const createLocalStorageKey = (name) => `DISMISSABLE_MESSAGE_${name}`;

export const removeLocalStorageKey = (name) => localStorage.removeItem(createLocalStorageKey(name));

/**
 * DismissableMessage can be dismissed permenantly for the current browser
 *
 * @param {name} string Name of overlay to save in local storage
 */
const DismissableMessage = ({ name, ...props }) => {
  const [isOpen, setIsOpen] = useState(
    !localStorage.getItem(createLocalStorageKey(name)),
  );
  return (
    isOpen && (
      <Message
        onDismiss={() => {
          setIsOpen(false);
          localStorage.setItem(createLocalStorageKey(name), DISMISSED);
        }}
        {...props}
      />
    )
  );
};

export default DismissableMessage;
