import React, { useContext } from 'react';
import { Image } from 'semantic-ui-react';

import { Link } from 'react-router-dom';
import { UserContext } from '../Contexts';

const WHITE_IMG_SRC = `${process.env.PUBLIC_URL}/img/signature_white.png`;
const BLUE_IMG_SRC = `${process.env.PUBLIC_URL}/img/signature_blue.svg`;

const InnerImage = ({ color = 'white', ...rest }) => (
  <Image
    alt="Quuly"
    src={color === 'blue' ? BLUE_IMG_SRC : WHITE_IMG_SRC}
    style={{ height: '100%' }}
    {...rest}
  />
);

export default ({ ...rest }) => {
  const userId = useContext(UserContext);

  // Logged in users should get Acadia, otherwise link back to the storefront
  return userId ? (
    <Link to="/" style={rest.style || {}}>
      <InnerImage {...rest} />
    </Link>
  ) : (
    <a href="https://quuly.com" style={rest.style || {}}>
      <InnerImage {...rest} />
    </a>
  );
};
