import React from 'react';
import { Helmet } from 'react-helmet';

const ENABLE_FRESHCHAT = false;

const SaaSHeader = (
  <script>
    {`
function initFreshChat() {
window.fcWidget.init({
  token: '69ea0fc8-5791-4797-82a6-3646ed73dc84',
  host: 'https://wchat.freshchat.com'
});
}
function initialize(i, t) {
var e;
i.getElementById(t)
  ? initFreshChat()
  : (((e = i.createElement('script')).id = t),
    (e.async = !0),
    (e.src = 'https://wchat.freshchat.com/js/widget.js'),
    (e.onload = initFreshChat),
    i.head.appendChild(e));
}
function initiateCall() {
initialize(document, 'freshchat-js-sdk');
}
window.addEventListener
? window.addEventListener('load', initiateCall, !1)
: window.attachEvent('load', initiateCall, !1);
`}
  </script>
);

const AppHat: React.FC<any> = () => {
  const isSaas = process.env.REACT_APP_IS_SAAS === 'true';
  return <Helmet>{isSaas && ENABLE_FRESHCHAT ? SaaSHeader : null}</Helmet>;
};

export default AppHat;
