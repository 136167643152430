import React, { useContext } from 'react';
import { useQuery, useSubscription } from 'react-apollo';
import gql from 'graphql-tag';
import { Icon } from 'semantic-ui-react';

import {
  ConvoWithInteractionsFragment,
  SharedLanderFragment,
  StudentLanderCourseFragment,
  QueueSubscription,
} from '../../api/queries';
import * as Contexts from '../Contexts';
import RoleGate from '../common/RoleGate';
import * as Logging from '../util/logging';
import StaffLanderContents from './StaffLanderContents';
import StudentLanderContents from './StudentLanderContents';
import CourseHat from '../common/CourseHat';

const StaffLanderQuery = gql`
  ${ConvoWithInteractionsFragment}
  ${SharedLanderFragment}
  query StaffLanderQuery($courseID: String!) {
    course(ID: $courseID) {
      ...SharedLanderFragment
      beingHelpedConvs: conversations(state: being_helped) {
        ...ConvoWithInteractionsFragment
      }
      # TODO: Move this to a centralized course query in App.js
      rooms {
        name
        id
        queue {
          size
          conversations {
            ...ConvoWithInteractionsFragment
          }
        }
        location
        type
      }
      instructorEnrollmentCount: enrollmentCount(roles: [instructor])
      taEnrollmentCount: enrollmentCount(roles: [ta])
      studentEnrollmentCount: enrollmentCount(roles: [student])
    }
  }
`;

const StudentLanderQuery = gql`
  ${ConvoWithInteractionsFragment}
  ${SharedLanderFragment}
  ${StudentLanderCourseFragment}
  query StaffLanderQuery($courseID: String!) {
    course(ID: $courseID) {
      ...SharedLanderFragment
      ...StudentLanderCourseFragment
    }
    user {
      tokens(course: $courseID)
      conversations(course: $courseID, state: any, sortOrder: updatedAsc) {
        ...ConvoWithInteractionsFragment
      }
      activeConversation {
          id
          course {
            id
            name
            code
          }
      }
    }
  }
`;

const StudentLander = ({ user, courseID }) => {
  const {
    loading, error, data, refetch,
  } = useQuery(StudentLanderQuery, {
    variables: { courseID },
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  });

  if (error) {
    Logging.error('StudentLander', error);
  }

  if (loading) return <Icon name="circle notched" loading />;

  if (!data || !data.course) {
    // TODO: if !!error and !data, make this pretty
    return <p>Looks like you&apos;re not enrolled in any courses</p>;
  }

  return (
    <>
      <CourseHat courseCode={data?.course?.code} />
      <StudentLanderContents
        user={({ ...user, ...data.user })}
        data={data}
        courseID={courseID}
        refetch={refetch}
      />
    </>
  );
};

const StaffLander = ({ user, courseID, currentRoomID }) => {
  const {
    loading, error, data, refetch,
  } = useQuery(StaffLanderQuery, {
    variables: { courseID },
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  });

  useSubscription(QueueSubscription, {
    variables: { courseID, roomID: currentRoomID },
    fetchPolicy: 'no-cache',
    onSubscriptionData: () => {
      try {
        if (typeof refetch === 'function') refetch();
      } catch (err) {
        Logging.error('StaffLander', 'Subscription error', err);
      }
    },
  });

  if (error) {
    Logging.error('StaffLander', error);
  }

  if (loading) return <Icon name="circle notched" loading />;

  if (!data || !data.course) {
    if (!data || !data.course) {
      // TODO: if !!error and !data, make this pretty
      return <p>Looks like you&apos;re not enrolled in any courses</p>;
    }
    return <p>GraphQL error</p>;
  }

  return (
    <>
      <CourseHat courseCode={data?.course?.code} />
      <StaffLanderContents
        courseID={courseID}
        currentRoomID={currentRoomID}
        user={({ ...user, ...data.user })}
        data={data}
        refetch={refetch}
      />
    </>
  );
};

const SharedLander = () => {
  Logging.debug('SharedLander', 'mounting');
  const user = useContext(Contexts.UserContext);
  const courseID = useContext(Contexts.CourseIDContext);
  const currentRoomID = useContext(Contexts.RoomIDContext);
  return (
    <>
      <RoleGate user={user} student>
        <StudentLander user={user} courseID={courseID} />
      </RoleGate>
      <RoleGate user={user} ta instructor>
        <StaffLander
          user={user}
          courseID={courseID}
          currentRoomID={currentRoomID}
        />
      </RoleGate>
    </>
  );
};

export default SharedLander;
