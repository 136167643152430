import { useEffect } from 'react';

export default () => {
  useEffect(() => {
    // Universe please forgive me for this awful hack, without height: vh there's no other way
    document.body.style = 'background: #044462;';
    return () => {
      document.body.style = 'background: unset;';
    };
  });
};
