import * as React from 'react';
import distanceInWordsToNow from 'date-fns/distance_in_words_to_now';
import useInterval from '../util/useInterval';

export const isPastTimeLimit = (timeyMcTimeFace, limit) => (timeyMcTimeFace
  ? new Date() - new Date(timeyMcTimeFace) > limit : false);

const getTimeString = (timeyMcTimeFace) => (timeyMcTimeFace
  ? distanceInWordsToNow(new Date(timeyMcTimeFace)) : null);

const TATimer = ({ initialTime, isExtraStrong = false, state = 'Notified' }) => {
  const [curTime, setCurTime] = React.useState(getTimeString(initialTime));

  React.useEffect(() => {
    setCurTime(initialTime);
  }, [initialTime]);

  useInterval(() => {
    setCurTime(getTimeString(initialTime));
  }, 10);

  const timeStyles = isExtraStrong ? { color: '#c54100' } : {};

  return (
    <>
      <p style={{ fontSize: '1.2em' }}>
        {state} <strong style={timeStyles}>{curTime}</strong> ago
      </p>
    </>
  );
};

export default TATimer;
