import React, { useContext } from 'react';
import { Header, Icon } from 'semantic-ui-react';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { Route } from 'react-router-dom';

import UserTable from '../../User/UserTable';
import UserAddForm from './UserAddForm';
import * as Contexts from '../../Contexts.jsx';
import * as Logging from '../../util/logging';
import ErrorView from '../../common/ErrorView';
import UserDrilldownView from '../../User/UserDrilldownView';

const UsersForInstitution = gql`
  query UsersForInstitution($id: String!) {
    institution(ID: $id) {
      id
      shortname
      name
      enrollments {
        __typename
        id
        pending
        user {
          externalid
          fullname
          username
          email
        }
      }
    }
  }
`;

const AdminUserView = ({ history }) => {
  const { institution: id } = useContext(Contexts.AppContext);
  const user = useContext(Contexts.UserContext);
  const { loading, error, data } = useQuery(UsersForInstitution, {
    fetchPolicy: 'network-only',
    variables: { id },
  });
  if (loading) return <Icon name="circle notched" loading />;
  if (error) Logging.error('Admin view gql error', error);

  if (!data || !data.institution) return <ErrorView error={error} data={data} appEntryPoint />;

  let enrollments = [];
  if (
    data
    && data.institution
    && data.institution.enrollments
    && data.institution.enrollments.length
  ) {
    enrollments = data.institution.enrollments;
  }

  const maybeShortName = data.institution.shortname || data.institution.name || 'your institution';

  return (
    <>
      <Route path="/admin/users/:userID" component={UserDrilldownView} />
      <Header as="h2">Manage users</Header>
      <Header as="h3">Invite a new user</Header>
      <UserAddForm institutionID={id} />
      <Header as="h3">All users</Header>

      <UserTable
        tableScope={maybeShortName}
        enrollments={enrollments}
        currentUserID={user.externalid}
        history={history}
      />
    </>
  );
};

export default AdminUserView;
