import React, { useState, useContext } from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import {
  Modal, Grid, Icon, Button, Form,
} from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import OnboardingActions from './OnboardingActions.tsx';
import InviteForm from './InviteForm';
import * as Logging from '../util/logging';
import ErrorMessage from '../common/ErrorMessage';
import { UserContext } from '../Contexts';
import { successToast } from '../util/toast';

const InviteInstructorMutation = gql`
  mutation inviteInstructor($email: String!, $courseName: String!) {
    inviteInstructor(email: $email, courseName: $courseName) {
      success
      message
    }
  }
`;

const onInviteCompleted = (history, data) => {
  successToast(
    <>
      <h4>Your instrutor has been notified</h4>
      <p>Thank you for sharing Quuly.</p>
    </>,
  );
  history.replace(history.location.pathname.replace('/welcome', ''));
};

export default ({ setStage }) => {
  const user = useContext(UserContext);
  const history = useHistory();
  const [inviteError, setInviteError] = useState('');
  const [inviteInstructor] = useMutation(InviteInstructorMutation, {
    onError: (data) => {
      Logging.error('StudentEntry', data);
      setInviteError('Something went wrong');
    },
    onCompleted: (data) => {
      Logging.info('StudentEntry', data);
      if (!data?.inviteInstructor?.success) {
        setInviteError("Please double check your instructor's email");
      } else {
        onInviteCompleted(history, data);
      }
    },
    refetchQueries: ['RootQuery', 'StudentLanderQuery', 'SelectCourseQuery'],
  });
  const [email, setEmail] = React.useState('');
  const [courseName, setCourseName] = React.useState('');

  return (
    <>
      <Modal.Header>Welcome to Quuly</Modal.Header>

      <Modal.Content>
        <Grid columns={2} divided container stackable centered>
          <Grid.Column verticalAlign="middle" width={8}>
            <Grid centered>
              <Grid.Column verticalAlign="middle" width={12}>
                <h3>I have an invite code</h3>
                <p style={{ paddingTop: '1em' }}>
                  Use a Quuly invite code provided by your instructor or
                  organizer
                </p>
                <InviteForm />
              </Grid.Column>
            </Grid>
          </Grid.Column>
          <Grid.Column verticalAlign="middle" width={8}>
            <Grid centered>
              <Grid.Column verticalAlign="middle" width={12}>
                <h3>I don&apos;t have an invite code</h3>
                <p style={{ padding: '1em 0' }}>
                  Not sure if your course or group is using Quuly yet? Ask your
                  instructor or organizer.
                </p>
                <Form
                  onSubmit={() => {
                    inviteInstructor({ variables: { email, courseName } });
                  }}
                >
                  <Form.Field>
                    <Form.Input
                      icon="mail"
                      name="email"
                      aria-label="Instructor email"
                      placeholder="Instructor email"
                      autoComplete="off"
                      required
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Form.Input
                      icon="university"
                      name="course"
                      aria-label="Instructor email"
                      placeholder="CMSC330 or Math Tutoring"
                      autoComplete="off"
                      required
                      onChange={(e) => setCourseName(e.target.value)}
                    />
                  </Form.Field>

                  {!!inviteError && <ErrorMessage message={inviteError} />}

                  <Button
                    color="blue"
                    style={{ margin: '1em 1em 0 1em' }}
                    type="submit"
                  >
                    Go <Icon name="right arrow" />
                  </Button>
                </Form>
              </Grid.Column>
            </Grid>
          </Grid.Column>
        </Grid>
      </Modal.Content>
      <OnboardingActions
        onBack={() => {
          if (user.isAccountInfoRequired) {
            setStage('COMPLETE_ACCOUNT');
          } else {
            setStage('ABOUT_YOU');
          }
        }}
      />
    </>
  );
};
