import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal } from 'semantic-ui-react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useLocalStorage } from '@rehooks/local-storage';

import { Helmet } from 'react-helmet';
import CreateSpace from './CreateSpace';
import ConfigureSpace from './ConfigureSpace.tsx';
import StaffEntryModal from './StaffEntry';
import FinalizeSpace from './FinalizeSpace';
import AboutYou from './AboutYou';
import StudentEntry from './StudentEntry';
import { AppContext } from '../Contexts';
import * as Logging from '../util/logging';
import { TERMS } from '../../types';
import { warningToast, successToast } from '../util/toast';
import CompleteAccount from './CompleteAccount';

const CreateInstitutionMutation = gql`
  mutation createInstitution($name: String!) {
    createInstitution(name: $name) {
      name
      id
    }
  }
`;

const CreateCourseMutation = gql`
  mutation createCourseMutation(
    $name: String!
    $code: String!
    $tokens: Int!
    $enableOpenEnrollment: Boolean!
    $type: SpaceType!
    $institution: String!
    $session: SessionInput!
  ) {
    createCourse(
      course: {
        institution: $institution
        name: $name
        code: $code
        session: $session
        tokens: $tokens
        preferences: {
          spaceType: $type
          enableOpenEnrollment: $enableOpenEnrollment
        }
      }
    ) {
      code
      id
    }
  }
`;

const DarkHeader = ({ children }) => (
  <Modal.Header style={{ backgroundColor: '#044462', color: 'white' }}>
    {children}
  </Modal.Header>
);

const STAGES = [
  'ABOUT_YOU',
  'STAFF_ENTRY',
  'CREATE_SPACE',
  'CONFIGURE_COURSE',
  'ACCOUNT_COMPLETE',
];
const DEFAULT_YEAR = new Date().getFullYear();

const useConfigureSpace = (spaceType = 'course') => {
  const [name, setName] = useState('');
  const [type, setType] = useState(spaceType);
  const [code, setCode] = useState('');
  const [term, setTerm] = useState(TERMS.spring);
  const [tokens, setTokens] = useState(0);
  const [enableOpenEnrollment, setEnableOpenEnrollment] = useState(true);
  const [year, setYear] = useState(DEFAULT_YEAR);
  const [institution, setInstitution] = useState('DEFAULT');

  return [
    [name, setName],
    [type, setType],
    [code, setCode],
    [term, setTerm],
    [tokens, setTokens],
    [enableOpenEnrollment, setEnableOpenEnrollment],
    [year, setYear],
    [institution, setInstitution],
    () => ({
      name,
      type,
      code,
      term,
      tokens,
      enableOpenEnrollment,
      year,
      institution,
    }),
  ];
};

const createSuccess = (
  onCourseSelected,
  history,
  deleteStoredStage,
) => (data) => {
  deleteStoredStage();
  successToast(
    <>
      <h4>Space created</h4>
      <div style={{ marginTop: '.5em' }}>
        Get started with your new Quuly space by adding staff and students
      </div>
    </>,
  );
  onCourseSelected(data.createCourse.id);
  history.push('/selectCourse');
};

const onError = (error) => {
  Logging.error('Welcome', error);
  warningToast(
    <>
      <h4>Something went wrong...</h4>
      <div style={{ marginTop: '.5em' }}>Unable to create space.</div>
    </>,
  );
};
// TODO: useReducer here
const Stager = () => {
  const history = useHistory();
  const { onCourseSelected } = useContext(AppContext);
  const [storedStage, setStoredStage, deleteStoredStage] = useLocalStorage(
    'STAGE',
    {
      stage: STAGES[0],
    },
  );
  const setStage = (stage) => {
    setStoredStage({
      stage,
    });
  };
  const { stage } = storedStage;
  const [userInfo, setUserInfo] = useState();
  const [createInstitution] = useMutation(CreateInstitutionMutation);
  const [createSpace] = useMutation(CreateCourseMutation, {
    onError,
    onCompleted: createSuccess(onCourseSelected, history, deleteStoredStage),
    refetchQueries: [
      'RootQuery',
      'StudentLanderQuery',
      'StaffLanderQuery',
      'SelectCourseQuery',
    ],
  });

  const spaceConfig = useConfigureSpace();
  const onDone = (config) => {
    const {
      name,
      type,
      code,
      term,
      tokens,
      enableOpenEnrollment,
      year,
      institution,
    } = config[8]();

    const createCourseParams = {
      variables: {
        name,
        type,
        code,
        tokens,
        enableOpenEnrollment,
        session: {
          term: term.value,
          year,
        },
      },
    };

    if (process.env.REACT_APP_IS_SAAS !== 'true') {
      createCourseParams.variables.institution = process.env.REACT_APP_INSTITUTION;
      createSpace(createCourseParams);
    } else {
      createInstitution({ variables: { name: institution } }).then((resp) => {
        createCourseParams.variables.institution = resp.data.createInstitution.id;
        createSpace(createCourseParams);
      });
    }
  };

  switch (stage) {
    case 'CREATE_SPACE':
      return (
        <CreateSpace setStage={setStage} setSpaceType={spaceConfig[1][1]} />
      );
    case 'CONFIGURE':
      return <ConfigureSpace setStage={setStage} spaceConfig={spaceConfig} />;
    case 'FINALIZE_SPACE':
      return (
        <FinalizeSpace
          setStage={setStage}
          spaceConfig={spaceConfig}
          onDone={onDone}
        />
      );
    case 'STAFF_ENTRY':
      return <StaffEntryModal setStage={setStage} />;
    case 'STUDENT_ENTRY':
      return <StudentEntry setStage={setStage} />;
    case 'COMPLETE_ACCOUNT':
      // todo: This isn't good practice, make it better
      // Forwards to STUDENT_ENTRY or STAFF_ENTRY depending on selection
      return <CompleteAccount setStage={setStage} />;
    case 'ABOUT_YOU':
    default:
      return <AboutYou setStage={setStage} setUserInfo={setUserInfo} />;
  }
};

export default ({ closeable, history }) => (
  <>
    <Helmet>
      <title>Welcome | Quuly</title>
    </Helmet>
    <Modal
      open
      closeIcon={!!closeable}
      style={{ zIndex: 10 }}
      onClose={() => {
        if (!closeable) return;
        history.replace(history.location.pathname.replace('/welcome', ''));
      }}
    >
      <Stager />
    </Modal>
  </>
);
