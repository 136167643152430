import { useContext } from 'react';

import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import * as Logging from '../util/logging';
import { CourseIDContext } from '../Contexts';
import { ROLES } from '../../types';

const UserTokensQuery = gql`
  query($courseID: String!) {
    user {
      tokens(course: $courseID)
    }
  }
`;

const useTokens = (role) => {
  const courseID = useContext(CourseIDContext);
  const { loading, error, data } = useQuery(UserTokensQuery, {
    variables: { courseID },
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
    skip: role !== ROLES.student,
  });

  if (error) Logging.error('useTokens', error);
  const tokens = data && data.user ? data.user.tokens : -1;

  return { loading, tokens };
};

export default useTokens;
