import * as React from 'react';
import { Button } from 'semantic-ui-react';
import UserName from '../common/UserName';
import TATimer, { isPastTimeLimit } from './TATimer';
import UserImage from '../User/UserImage';

const TEN_MINS = 10 * 60 * 1000; /* ms */
const TWENTY_MINS = TEN_MINS * 2;

interface ICurrentHelperHeader {
  ta: any;
  dequeueTime?: Date;
  withModal?: boolean;
  meetingURL?: string;
}

// eslint-disable-next-line
export const CurrentHelperHeader: React.FC<Partial<ICurrentHelperHeader>> = ({
  ta,
  dequeueTime,
  withModal,
  meetingURL,
}) => (
  <div
    style={{
      display: 'flex',
      flexFlow: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',
    }}
  >
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <UserImage width={100} url={ta.photoUrl} />
      <div style={{ marginLeft: '1em' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h3>
            Assisting you: <UserName
              style={
                  withModal
                    ? { color: 'black', textDecoration: 'underline' }
                    : {}
                }
              userName={ta.fullname}
            />
          </h3>
        </div>

        {ta.tagline || 'Course Staff'}
      </div>
    </div>

    {!!dequeueTime && (
    <TATimer
      initialTime={dequeueTime}
      isExtraStrong={isPastTimeLimit(dequeueTime, TEN_MINS)}
    />
    )}
    {!!meetingURL && (
    <Button
      primary
      onClick={() => {
        window.open(meetingURL, '_blank');
      }}
    >
      Open Zoom Meeting
    </Button>
    )}
  </div>
);
