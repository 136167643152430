import gql from 'graphql-tag';

/* Mutations */

export const DequeueConversationMutation = gql`
  mutation DequeueConversationMutation($room: String!, $course: String!) {
    dequeueConversation(queueData: { room: $room, course: $course }) {
      topic
      description
      id
      tags {
        id
        tag
      }
    }
  }
`;

export const InteractConversationMutation = gql`
  mutation ConversationDone(
    $conversation: String!
    $interactionType: InteractionType!
    $feedback: FeedbackInput
    $comment: String
  ) {
    interact(
      conversation: $conversation
      interaction: $interactionType
      feedback: $feedback
      comment: $comment
    ) {
      message
    }
  }
`;

export const SetSessionMutation = gql`
  mutation SetSession($key: SessionKey!, $value: String!) {
    setSessionValue(key: $key, value: $value) {
      message
    }
  }
`;

export const AssignRoomMutation = gql`
  mutation assignRoomMutation($courseID: String!, $room: String!) {
    assignRoom(course: $courseID, room: $room) {
      message
    }
  }
`;

export const ActivateEnrollment = gql`
  mutation ActivateEnrollments($courseID: String!) {
    activateEnrollments(Courses: [$courseID]) {
      message
      success
    }
  }
`;

/* Queries */

export const PendingEnrollmentsQuery = gql`
  query PendingEnrollmentsQuery {
    pendingEnrollments {
      id
      course {
        id
        name
        code
      }
      type
      pending
    }
  }
`;

export const UserDrilldownQuery = gql`
  query UserDrilldownQuery($id: String!) {
    # institutionID: String!
    user(ID: $id) {
      __typename
      externalid
      fullname
      email
      username
      #      isAdmin(institution: $institutionID)
    }
  }
`;

export const ConvoHistoryFragment = gql`
  fragment ConvoHistoryFragment on Conversation {
    __typename
    id
    topic
    location
    creator {
      externalid
      fullname
      photoUrl
      tagline
    }
    currentResponder {
      externalid
      fullname
      photoUrl
      tagline
    }
    description
    state
    tags {
      id
      tag
    }
    created
    lastUpdated
    room {
      id
      name
      location
      type
    }
    interactions {
      __typename
      id
      type
      created
      comment
      responder {
        externalid
        fullname
      }
    }
  }
`;

export const ConvoWithInteractionsFragment = gql`
  ${ConvoHistoryFragment}
  fragment ConvoWithInteractionsFragment on Conversation {
    __typename
    meetingURL
    ...ConvoHistoryFragment
  }
`;

export const QueueSubscription = gql`
  subscription queue($courseID: String!, $roomID: String!) {
    queueSubscription(courseID: $courseID, roomID: $roomID)
  }
`;

export const StudentLanderCourseFragment = gql`
  fragment StudentLanderCourseFragment on Course {
    __typename
    message
    rooms {
      name
      id
      queue {
        size
        position
      }
      location
      type
    }
  }
`;

// This is all that's left of poor SharedLanderQuery
export const SharedLanderFragment = gql`
  fragment SharedLanderFragment on Course {
    __typename
    id
    name
    code
    tags {
      id
      tag
    }
    session {
      term
      year
    }
  }
`;

export const ConvoFragment = gql`
  fragment ConvoFragment on Conversation {
    __typename
    id
    topic
    location
    creator {
      externalid
      fullname
    }
    currentResponder {
      externalid
      fullname
    }
    description
    state
    tags {
      id
      tag
    }
    created
    room {
      id
      name
      location
      type
    }
  }
`;

export const CourseOverviewFragment = gql`
  fragment CourseOverviewFragment on Course {
    __typename
    id
    message
    name
    code
    session {
      term
      year
    }
    institution {
      id
    }
  }
`;

export const CourseOverviewQuery = gql`
  ${CourseOverviewFragment}
  query CourseOverviewQuery($courseID: String!) {
    course(ID: $courseID) {
      ...CourseOverviewFragment
    }
  }
`;

export const RoomFragment = gql`
  fragment RoomParts on Course {
    rooms {
      __typename
      id
      name
      location
      tas {
        externalid
        fullname
      }
      type
      queue {
        size
        updated
        waitTime
      }
      courses {
        id
        name
        code
      }
    }
  }
`;

export const CourseDrilldownQuery = gql`
  ${CourseOverviewFragment}
  query CourseDrilldownQuery($id: String!) {
    course(ID: $id) {
      ...CourseOverviewFragment
      link
      tokens
      tags {
        id
        tag
      }
      session {
        term
        year
      }
      preferences {
        enableOpenEnrollment
      }
      institution {
        id
        name
        shortname
        rooms {
          location
          name
          type
          id
          courses {
            id
            name
            code
          }
        }
      }
      rooms {
        type
        location
        name
        type
        id
        courses {
          id
          name
          code
        }
      }
    }
  }
`;
/* TODO: pull this out
    institution(ID: $institutionID) {
      id
      shortname
      name
      rooms {
        ... on InPerson {
          location
        }
        name
        id
        courses {
          id
          name
          code
        }
      }
    }
  }
`;
*/
export const RoomListQuery = gql`
  ${ConvoWithInteractionsFragment}
  query RoomListQuery($courseID: String!) {
    course(ID: $courseID) {
      rooms {
        name
        id
        type
        queue {
          size
          conversations {
            ...ConvoWithInteractionsFragment
          }
        }
        location
      }
    }
  }
`;

export const UsersForCourse = gql`
  query UsersForCourse(
    $courseID: String!
    $offset: Int!
    $limit: Int!
    $sortOrder: SortOrder
  ) {
    course(ID: $courseID) {
      id
      name
      code
      session {
        term
        year
      }
      enrollments(
        page: { offset: $offset, limit: $limit }
        sortOrder: $sortOrder
      ) {
        type
        pending
        user {
          externalid
          fullname
          username
          email
        }
      }
    }
  }
`;

export const UsersForCourseWithInvites = gql`
  query UsersForCourseWithInvites(
    $courseID: String!
    $offset: Int!
    $limit: Int!
    $sortOrder: SortOrder
  ) {
    course(ID: $courseID) {
      id
      name
      code
      session {
        term
        year
      }
      enrollments(
        page: { offset: $offset, limit: $limit }
        sortOrder: $sortOrder
      ) {
        type
        pending
        user {
          externalid
          fullname
          username
          email
        }
      }
      pendingInvites {
        identifier
        role
      }
    }
  }
`;

export const SetOnDutyMutation = gql`
  mutation ToggleOnDuty($courseId: String!, $roomId: String!) {
    setOnDuty(course: $courseId, room: $roomId) {
      success
    }
  }
`;

export const SetOffDutyMutation = gql`
  mutation ToggleOffDuty($courseId: String!, $roomId: String!) {
    setOffDuty(course: $courseId, room: $roomId) {
      success
    }
  }
`;

export const UpdateEnrollmentMutation = gql`
  mutation UpdateEnrollment(
    $userID: String!
    $courseID: String!
    $type: EnrollmentType!
  ) {
    updateEnrollment(user: $userID, course: $courseID, newEnrollment: $type) {
      success
      message
    }
  }
`;

export const UpdateUserMutation = gql`
  mutation UpdateUser(
    $userID: String!
    $fullname: String
    $email: String
    $username: String
  ) {
    editUser(
      id: $userID
      fullname: $fullname
      email: $email
      username: $username
    ) {
      externalid
    }
  }
`;

export const CreateFaqMutation = gql`
  mutation CreateFaq($question: String!, $answer: String!, $course: String!) {
    createFaq(course: $course, question: $question, answer: $answer) {
      id
    }
  }
`;

export const UserSessionQuery = gql`
  query UserSession {
    lastCourse: sessionValue(key: last_course)
    lastRoom: sessionValue(key: last_room)
  }
`;

export const FullCourseFragment = gql`
  fragment FullCourseFragment on Course {
    id
    message
    name
    code
    tags {
      id
      tag
    }
    session {
      term
      year
    }
    conversations {
      id
      topic
      creator {
        externalid
        fullname
      }
      description
      state
      tags {
        id
        tag
      }
      created
    }
  }
`;

export const RootQuery = gql`
  query RootQuery {
    user {
      externalid
      fullname
      email
      isRegisteredForVOH
      username
      photoUrl
      authType
      tagline
      enrollments {
        id
        course {
          id
        }
        type
      }
    }
  }
`;

export const BaseInstitutionQuery = gql`
  query BaseInstitutionQuery($institutionID: String!) {
    institution(ID: $institutionID) {
      id
      shortname
      name
    }
  }
`;

export const RootCourseQuery = gql`
  ${FullCourseFragment}
  query CourseQuery($courseID: String!) {
    course(ID: $courseID) {
      ...FullCourseFragment
    }
  }
`;
