/* Default strings */
export const DEFAULT_STUDENT_NAME = 'Unnamed Student';
export const DEFAULT_COURSE_STAFF = 'Unnamed course staff';
export const DEFAULT_ROOM_NAME = 'Unnamed Room';
export const DEFAULT_USER_NAME = 'Unnamed User';
export const DEFAULT_USER_USERNAME = 'No username';
export const DEFAULT_USER_EMAIL = 'No email';
export const DEFAULT_COURSE_NAME = 'Unnamed Course';

/* Screen width */
export const MAX_MOBILE_WIDTH = 768;
export const MAX_TABLET_WIDTH = 1024;

/* Login messages */
export const LOGIN_TIMEOUT = 'timeout';
export const LOGIN_LOGOUT = 'logout';

/* Login errors */
export const NO_ACCOUNT = 'noaccount';
export const SERVER_ERROR = 'servererror';
export const INVALID_TOKEN = 'invalidtoken';
export const INVALID_INVITE = 'invalidinvite';
