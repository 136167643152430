import React, { useContext, useState } from 'react';
import { Modal, Button, Icon } from 'semantic-ui-react';
import OnboardingActions from './OnboardingActions.tsx';
import UserEditForm from '../User/UserEditForm';
import { UserContext } from '../Contexts';
import useUpdateUser from '../User/useUpdateUser';
import * as Logging from '../util/logging';
import { doesAccountRequireCompletion } from '../util/user-utils';

export const reduceUserTypeToStage = (userType) => {
  switch (userType) {
    case 'STUDENT':
      return 'STUDENT_ENTRY';
    case 'INSTR_TA':
    case 'TUTOR':
    default:
      return 'STAFF_ENTRY';
  }
};

const Additionalinstructions = () => (
  <div style={{ marginBottom: '1.5em' }}>
    Please provide the same name that is printed on your university ID to make
    it easy for staff to identify you. You can change this later on.
  </div>
);

// Return false on any undefined
const usersDiffer = (oldUser, newUser) => {
  if (!oldUser || !newUser) return true;
  return (
    (newUser.fullName && oldUser.fullname !== newUser.fullname)
    || (newUser.email && oldUser.email !== newUser.email)
  );
};

const CompleteAccount = ({ setStage, userInfo }) => {
  const user = useContext(UserContext);
  const [editUser] = useUpdateUser(true);
  const [localUser, setLocalUser] = useState();

  return (
    <>
      <Modal.Header as="h1">About you</Modal.Header>
      <Modal.Content>
        <UserEditForm
          isEditing
          isEmailDisabled
          withCancel={false}
          user={user}
          withDone={false}
          withCompletionWarning={false}
          onChange={(newUser) => setLocalUser(newUser)}
          renderAdditionalInstructions={() => <Additionalinstructions />}
        />
      </Modal.Content>
      <OnboardingActions
        onBack={() => {
          setStage('ABOUT_YOU');
        }}
        renderProceedButton={
          user.isAccountInfoRequired
          && doesAccountRequireCompletion(localUser?.variables)
            ? () => (
              <Button
                onClick={() => {
                  if (
                    !!localUser
                      && usersDiffer(localUser?.variables, user)
                  ) {
                    Logging.debug('Setting user', localUser);
                    editUser(localUser);
                  }
                  setStage(reduceUserTypeToStage(userInfo?.userType));
                }}
              >
                Next
                <Icon name="arrow right" />
              </Button>
            )
            : null
        }
        onProceed={() => {
          if (localUser) {
            Logging.debug('Setting user', localUser);
            editUser(localUser);
          }
          setStage(reduceUserTypeToStage(userInfo?.userType));
        }}
      />
    </>
  );
};

export default CompleteAccount;
