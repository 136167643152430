import { useContext } from 'react';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';

import { CourseIDContext, RoomIDContext } from '../Contexts';

const DutyQuery = gql`
  query DutyQuery($courseId: String!) {
    user {
      isOnDuty(course: $courseId)
    }
  }
`;

const DEFAULT_RESPONSE = { user: { isOnDuty: false } };

export default () => {
  const currentCourseID = useContext(CourseIDContext);
  const currentRoomID = useContext(RoomIDContext);

  const { loading, error, data } = useQuery(DutyQuery, {
    variables: { courseId: currentCourseID },
    errorPolicy: 'all',
    skip: !currentCourseID,
  });

  return [
    loading,
    error,
    data && data.user ? data : DEFAULT_RESPONSE,
    currentCourseID,
    currentRoomID,
  ];
};
