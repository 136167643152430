import React from 'react';
import { Link } from 'react-router-dom';
import {
  Header, Icon, Label, Message, Modal, Button,
} from 'semantic-ui-react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { AssignRoomMutation } from '../../../api/queries';

import RoomAddForm from '../Room/RoomAddForm';
import RoomsListView from '../Room/RoomsListView';
import CourseEditForm from './CourseEditForm';
import TagAddForm from './TagAddForm';
import * as Toasts from '../../util/toast';
import { TERMS } from '../../../types';

/*
 * We have to take the courseID here
 * because the non-replacement feature of react-router is borked
 */
const EditLabel = ({ editCourseLink }) => (
  <Label as={Link} to={editCourseLink} pointing="left" size="small">
    <Icon name="pencil" /> Edit
  </Label>
);

const EditCourseModal = ({ isOpen, onClose, course }) => (
  <Modal closeIcon open={isOpen} onClose={onClose}>
    <Modal.Header>Edit course</Modal.Header>
    <Modal.Content>
      <CourseEditForm course={course} />
    </Modal.Content>
  </Modal>
);

const RemoveTagMutation = gql`
  mutation RemoveTag($tagID: String!, $courseID: String!) {
    removeTag(tagID: $tagID, course: $courseID) {
      message
    }
  }
`;

const onTagDeleteSuccess = () => Toasts.successToast('Tag successfully deleted');
const onTagDeleteError = () => Toasts.warningToast('Could not delete tag');

const DeleteableTag = ({ tag, courseID }) => {
  const [
    onRemoveTag,
    { loading: loadingOnDuty, error: onDutyError },
  ] = useMutation(RemoveTagMutation, {
    onCompleted: onTagDeleteSuccess,
    onError: onTagDeleteError,
    refetchQueries: ['CourseDrilldownQuery'],
  });
  return (
    <Label size="large" color="blue">
      {tag.tag}
      <Icon
        name="close"
        onClick={() => {
          // eslint-disable-next-line no-restricted-globals, no-alert
          const ok = window.confirm(
            `Are you sure you want to delete tag ${tag.tag}?`,
          );
          if (!ok) return;
          onRemoveTag({ variables: { tagID: tag.id, courseID } });
        }}
      />
    </Label>
  );
};

// TODO: make an edit tag view, and unhardcode # of sessions with this tag
const TagsListView = ({ course }) => (
  <div style={{ marginBottom: '10px' }}>
    {course.tags.length ? (
      course.tags.map((tag) => (
        <span key={`tag-${tag.id}`} style={{ marginRight: '5px' }}>
          <DeleteableTag tag={tag} courseID={course.id} />
        </span>
      ))
    ) : (
      <p>No tags</p>
    )}
  </div>
);

const onRoomAssocError = (error) => {
  // eslint-disable-next-line no-console
  console.error(error);
  Toasts.warningToast('Something went wrong while adding this room');
};

const onRoomAssign = (msg) => {
  Toasts.successToast('Successfully assigned room');
};

const AddRoomToCourseView = ({ allRooms, course, institution }) => {
  // Hide if we are not scoped by institution
  const isEnabled = !!process.env.REACT_APP_INSTITUTION;
  const roomsInCourseIds = course.rooms.map((room) => room.id);
  const difference = [...allRooms].filter(
    (room) => !roomsInCourseIds.includes(room.id),
  );

  const [assignRoom] = useMutation(AssignRoomMutation, {
    onCompleted: onRoomAssign,
    onError: onRoomAssocError,
    refetchQueries: ['CourseDrilldownQuery', 'RoomListQuery', 'ActiveTAsQuery'],
    skip: !isEnabled,
  });

  // Do not render if no inst exists or if we do not have existing rooms in the institution
  if (!isEnabled || !allRooms.length) {
    return null;
  }

  return (
    <>
      <Header as="h4">Add an existing room to this course</Header>
      <RoomsListView
        onCardClick={(room) => {
          assignRoom({
            variables: { room: room.id, courseID: course.id },
          });
        }}
        rooms={difference}
        withOtherCourses
      />
    </>
  );
};

const CourseDrilldown = ({
  course,
  institution,
  history,
  courseEditOpen,
  editCourseLink,
}) => {
  if (!course) {
    return null;
  }

  const onModalClose = () => history.replace(history.location.pathname.replace('/edit', ''));

  return (
    <>
      <Header as="h2">
        About {course.code} <EditLabel editCourseLink={editCourseLink} />
        <Header.Subheader>{course.name}</Header.Subheader>
        <Header.Subheader>
          {course.session
            && `${TERMS[course.session.term].text} ${course.session.year}`}
        </Header.Subheader>
        <Header.Subheader>
          {course.link || <Link to={editCourseLink}>Add url</Link>}
        </Header.Subheader>
      </Header>
      <Header as="h3">
        Number of tokens: {course.tokens || 'Unlimited'} <EditLabel editCourseLink={editCourseLink} />
      </Header>
      <Header as="h3">
        Student messaging <EditLabel editCourseLink={editCourseLink} />
      </Header>
      <Message content={course.message || 'No message'} />
      <Header as="h3">
        Rooms for
        {course.code}
      </Header>
      <RoomsListView rooms={course.rooms} course={course} />
      <Header as="h3">Add rooms to course</Header>
      <AddRoomToCourseView
        allRooms={institution.rooms}
        course={course}
        institution={institution}
      />
      <Header as="h4">
        Create a new room for
        {course.code}
      </Header>
      <RoomAddForm institutionID={institution.id} course={course} />
      <Header as="h3">Question Tags</Header>
      <TagsListView course={course} />
      <Header as="h4" content="Add a tag" />
      <TagAddForm course={course} />
      <Header as="h3">
        All {course.code} Questions
      </Header>
      <Button as={Link} to={`/${course.id}/allConversations`} primary>
        Browse all questions
      </Button>
      <EditCourseModal
        course={course}
        isOpen={courseEditOpen}
        onClose={onModalClose}
      />
    </>
  );
};

export default CourseDrilldown;
