import React, { useState, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  Responsive, Sidebar, Menu, Icon,
} from 'semantic-ui-react';

import LoggedInBody from './LoggedInBody';
import TopNavMenu from '../components/Menus/TopNavMenu';

import Changelog from '../components/About/Changelog';

import { useChangelogToast, successToast } from '../components/util/toast';
import useAccountUpdate from './AccountUpdate';
import useWindowDimensions from '../components/util/useWindowDimensions';
import PrimaryNavigation from '../components/Menus/PrimaryNavigation';
import LogoSignature from '../components/common/LogoSignature';

import Welcome from '../components/Onboarding/Welcome';

import './LoggedInContainer.css';

const SHARE_QUULY_ENABLED = false;
const SIDEBAR_LS_KEY = 'SIDEBAR_OPEN';
const SIDEBAR_LS_DT_KEY = 'SIDEBAR_DT_OPEN';
const SHARE_QUULY_MSG = 'SHARE_QUULY_MSG';
const SESSION_CONVO_UPDATE = 'SESSION_CONVO_UPDATE';
const SIDEBAR_HIDDEN_WIDTH = 1305;

const SidebarHeader = ({ onClose }) => (
  <div className="sidebarHeaderContainer">
    <div className="sidebarHeaderContent">
      <LogoSignature
        style={{
          height: '100%',
        }}
      />
      <div className="sidebarCloseContainer">
        <Icon
          name="chevron left"
          style={{ marginBottom: '.3em', cursor: 'pointer' }}
          onClick={onClose}
        />
      </div>
    </div>
  </div>
);

const SideBarNav = () => (
  <div className="sidebarNav">
    <Menu secondary vertical style={{ position: 'unset' }}>
      <PrimaryNavigation withAccountInfo />
    </Menu>
  </div>
);

function wasSidebarOpen() {
  return localStorage.getItem(SIDEBAR_LS_KEY) === true.toString();
}

function setSidebarStatus(isOpen = false) {
  return localStorage.setItem(SIDEBAR_LS_KEY, isOpen.toString());
}

function wasSidebarOpenDt() {
  return localStorage.getItem(SIDEBAR_LS_DT_KEY) !== false.toString();
}

function setSidebarStatusDt(isOpen = true) {
  return localStorage.setItem(SIDEBAR_LS_DT_KEY, isOpen.toString());
}

function wasShareQuulyOpen() {
  return localStorage.getItem(SHARE_QUULY_MSG) === true.toString();
}

function setShareQuulyOpen(isOpen = false) {
  return localStorage.setItem(SHARE_QUULY_MSG, isOpen.toString());
}

function wasPopupOpen(lsKey = 'NO_KEY', value = true) {
  return localStorage.getItem(lsKey) === value.toString();
}

function setPopupSeen(lsKey = 'NO_KEY', value = false) {
  return localStorage.setItem(lsKey, value.toString());
}

const covidToast = () => successToast(
  <>
    <h4>COVID-19 Response</h4>
    Quuly office hours are now virtual: we&apos;ve upgraded conversations to
    sessions. The Quuly team is here for you at <a href="mailto:team@quuly.com">team@quuly.com</a>.
  </>,
  null,
);

const LoggedInContainer = ({ history, location }) => {
  const { width } = useWindowDimensions();
  const [isSidebarOpen, setSidebarOpen] = useState(wasSidebarOpen());
  const [isSidebarOpenDt, setSidebarOpenDt] = useState(wasSidebarOpenDt());

  useAccountUpdate({ location });

  // Persist in localstorage
  useEffect(() => {
    setSidebarStatus(isSidebarOpen);
  }, [isSidebarOpen]);

  useEffect(() => {
    setSidebarStatusDt(isSidebarOpenDt);
  }, [isSidebarOpenDt]);

  // useChangelogToast(history);
  useEffect(() => {
    if (wasPopupOpen(SESSION_CONVO_UPDATE)) return () => {};
    // covidToast()
    setPopupSeen(SESSION_CONVO_UPDATE, true);
    return () => {};
  }, []);

  useEffect(() => {
    if (wasShareQuulyOpen() || !SHARE_QUULY_ENABLED) return () => {};
    successToast(
      <>
        <h4>Want Quuly in other courses?</h4>
        Share <a href="https://quuly.com/">quuly.com</a> with TAs and instructors in
        other courses or ping us at <a href="mailto:team@quuly.com">team@quuly.com</a>
      </>,
    );
    setShareQuulyOpen(true);
    return () => {};
  }, []);

  // We want the sidebar icon to toggle the state regardless of state
  const openSideBarOnClick = (event) => {
    if (width < SIDEBAR_HIDDEN_WIDTH) {
      setSidebarOpen(!isSidebarOpen);
    } else {
      setSidebarOpenDt(!isSidebarOpenDt);
    }
    event.stopPropagation();
  };

  // This will be called every time the page is clicked
  const closeSideBarOnClick = () => {
    if (width < SIDEBAR_HIDDEN_WIDTH && isSidebarOpen) {
      setSidebarOpen(false);
    }
    if (width >= SIDEBAR_HIDDEN_WIDTH && isSidebarOpenDt) {
      setSidebarOpenDt(false);
    }
  };

  return (
    <>
      <Route path="*(/changelog)" component={Changelog} />
      <Sidebar.Pushable className="appContainer">
        <Sidebar
          as={Menu}
          animation="uncover"
          vertical
          visible={isSidebarOpen && width < SIDEBAR_HIDDEN_WIDTH}
          style={{ border: 'unset', borderRight: '1px solid lightgray' }}
        >
          <SidebarHeader onClose={closeSideBarOnClick} />
          <PrimaryNavigation withAccountInfo onClose={closeSideBarOnClick} />
        </Sidebar>
        <Sidebar.Pusher
          className="appContainer"
          onClick={(event) => {
            if (isSidebarOpen && width < SIDEBAR_HIDDEN_WIDTH) {
              setSidebarOpen(false);
            }
            event.stopPropagation();
          }}
        >
          <TopNavMenu onOpenSidebarClick={openSideBarOnClick} />
          <Switch>
            <Route path="/welcome" component={Welcome} />
            <Route path="/">
              <div className="container">
                <Responsive as={React.Fragment} minWidth={SIDEBAR_HIDDEN_WIDTH}>
                  {isSidebarOpenDt && <SideBarNav />}
                </Responsive>
                <LoggedInBody history={history} />
              </div>
            </Route>
          </Switch>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </>
  );
};

export default LoggedInContainer;
