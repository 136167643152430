import React, { useState, useContext } from 'react';
import {
  Card,
  Form,
  TextArea,
  Header,
  ButtonGroup,
  Button,
  Icon,
  Label,
} from 'semantic-ui-react';
import distanceInWordsToNow from 'date-fns/distance_in_words_to_now';

import { Link } from 'react-router-dom';
import { Mutation } from 'react-apollo';
import * as Toasts from '../util/toast';
import { triadQuestionOptions } from '../../types';
import { CreateFaqMutation } from '../../api/queries';
import { CourseIDContext } from '../Contexts';

import Tags from '../ConvoCard/Tags';
import * as Constants from '../../constants';
import InteractionButton from '../ConvoCard/InteractionButton';
import InteractionList from '../ConvoCard/InteractionList';
import { createUserURL } from '../util/url-utils';

const TriadQuestion = ({
  questionText,
  triadQuestionAnswers,
  setTriadQuestionAnswers,
  index,
  setFeedbackModified,
}) => (
  <>
    <label htmlFor="triadQuestion">{questionText}</label>
    <Form.Group inline name="triadQuestion">
      {triadQuestionOptions.map((opt) => (
        <Form.Radio
          key={opt.value}
          label={opt.label}
          value={opt.value}
          checked={triadQuestionAnswers[index] === opt.value}
          onChange={(e, { value }) => {
            const newAnswers = [...triadQuestionAnswers];
            newAnswers[index] = value;
            setTriadQuestionAnswers(newAnswers);
            setFeedbackModified(true);
          }}
        />
      ))}
    </Form.Group>
  </>
);

// TODO: grab from query
const questions = [
  { question: "Did the student appear to know what they're doing?", id: '1' },
  {
    question:
      'Do you think the student could have solved the problem with less help?',
    id: '2',
  },
];

const onMutationError = (error) => {
  console.error(error);
  Toasts.warningToast('Something went wrong while closing a session');
};

const onDone = () => Toasts.successToast('Session ended and feedback submitted.');
const onNoShow = () => Toasts.successToast('Student marked as no show.');
const onResolve = () => Toasts.successToast('Session fully resolved and feedback submitted.');

const interactionButtonProps = {
  type: 'submit',
  floated: 'right',
  basic: true,
  primary: true,
};

const FAQForm = ({ setFAQFormOpen, convo, formattedFeedback }) => {
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [hasFAQItem, setHasFAQItem] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const courseID = useContext(CourseIDContext);
  return (
    <>
      <Form>
        {submitted ? (
          <Header
            as="h3"
            style={{ color: 'gray', 'text-align': 'center', margin: '2em 0' }}
          >
            Thanks for adding a FAQ!
          </Header>
        ) : (
          <>
            <Form.Field>
              <Form.Checkbox
                label="Yes, I'll add a FAQ Item for all students"
                onChange={(e, { checked }) => setHasFAQItem(checked)}
              />
            </Form.Field>
            {hasFAQItem && (
              <p style={{ marginBottom: '.5em' }}>
                Disclaimer: Please do
                <strong> not</strong> include information specific to this
                student. We won&apos;t associate the current student with this FAQ
                item when we store it.
              </p>
            )}
            <Form.Field>
              <label htmlFor="question">Question</label>
              <TextArea
                id="question"
                placeholder="How do I run the Ruby interpreter?"
                rows={1}
                onChange={(e) => {
                  setQuestion(e.target.value);
                }}
                disabled={!hasFAQItem}
              />
            </Form.Field>
            <Form.Field>
              <label htmlFor="answer">Answer</label>
              <TextArea
                id="answer"
                placeholder="Do X, Y, and Z as written on the syllabus."
                onChange={(e) => {
                  setAnswer(e.target.value);
                }}
                disabled={!hasFAQItem}
              />
            </Form.Field>
            {hasFAQItem && (
              <Mutation mutation={CreateFaqMutation}>
                {(createFaq) => (
                  <Button
                    type="submit"
                    basic
                    primary
                    onClick={() => {
                      createFaq({
                        variables: {
                          course: courseID,
                          question,
                          answer,
                        },
                      });
                      Toasts.successToast('Created FAQ');
                      setSubmitted(true);
                    }}
                  >
                    Add FAQ item
                    <Icon style={{ paddingLeft: '8px' }} name="check" />
                  </Button>
                )}
              </Mutation>
            )}
          </>
        )}
      </Form>
      {/* Keep these out of the form so they don't trigger a submit */}
      <div style={{ marginTop: '1em' }}>
        <Button
          basic
          onClick={() => {
            setFAQFormOpen(false);
          }}
        >
          Back
        </Button>
        <InteractionButton
          onError={onMutationError}
          interactionType="open"
          text="Return to Student"
          convo={convo}
          onCompleted={onDone}
          feedback={formattedFeedback}
          buttonProps={interactionButtonProps}
          tooltipText="Further student action required, the student may choose to requeue with this session in the future."
        />
        <InteractionButton
          onError={onMutationError}
          interactionType="resolve"
          text="Resolve"
          convo={convo}
          onCompleted={onResolve}
          feedback={formattedFeedback}
          buttonProps={interactionButtonProps}
          tooltipText="Student's question fully answered? Mark this session as resolved."
        />
      </div>
    </>
  );
};

const LeaveFeedbackHeader = () => (
  <>
    <Card.Header>Feedback (recommended)</Card.Header>
    <Card.Meta style={{ marginBottom: '15px' }}>
      Students can not see TA feedback
    </Card.Meta>
  </>
);

const FAQItemHeader = () => (
  <>
    <Card.Header>
      Frequently asked question?
      {/* <Label>New</Label> */}
    </Card.Header>
    <Card.Meta style={{ marginBottom: '15px' }}>
      Create a public FAQ item to inform students of common questions.
    </Card.Meta>
  </>
);

const TAFeedbackAndActionsForm = ({ convo }) => {
  const [wasFeedbackModified, setFeedbackModified] = useState(false);
  const [isFAQFormOpen, setFAQFormOpen] = useState(false);

  const [comment, setComment] = useState('');
  // Set to 'not_sure' by default
  const [triadQuestionAnswers, setTriadQuestionAnswers] = useState(
    questions.map(() => triadQuestionOptions[0].value),
  );
  const onSubmit = () => {};

  const formattedFeedback = {
    text: comment,
    answers: questions.map((q, index) => ({
      questionID: q.id,
      answer: triadQuestionAnswers[index],
    })),
  };

  return (
    <>
      {isFAQFormOpen ? <FAQItemHeader /> : <LeaveFeedbackHeader />}
      {isFAQFormOpen ? (
        <FAQForm
          setFAQFormOpen={setFAQFormOpen}
          comment={comment}
          triadQuestionAnswers={triadQuestionAnswers}
          convo={convo}
          formattedFeedback={formattedFeedback}
        />
      ) : null}
      {!isFAQFormOpen && (
        <Form onSubmit={onSubmit}>
          <Form.Field>
            {questions.map(({ question, id }, index) => (
              <TriadQuestion
                key={question}
                questionText={question}
                triadQuestionAnswers={triadQuestionAnswers}
                setTriadQuestionAnswers={setTriadQuestionAnswers}
                index={index}
                setFeedbackModified={setFeedbackModified}
              />
            ))}
          </Form.Field>
          <Form.Field>
            <label htmlFor="comments">
              Comments &amp; Resolution
            </label>
            <TextArea
              id="comments"
              name="comments"
              placeholder="Briefly, how did you help resolve the student's issue? What would you tell the next helper? Anything else to know? "
              onChange={(e) => {
                setComment(e.target.value);
                setFeedbackModified(true);
              }}
            />
            <p style={{ color: 'gray', marginTop: '.25em' }}>
              Leave this blank if the student did not show.
            </p>
          </Form.Field>
          <Form.Field>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>Next steps</label>
            <Button
              {...interactionButtonProps}
              onClick={() => {
                setFAQFormOpen(true);
              }}
            >
              Done
              <Icon style={{ paddingLeft: '8px' }} name="check" />
            </Button>
            {/* no_show conversations should not have associated feedback */}
            <InteractionButton
              onError={onMutationError}
              interactionType="no_show"
              text="No Show"
              convo={convo}
              onCompleted={onNoShow}
              buttonProps={interactionButtonProps}
              tooltipText="Can't find this student? This will allow the student to requeue if they stepped out."
            />
          </Form.Field>
        </Form>
      )}
    </>
  );
};

const TAConversationInProgress = ({ convo, courseID }) => {
  const {
    tags, created, topic, description, creator, interactions,
  } = convo;
  const createdDate = distanceInWordsToNow(new Date(created));
  const creatorName = (creator && (creator.fullname || creator.email))
    || Constants.DEFAULT_STUDENT_NAME;
  const sortedInteractions = (interactions || []).sort(
    (c1, c2) => new Date(c2.created) - new Date(c1.created),
  );

  return (
    <>
      <Card fluid>
        <Card.Content>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ marginBottom: '.5em' }}>
              <h3>
                {topic}
              </h3>
            </div>

            {convo.room.type === 'virtual' && !!convo.meetingURL && (
              <div style={{ marginBottom: '.5em' }}>
                <Button
                  color="blue"
                  onClick={() => {
                    window.open(convo.meetingURL, '_blank');
                  }}
                >
                  Open Zoom Meeting
                </Button>
              </div>
            )}
          </div>

          <Card.Meta>
            Created {createdDate} ago by {creatorName}
          </Card.Meta>
          <Card.Description>{description}</Card.Description>
          <br />
          {tags && tags.length ? (
            <Card.Description>
              <Tags tags={tags} />
            </Card.Description>
          ) : null}
          <br />
        </Card.Content>

        <Card.Content>
          <TAFeedbackAndActionsForm convo={convo} />
        </Card.Content>

        {!!interactions && !!interactions.length && (
          <Card.Content>
            {/*
                  <Header as="h3" style={{ marginBottom: '0' }}>
            Latest session activity
          </Header>
         */}

            <InteractionList interactions={sortedInteractions} />
          </Card.Content>
        )}
      </Card>
    </>
  );
};

export default TAConversationInProgress;
