import React, { useContext } from 'react';
import {
  Modal, Button, Grid, Form, Icon,
} from 'semantic-ui-react';
import { UserContext } from '../Contexts';
import { reduceUserTypeToStage } from './CompleteAccount';
import { NotifTypes } from '../../types';
import { useNotificationData, PendingEnrollmentNotification } from '../NotificationsView/NotificationsView';

const AboutYou = ({ setStage, setUserInfo }) => {
  const user = useContext(UserContext);
  const [pendingEnrollments] = useNotificationData();

  return (
    <>
      <Modal.Header as="h1">Welcome to Quuly</Modal.Header>

      <Modal.Content>
        <Grid columns={1} container stackable centered divided="vertically">
          {pendingEnrollments?.length ? (
            <Grid columns={1} container stackable centered>
              <Grid.Row columns={1}>
                <Grid.Column verticalAlign="middle" width={8}>
                  <h2>Course Invites</h2>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column verticalAlign="left">
                  {pendingEnrollments.map((enrollment) => (
                    <PendingEnrollmentNotification
                      enrollment={enrollment}
                      type={NotifTypes.enrollmentInvite}
                      key={`${enrollment.type} ${enrollment.course
                        && enrollment.course.name}`}
                      onAccept={() => window.location.replace('/q')}
                    />
                  ))}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          )
            : null}
          <Grid.Row>
            <Grid.Column verticalAlign="middle" width={8}>
              <h2>Let&apos;s get started.</h2>
              <p>What best describes your role?</p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid columns={2} divided container stackable centered>
          <Grid.Column verticalAlign="middle" width={6}>
            <Grid centered>
              <Grid.Column verticalAlign="middle" width={12}>
                <div>
                  <Button
                    color="blue"
                    size="large"
                    onClick={() => {
                      setUserInfo({ userType: 'STUDENT' });

                      if (!user.isAccountInfoRequired) {
                        setStage(reduceUserTypeToStage('STUDENT'));
                        return;
                      }
                      setStage('COMPLETE_ACCOUNT');
                    }}
                    fluid
                  >
                    <Icon name="student" /> Student
                  </Button>
                </div>
              </Grid.Column>
            </Grid>
          </Grid.Column>
          <Grid.Column verticalAlign="middle" width={6}>
            <Grid centered>
              <Grid.Column verticalAlign="middle" width={12}>
                <Button
                  color="blue"
                  onClick={() => {
                    if (!user.isAccountInfoRequired) {
                      setStage(reduceUserTypeToStage('INSTR_OR_TA'));
                      return;
                    }
                    setUserInfo({ userType: 'INSTR_OR_TA' });
                    setStage('COMPLETE_ACCOUNT');
                  }}
                  fluid
                >
                  <Icon name="user" />
                  Instructor or TA
                </Button>
                <Button
                  color="blue"
                  fluid
                  onClick={() => {
                    if (!user.isAccountInfoRequired) {
                      setStage(reduceUserTypeToStage('TUTOR'));
                      return;
                    }
                    setUserInfo({ userType: 'TUTOR' });
                    setStage('COMPLETE_ACCOUNT');
                  }}
                  style={{ marginTop: '.5em' }}
                >
                  <Icon name="users" />
                  Tutor
                </Button>
                {/*
                <Button
                  fluid
                  color="blue"
                  onClick={() => setStage('STAFF_ENTRY')}
                  style={{ marginTop: '.5em' }}
                >
                  <Icon name="university" />
                  Administrator
                </Button>
                  */}
              </Grid.Column>
            </Grid>
          </Grid.Column>
        </Grid>
      </Modal.Content>
    </>
  );
};

export default AboutYou;
