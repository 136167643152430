import React from 'react';
import { H5, Icon } from '@blueprintjs/core';
import { Button } from 'semantic-ui-react';
import * as Toasts from '../util/toast';
import UserImage from './UserImage';
import * as Logging from '../util/logging';

const PHOTOS_DISABLED = process.env.REACT_APP_DISABLE_PHOTOS === 'true';

interface UserImageUploadProps {
  userFullname?: string;
  userImageUrl?: string;
}

const UserImageUpload: React.FC<UserImageUploadProps> = ({
  userFullname,
  userImageUrl
}) => {
  const inputRef = React.createRef<HTMLInputElement>();
  const [image, setImage] = React.useState<string | null>(userImageUrl || null);

  const uploadImage = (file: File): void => {
    const formData = new FormData();
    formData.append('file', file);
    fetch(`${process.env.REACT_APP_API_URL}/uploadPhoto`, {
      method: 'post',
      mode: 'cors',
      credentials: 'include',
      body: formData
    })
      .then(res => {
        if (res.status === 200) {
          return res.text();
        } else {
          Toasts.warningToast('Oops, something went wrong...');
          throw Error('Error uploading image ' + res.statusText);
        }
      })
      .then(url => setImage(url))
      .catch(e => Logging.error(e));
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          textAlign: 'center',
          flexFlow: 'column'
        }}
      >
        <H5>{userFullname || 'Please add a name'}</H5>
        <div
          style={{
            display: 'flex',
            flexFlow: 'row',
            justifyContent: 'center',
            marginTop: '1em'
          }}
        >
          <UserImage
            url={image}
            onUploadClick={() => {
              if (PHOTOS_DISABLED) return;
              if (inputRef.current) inputRef.current.click();
            }}
          />
        </div>
        <Button
          style={{ marginTop: '1.5em' }}
          disabled={!!PHOTOS_DISABLED}
          onClick={() => {
            if (inputRef.current) inputRef.current.click();
          }}
        >
          Update photo
        </Button>
        {!!PHOTOS_DISABLED && (
          <span style={{ marginTop: '10px' }}>User photos coming soon</span>
        )}
        <input
          ref={inputRef}
          type="file"
          hidden
          name="file"
          onChange={event => {
            if (event && event.target && event.target.files) {
              uploadImage(event.target.files[0]);
            }
          }}
        />
      </div>
    </>
  );
};

export default UserImageUpload;
