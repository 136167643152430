import * as React from 'react';
import { Segment } from 'semantic-ui-react';
import { H5, Icon } from '@blueprintjs/core';

import './CollapsableSegment.css';
import { useLocalStorage } from '@rehooks/local-storage';

export const createLocalStorageKey = (name) => `COLLAPSED_SEGMENT_${name}`;

export default ({ title, children }) => {
  const [storedIsOpen, setIsOpen] = useLocalStorage(
    createLocalStorageKey(title),
    {
      isOpen: true,
    },
  );

  const isOpen = !!storedIsOpen?.isOpen;
  return (
    <Segment>
      <div
        className="heading"
        onClick={() => {
          setIsOpen({
            isOpen: !isOpen,
          });
        }}
        onKeyDown={() => {
          setIsOpen({
            isOpen: !isOpen,
          });
        }}
        role="button"
        tabIndex={0}
      >
        <H5>{title}</H5>
        {!!isOpen && <Icon aria-label="button" icon="chevron-down" />}
        {!isOpen && <Icon aria-label="button" icon="chevron-up" />}
      </div>
      {!!isOpen && children}
    </Segment>
  );
};
