import React, { useContext } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { UserContext } from '../Contexts';
import QDropdown from '../common/Dropdown';
import FeedbackMenu from './FeedbackMenu';

const AccountDropdown = ({ user, ...rest }) => {
  const userName = user
    ? user.fullname || user.email || user.username || 'My account'
    : 'Log in';

  return (
    <QDropdown aria-label="My Account Dropdown" text={userName} {...rest}>
      <Dropdown.Menu>
        <Dropdown.Item as={Link} to="/account" text="My account" />
        <Dropdown.Item as="a" href="https://officehours.cs.umd.edu/docs/" text="Support" />
        <FeedbackMenu />
        <Dropdown.Item as="a" href="https://quuly.com" text="About Quuly" />
        <Dropdown.Divider />
        {/* <Dropdown.Item text="Switch institution" /> */}
        <Dropdown.Item as={Link} to="/logout" text="Log out" />
      </Dropdown.Menu>
    </QDropdown>
  );
};
export default ({ ...rest }) => {
  const me = useContext(UserContext);

  return <AccountDropdown user={me} {...rest} />;
};
