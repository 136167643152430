import React, { useContext, useRef } from 'react';

import { RouteComponentProps } from 'react-router';

import { H3, Icon, Spinner } from '@blueprintjs/core';
import { useQuery } from 'react-apollo';
import { FAQItem, AddFAQItem } from './FAQItem';
import gql from 'graphql-tag';
import { Loader, Segment } from 'semantic-ui-react';

import { CourseIDContext, UserContext } from '../Contexts';
import * as Logging from '../util/logging';
import ComingSoonView from '../ComingSoon/ComingSoonView';
import RoleGate from '../common/RoleGate';

const FAQQuery = gql`
  query FAQQuery($courseID: String!) {
    course(ID: $courseID) {
      faq {
        id
        creator {
          fullname
          username
        }
        votes
        question
        answer
        tags {
          tag
        }
      }
      tags {
        tag
        id
      }
    }
  }
`;

const SampleFAQItem: React.FC<any> = () => {
  return (
    <FAQItem answer="Quuly intelligently warns students of common questions before they enter the help queue. Instructors and TAs can add frequently asked questions and all users can view them in the FAQ feed view and vote on their helpfulness." />
  );
};

const IS_FAQ_ENABLED: boolean = process.env.REACT_APP_DISABLE_FAQ !== 'true';

const FAQView: React.FC<RouteComponentProps<any>> = props => {
  const user = useContext(UserContext);
  const courseID = useContext(CourseIDContext);
  const addQuestionInputRef = useRef<HTMLInputElement>(null);
  const onFAQCellClick = () => {
    if (!addQuestionInputRef.current) return;

    addQuestionInputRef.current!.focus();
  };

  const { loading, error, data } = useQuery(FAQQuery, {
    variables: {
      courseID: courseID || ''
    },
    fetchPolicy: 'no-cache',
    skip: !courseID || !IS_FAQ_ENABLED
  });

  if (IS_FAQ_ENABLED && !IS_FAQ_ENABLED) {
    return <ComingSoonView featureName="FAQ Feed" />;
  }

  if (error) Logging.error('FAQ', error);

  return (
    <>
      <div>
        <H3>Frequently asked questions</H3>
      </div>
      {loading && <Loader />}
      <RoleGate user={user} instructor ta>
        <AddFAQItem
          course={data?.course}
          formVariant
          addQuestionInputRef={addQuestionInputRef}
        />
      </RoleGate>
      {!loading && (
        <>
          {(data?.course?.faq || []).map((faqItem: any) => (
            <FAQItem key={faqItem.id} {...faqItem} />
          ))}
          <SampleFAQItem />
          <RoleGate user={user} student>
            <Segment padded>
              Ask your instructor or assistants to add more FAQs to save you
              time in office hours
            </Segment>
          </RoleGate>
        </>
      )}
      {loading && <Spinner />}
      {/*
      <RoleGate user={user} instructor ta>
        <AddFAQItem course={data?.course} onClick={onFAQCellClick} />
      </RoleGate>
        */}
    </>
  );
};

export default FAQView;
