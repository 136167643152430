import React, { useContext } from 'react';

import * as Contexts from '../Contexts';

import ConvoHistory from './ConvoHistory';

const ConvoHistoryContainer = () => {
  const courseID = useContext(Contexts.CourseIDContext);
  return <ConvoHistory courseID={courseID} />;
};

export default ConvoHistoryContainer;
