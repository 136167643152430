import React from 'react';
import { Label } from 'semantic-ui-react';

const UserStatusSection = ({ user, isMe, pending }) => (
  <>
    {user?.isAdmin && <Label>Admin</Label>}
    {(!!user?.pending || !!pending) && (
      <Label color="orange">Pending invite</Label>
    )}
    {!isMe && !(pending || user?.pending) && <Label basic>Active user</Label>}
    {isMe && <Label color="blue">You</Label>}
  </>
);

export default UserStatusSection;
