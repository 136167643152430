// TODO: test if node_env is production
const SHOULD_ENABLE_DEBUG = process.env.REACT_APP_IS_DEBUG === 'true';

export function log(compName = 'Unknown', ...args) {
  console.log(`[${compName}]`, ...args);
}

export function debug(compName = 'Unknown', ...args) {
  if (!SHOULD_ENABLE_DEBUG) return;
  console.log(`[${compName} debug]`, ...args);
}

export function info(compName = 'Unknown', ...args) {
  console.info(`[${compName} info]`, ...args);
}

export function warning(compName = 'Unknown', ...args) {
  console.warning(`[${compName} warning]`, ...args);
}

export function error(compName = 'Unknown', ...args) {
  console.error(`[${compName} error]`, ...args);
}
