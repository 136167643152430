import React, { useContext } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { useMutation } from 'react-apollo';
import * as Contexts from '../Contexts.jsx';
import * as Logging from '../util/logging';
import gql from 'graphql-tag';

import * as Toasts from '../util/toast';
import { IConversation } from '../SharedLander/UnifiedInbox';
import { InteractConversationMutation } from '../../api/queries.jsx';

const CLEAR_CONFIRM_MESSAGE =
  'Are you sure you want to clear the queue for this room?';

const REMOVE_CONFIRM_MESSAGE =
  'Are you sure you want to remove this session(s)?';

const ClearAllMutation = gql`
  mutation clearAll($courseID: String!, $roomID: String!) {
    clearAll(course: $courseID, room: $roomID) {
      success
      message
    }
  }
`;

const onClearComplete = (data: any) => {
  Logging.info('ClearAllButton', 'Session clear mutation complete', data);

  Toasts.successToast('All questions cleared');
};

const onClearError = (error: any) => {
  Toasts.warningToast('Something went wrong removing session(s)');
  Logging.error('ClearAllButton', error);
};

interface IDequeueButtonProps {
  convos?: IConversation[];
  disabled?: boolean;
  [x: string]: any;
}

const ClearAllButton: React.FC<Partial<IDequeueButtonProps>> = ({
  convos,
  disabled,
  ...rest
}) => {
  const courseID = useContext(Contexts.CourseIDContext);
  const roomID = useContext(Contexts.RoomIDContext);

  const [clearAll, { loading: loadingClearAll }] = useMutation(
    ClearAllMutation,
    {
      onCompleted: onClearComplete,
      onError: onClearError,
      refetchQueries: [
        'StaffLanderQuery',
        'StudentLanderQuery',
        'RoomListQuery',
        'ActiveTAsQuery'
      ]
    }
  );

  const [interact, { loading: loadingInteractionMutation }] = useMutation(
    InteractConversationMutation,
    {
      onCompleted: onClearComplete,
      onError: onClearError,
      refetchQueries: [
        'StaffLanderQuery',
        'StudentLanderQuery',
        'RoomListQuery',
        'ActiveTAsQuery'
      ]
    }
  );

  const loading = loadingInteractionMutation || loadingClearAll;

  const onRemoveClick = () => {
    if (!convos?.length) {
      // eslint-disable-next-line no-restricted-globals
      const ok = window.confirm(CLEAR_CONFIRM_MESSAGE);
      if (!ok) return;
      clearAll({ variables: { courseID, roomID } });
    } else {
      // eslint-disable-next-line no-restricted-globals
      const ok = window.confirm(REMOVE_CONFIRM_MESSAGE);
      if (!ok) return;
      const [convo] = convos;
      interact({
        variables: {
          conversation: convo?.id,
          interactionType: 'leave'
        }
      });
    }
  };

  return (
    <Button
      onClick={() => {
        onRemoveClick();
      }}
      icon
      disabled={disabled || loading}
      {...rest}
    >
      {!convos?.length ? 'Clear all' : `Remove (${convos.length})`}
      {loading ? <Icon name="circle notched" loading /> : <Icon name="times" />}
    </Button>
  );
};

export default ClearAllButton;
