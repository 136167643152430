import React, { useContext, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { AppContext } from '../components/Contexts';
import LoadingView from './LoadingView';

// Simple wrapper over our logout function
export default ({
  history,
  match: { params: { courseID } } = { params: {} },
}) => {
  const { onCourseSelected } = useContext(AppContext);
  useEffect(() => {
    if (!courseID) return;
    onCourseSelected(courseID);
    history.push('/');
  }, [courseID, onCourseSelected]);
  if (!courseID) {
    return (
      <Redirect
        to={{
          pathname: '/selectCourse',
        }}
      />
    );
  }
  return <LoadingView />;
};
