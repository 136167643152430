import React from 'react';
import {
  Modal, Header, Segment, List, Label,
} from 'semantic-ui-react';

import { getFullChangelog } from '../util/changelog';

/**
 * Changelog displays the most recent site changes
 */

const Version = ({ update }) => (
  <Segment>
    <Header>
      {`${update.displayVersion} ${!!update.isLatest && <Label>Latest</Label>}`}
    </Header>
    {Object.keys(update.Changelog).map((what) => (
      <div key={what}>
        <Header as="h4">{what}</Header>
        <List as="ul">
          {update.Changelog[what].map((item) => (
            <List.Item as="li" key={item}>
              {item}
            </List.Item>
          ))}
        </List>
        <p>{}</p>
      </div>
    ))}
  </Segment>
);

const changleogJSON = getFullChangelog();

const Changelog = ({ history }) => (
  <Modal
    open
    closeIcon
    onClose={() => {
      history.replace(history.location.pathname.replace('/changelog', ''));
    }}
  >
    <Modal.Header>Quuly Platform Changelog</Modal.Header>

    <Modal.Content scrolling>
      <div>
	If you notice any issues, please drop us a line at officehours-help@cs.umd.edu
      </div>
      <Segment.Group>
        {Object.values(changleogJSON).map((update) => (
          <Version key={update.displayVersion} update={update} />
        ))}
      </Segment.Group>
    </Modal.Content>
  </Modal>
);

export default Changelog;
