import React, { useContext } from 'react';
import {
  Modal, Grid, Icon, Button,
} from 'semantic-ui-react';
import OnboardingActions from './OnboardingActions.tsx';
import InviteForm from './InviteForm';
import { UserContext } from '../Contexts';

export default ({ setStage }) => {
  const user = useContext(UserContext);
  return (
    <>
      <Modal.Header>Welcome to Quuly</Modal.Header>

      <Modal.Content>
        <Grid columns={2} divided container stackable centered>
          <Grid.Column verticalAlign="middle" width={8}>
            <Grid centered>
              <Grid.Column verticalAlign="middle" width={12}>
                <h3>Join existing space</h3>
                <p style={{ paddingTop: '1em' }}>
                  Does your course or group already use Quuly? If so, enter your
                  invite code.
                </p>
                <InviteForm />
              </Grid.Column>
            </Grid>
          </Grid.Column>
          <Grid.Column verticalAlign="middle" width={8}>
            <Grid centered>
              <Grid.Column verticalAlign="middle" width={12}>
                <h3>Add a new course or group</h3>
                <p style={{ padding: '1em 0' }}>
                  Get started with Quuly by creating a learning space.
                </p>
                <Button color="blue" onClick={() => setStage('CREATE_SPACE')}>
                  <Icon name="plus" />
                  Create space
                </Button>
              </Grid.Column>
            </Grid>
          </Grid.Column>
        </Grid>
      </Modal.Content>
      <OnboardingActions
        onBack={() => {
          if (user.isAccountInfoRequired) {
            setStage('COMPLETE_ACCOUNT');
          } else {
            setStage('ABOUT_YOU');
          }
        }}
      />
    </>
  );
};
