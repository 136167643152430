import { useContext } from 'react';
import { useQuery } from 'react-apollo';
import { CourseIDContext } from '../Contexts';
import { CourseOverviewQuery } from '../../api/queries';
import * as Logging from './logging';

export default (name) => {
  const courseID = useContext(CourseIDContext);

  const {
    data, error, loading, fetchMore,
  } = useQuery(CourseOverviewQuery, {
    variables: {
      courseID,
    },
    skip: !courseID,
  });

  if (error) {
    Logging.error(name, 'CourseOverviewQuery', error, data);
  }

  return [data, loading, courseID, error, fetchMore];
};
