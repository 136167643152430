import React, { useContext } from 'react';

import { Responsive, Icon, Dropdown } from 'semantic-ui-react';

import './TopNavMenu.css';
import DutyToggle from '../common/DutyToggle';
import CoursesDropdownContainer from './CoursesDropdown';
import { UserContext } from '../Contexts';
import RoleGate from '../common/RoleGate';
import LogoSignature from '../common/LogoSignature';
import RoomsDropdown from './RoomsDropdown';
import AccountDropdown from './AccountDropdown';

const TopNavMenu = ({ onOpenSidebarClick }) => {
  const user = useContext(UserContext);

  return (
    <div className="topMenu">
      <div className="topMenuContent">
        <div className="leftHandContent">
          <Icon
            role="button"
            size="large"
            name="bars"
            style={{ marginRight: '.75em', cursor: 'pointer' }}
            onClick={(e) => {
              if (onOpenSidebarClick) {
                onOpenSidebarClick(e);
              }
            }}
          />
          <Responsive as={React.Fragment} minWidth={1305}>
            <LogoSignature
              style={{ width: '80px', height: '23px', marginRight: '.75em' }}
            />
          </Responsive>

          <div className="appGlobalsContainer">
            <div style={{ marginRight: '.75em' }}>
              <CoursesDropdownContainer
                dropdownProps={{
                  pointing: true,
                  item: true,
                  direction: 'right',
                }}
              />
            </div>
          </div>
        </div>

        <Responsive minWidth={580} as="div" className="centeredFlex">
          {/* <div style={{ marginRight: '.75em' }}>UMD Admin </div> */}
          <div>
            <Icon name="user" />
            <AccountDropdown direction="left" pointing item />
          </div>
        </Responsive>
      </div>
    </div>
  );
};

export default TopNavMenu;
