import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import { Button } from 'semantic-ui-react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { eventId: null };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    const { hasError, eventId } = this.state;
    const { children } = this.props;
    if (hasError) {
      // render fallback UI

      return (
        <>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <h2>😰 Welp this is embaressing</h2>
            <p>
              Something went wrong, we have been notified of the issue and we
              will look into it.
            </p>
            <p>
              <i>-The Quuly Team</i>
            </p>
            <Button
              color="blue"
              onClick={() => {
                Sentry.showReportDialog({ eventId });
              }}
            >
              Report feedback
            </Button>
          </div>
        </>
      );
    }

    // when there's not an error, render children untouched
    return children;
  }
}

export default ErrorBoundary;
