import React from 'react';
import { Dropdown } from 'semantic-ui-react';

const options = [
  { name: 'Any', value: 'any', text: 'Any' },
  { name: 'Enqueued', value: 'in_queue', text: 'Enqueued' },
  { name: 'Being helped', value: 'being_helped', text: 'Being helped' },
  { name: 'Ongoing', value: 'ongoing', text: 'Ongoing' },
  { name: 'Resolved', value: 'resolved', text: 'Resolved' },
];

export default ({ convoStateForQuery, setConvoStateForQuery }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    State: &nbsp;
    <Dropdown
      selection
      options={options}
      value={convoStateForQuery}
      onChange={(ignore, { value }) => setConvoStateForQuery(value)}
    />
  </div>
);
