import * as React from 'react';
import distanceInWordsToNow from 'date-fns/distance_in_words_to_now';
import { IConversation } from './UnifiedInbox';
import UserImage from '../User/UserImage';

import './InboxItemCard.css';
import { Checkbox } from '@blueprintjs/core';

const SHOULD_ENABLE_CHECKBOXES = false;

interface IInboxItemCard {
  conversation?: IConversation;
  active?: boolean;
  skeleton?: boolean;
  onClick?: (wasChecked: boolean, conv: IConversation) => void;
}

export const InboxItemCard: React.FC<IInboxItemCard> = ({
  conversation: convo,
  onClick,
  active
}) => {
  const [isCheckSelected, setIsCheckSelected] = React.useState(!!active);
  const handleOnClick = () => {
    if (convo && onClick) {
      onClick(!isCheckSelected, convo);
      setIsCheckSelected(!isCheckSelected);
    }
  };
  React.useEffect(() => {
    if (active === true) {
      setIsCheckSelected(true);
    }
  }, [setIsCheckSelected, active]);

  if (!convo) {
    return (
      <div className="card-container card-container-disabled ">
        {SHOULD_ENABLE_CHECKBOXES && <Checkbox checked={isCheckSelected} />}
        <div className="card-content card-content-disabled">
          <div className="image-section">
            <div className="image-detail"></div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="card-container">
        {SHOULD_ENABLE_CHECKBOXES && (
          <Checkbox checked={isCheckSelected} onClick={handleOnClick} />
        )}
        <div
          className={active ? 'card-content-active' : 'card-content'}
          onClick={handleOnClick}
        >
          <strong>{convo.topic || 'Help request'}</strong>
          <div className="image-section">
            <UserImage width={40} url={convo.creator?.photoUrl} />
            <div className="image-detail">
              <div>
                {convo.creator?.fullname ||
                  convo.creator?.email ||
                  'Unnamed student'}
              </div>
              <div>{distanceInWordsToNow(convo.created)} ago</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
