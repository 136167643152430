import React, { useEffect } from 'react';
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
  Divider,
  Responsive,
  Input,
  Image,
} from 'semantic-ui-react';
import { Link, Route } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

import './Login.css';

import { Callout } from '@blueprintjs/core';
import Changelog from '../About/Changelog';
import * as Constants from '../../constants';
import LogoSignature from '../common/LogoSignature';
import Fade from '../common/Fade';
import LoadingView from '../../EntryPoint/LoadingView';
import { doLogin, doSignup } from './RegistrationUtils';

const onIAMLogin = () => {
  fetch(`${process.env.REACT_APP_IAM_URL}`, {
    method: 'POST',
    mode: 'cors',
    credentials: 'same-origin',
  })
    .then((res) => {
      if (res.status === 200) {
        return res.text();
      }
      throw new Error(`Registration error:${res}`);
    })
    .then((responseUrl) => {
      window.location = responseUrl;
    })
    .catch((e) => console.log(e));
};

const LoginPageButton = (props) => (
  <Button type="submit" primary fluid size="large" {...props} />
);

const getErrorText = (errorType) => {
  switch (errorType) {
    case Constants.NO_ACCOUNT:
      return 'No account exists for the provided credentials.';
    case Constants.SERVER_ERROR:
      return 'Internal server error. Try again later.';
    case Constants.INVALID_TOKEN:
      return 'Invalid login token. Please try logging in again.';
    case Constants.INVALID_INVITE:
      return 'Invalid invite link. Please contact your instructor.';
    default:
      return 'Please try logging in again. If the problem continues, contact an administrator.';
  }
};

const getMessageText = (messageType) => {
  switch (messageType) {
    case Constants.LOGIN_TIMEOUT:
      return "For your security, you've been signed out.";
    case Constants.LOGIN_LOGOUT:
      return "You've been successfully signed out";
    default:
      return '';
  }
};

const OrDivider = () => (
  <Responsive
    as={Divider}
    maxWidth={Constants.MAX_MOBILE_WIDTH}
    horizontal
    header="or"
  >
    or
  </Responsive>
);

const IAMSignIn = ({ withDivider }) => (
  <Grid.Column>
    <Header icon>
      <LoginPageButton onClick={() => onIAMLogin()}>
        <span>UMD Computer Science Login</span>
      </LoginPageButton>
    </Header>
    {!!withDivider && <OrDivider />}
  </Grid.Column>
);

const onGoogleLogin = () => {
  window.location = `${process.env.REACT_APP_API_URL}/oauth`;
};

const GoogleSignIn = ({ asSignupVariant }) => (
  <Grid.Column>
    <Header icon>
      <LoginPageButton onClick={() => onGoogleLogin()}>
        {asSignupVariant ? (
          <span>Signup with Google</span>
        ) : (
          <span>Login with Google</span>
        )}
      </LoginPageButton>
    </Header>
    <OrDivider />
  </Grid.Column>
);

const EmailSignIn = ({ asSignupVariant, setEmail, recaptchaRef }) => (
  <Grid.Column>
    {asSignupVariant ? (
      <Header>Sign up with Email</Header>
    ) : (
      <Header>Login with Email</Header>
    )}
    <Form.Field>
      <Input
        onChange={(e) => setEmail(e.target.value)}
        fluid
        icon="user"
        iconPosition="left"
        placeholder="E-mail address"
        type="email"
        required
      />
    </Form.Field>
    <ReCAPTCHA
      sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
      size="invisible"
      ref={recaptchaRef}
    />
    <br />
    <LoginPageButton>
      {asSignupVariant ? (
        <>Sign up</>
      ) : (
        <>Login</>
      )}
    </LoginPageButton>
  </Grid.Column>
);

const SplitLogin = (props) => {
  const [email, setEmail] = React.useState('');
  const recaptcha = React.createRef();

  return (
    <Grid columns={1} stackable textAlign="center">
      <Grid.Row verticalAlign="middle">
        <Grid.Column>
          <Form
            size="large"
            onSubmit={() => {
              if (props.asSignupVariant) {
                recaptcha.current.execute().then((val) => doSignup(email, val));
              } else {
                recaptcha.current.execute().then((val) => doLogin(email, val));
              }
            }}
          >
            <EmailSignIn
              {...props}
              setEmail={setEmail}
              recaptchaRef={recaptcha}
            />
          </Form>
          <Divider horizontal />
          <IAMSignIn withDivider {...props} />
          <GoogleSignIn {...props} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

const IAMLogin = (props) => (
  <Form size="large">
    <Grid columns={1} stackable textAlign="center">
      <Grid.Row verticalAlign="middle">
        <Grid.Column>
          <IAMSignIn {...props} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Form>
);

const IAMLoginFooter = () => null;

const LoginFooter = ({ asSignupVariant }) => (asSignupVariant ? (
  <Callout>
    Already have an account? <Link to="/login">Log in</Link>
  </Callout>
) : (
  <Callout>
    New to us? <Link to="/signup">Sign Up</Link>
  </Callout>
));

const UMDLogo = () => (
  <Image
    alt="University of Maryland"
    src={`${process.env.PUBLIC_URL}/img/UMD_primary_mark.jpg`}
  />
);

// The better way to do this is probably with children
const LoginView = ({ asSignupVariant, location }) => {
  const asIAMOnly = process.env.REACT_APP_IAM_ONLY_LOGIN === 'true';
  const isSaaS = process.env.REACT_APP_IS_SAAS === 'true';

  const searchParams = new URLSearchParams(location.search);
  const messageType = searchParams.get('msg');
  const errType = searchParams.get('error');

  useEffect(() => {
    if (isSaaS) {
      window.location.href = `${process.env.REACT_APP_SAAS_LOGIN_REDIRECT}/?${searchParams.toString()}`;
    }
  });

  if (isSaaS) {
    return <LoadingView />;
  }
  // this is bad
  document.body.style = 'background: #f6f8fb';

  return (
    <Fade>
      {/* Drop our changelog component here since it's an absolutely positioned modal */}
      <Route path="*(/changelog)" component={Changelog} />

      <Grid
        textAlign="center"
        className="loginContainer"
        verticalAlign="middle"
      >
        <Grid.Column className="centerColumn">
          <div style={{ margin: '1em 0', textAlign: 'left' }}>
            {!!messageType && (
              <Callout textAlign="left" intent="primary" title="Heads up">
                {getMessageText(messageType)}
              </Callout>
            )}
          </div>
          <div style={{ margin: '1em 0', textAlign: 'left' }}>
            {errType ? (
              <Callout imtent="danger" header="Uh oh...">
                {getErrorText(errType)}
              </Callout>
            ) : null}
          </div>
          <Segment padded>
            <LogoSignature
              color="blue"
              className="loginHeader"
              centered
              style={{ width: '12.75em', marginBottom: '1em' }}
            />

            {asIAMOnly ? (
              <IAMLogin />
            ) : (
              <SplitLogin asSignupVariant={asSignupVariant} />
            )}

            <div style={{ margin: '1em 0' }}>
              {asIAMOnly ? (
                <IAMLoginFooter />
              ) : (
                <LoginFooter asSignupVariant={asSignupVariant} />
              )}
            </div>
          </Segment>

          <a href="https://quuly.com">Learn more about Quuly</a>
        </Grid.Column>
      </Grid>
    </Fade>
  );
};

export default LoginView;
