import React from 'react';
import { Icon } from '@blueprintjs/core';

interface UserPlaceholderImageProps {
  onUpload?: () => void;
  width?: number;
}

const UserPlaceholderImage: React.FC<UserPlaceholderImageProps> = ({
  onUpload,
  width = 120
}) => (
  <Icon
    icon="user"
    iconSize={width}
    color="gray"
    onClick={() => {
      if (onUpload) onUpload();
    }}
    style={onUpload ? { cursor: 'pointer' } : {}}
  />
);

export default UserPlaceholderImage;
