import React from 'react';
import { Modal, Button, Grid } from 'semantic-ui-react';
import OnboardingActions from './OnboardingActions.tsx';

const CreateSpace = ({ setStage, setSpaceType }) => (
  <>
    <Modal.Header>Create a space</Modal.Header>

    <Modal.Content>
      <Grid container stackable centered>
        <Grid.Column verticalAlign="middle" width={10}>
          <h3>What&apos;s the purpose of your new space?</h3>
          <p style={{ padding: '1.25em' }}>
            We tailor Quuly depending on the purpose of your space.
          </p>
          <div style={{ marginBottom: '1em' }}>
            <Button.Group basic vertical size="large">
              <Button
                onClick={() => {
                  setSpaceType('course');
                  setStage('CONFIGURE');
                }}
              >
                Course
              </Button>
              <Button
                onClick={() => {
                  setSpaceType('tutoring_group');
                  setStage('CONFIGURE');
                }}
              >
                Tutoring group
              </Button>
              <Button
                onClick={() => {
                  setSpaceType('library');
                  setStage('CONFIGURE');
                }}
              >
                Library
              </Button>
              <Button
                onClick={() => {
                  setSpaceType('learning_space');
                  setStage('CONFIGURE');
                }}
              >
                Learning space
              </Button>
              <Button
                style={{ display: 'none' }}
                disabled
                onClick={() => {
                  setSpaceType('other');
                  setStage('CONFIGURE');
                }}
              >
                Advising or other space (coming soon)
              </Button>
            </Button.Group>
          </div>
        </Grid.Column>
      </Grid>
    </Modal.Content>
    <OnboardingActions
      onBack={() => {
        setStage('STAFF_ENTRY');
      }}
    />
  </>
);

export default CreateSpace;
