import { useMutation } from 'react-apollo';
import * as Toasts from '../util/toast';
import { UpdateUserMutation } from '../../api/queries';

const onUserUpdateError = (error) => {
  console.error(error);
  Toasts.warningToast('Something went wrong updating this user');
};

const onUserUpdate = (avoidToast, msg) => {
  if (avoidToast) return;
  Toasts.successToast('User Updated');
};

export default (avoidToast) => {
  const [editUser] = useMutation(UpdateUserMutation, {
    onCompleted: () => onUserUpdate(avoidToast),
    onError: onUserUpdateError,
    refetchQueries: [
      'RootQuery',
      'UserDrilldownQuery',
      'StaffLanderQuery',
      'StudentLanderQuery',
    ],
  });

  return [editUser];
};
