import React from 'react';
import './Toggle.css';

const Toggle = ({ onClick, label, toggled }) => (
  <div className="toggleContainer">
    <label htmlFor="toggle" className="switch">
      <input
        aria-label={label}
        type="checkbox"
        name="toggle"
        id="toggle"
        checked={!!toggled}
        onChange={(e) => {
          if (onClick) {
            onClick(e);
          }
        }}
      />
      <span className="slider round" />
    </label>
  </div>
);

export default Toggle;
