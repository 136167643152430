import React, { useState } from 'react';
import { Mutation } from 'react-apollo';
import { Form, Radio } from 'semantic-ui-react';
import gql from 'graphql-tag';
import { AssignRoomMutation } from '../../../api/queries';
import * as Toasts from '../../util/toast';
import * as Logging from '../../util/logging';

const CreateRoomMutation = gql`
  mutation createRoomMutation(
    $institutionID: String!
    $location: String!
    $name: String!
    $type: RoomType!
  ) {
    createRoom(
      room: {
        institution: $institutionID
        location: $location
        name: $name
        type: $type
      }
    ) {
      id
      name
    }
  }
`;

const onMutationError = (error) => {
  Logging.error('RoomAddForm', error);
  Toasts.warningToast('Something went wrong while creating a room');
};

const MAX_ROOM_LOC_LENGTH = 8;
const MAX_ROOM_NAME = 16;

const RoomAddForm = ({ institutionID, course }) => {
  const [location, setLocation] = useState('');
  const [name, setName] = useState('');
  const [roomType, setRoomType] = useState('virtual');

  return (
    <Form>
      <Form.Group inline>
        <label htmlFor="roomType">Room type</label>
        <Form.Field
          control={Radio}
          label="Virtual"
          value="virtual"
          checked={roomType === 'virtual'}
          onChange={() => setRoomType('virtual')}
        />
        <Form.Field
          control={Radio}
          label="Physical"
          value="physical"
          checked={roomType === 'physical'}
          onChange={() => setRoomType('physical')}
        />
      </Form.Group>
      {roomType === 'physical' && (
        <Form.Input
          fluid
          label="Optional room description"
          placeholder="ex: Dr. Smith's Office, TA Room etc"
          value={name || location}
          onChange={(e) => setName(e.target.value)}
        />
      )}
      {roomType === 'physical' && (
        <Form.Input
          fluid
          label="Room Number/Location"
          placeholder="eg: AVW4110"
          required
          value={location}
          onChange={(e) => setLocation(
            e.target.value
              .trim()
              .toUpperCase()
              .substring(0, MAX_ROOM_LOC_LENGTH),
          )}
        />
      )}
      {roomType === 'virtual' && (
        <Form.Input
          fluid
          label="Virtual room name"
          placeholder="eg: Exam Review Room"
          required
          value={location}
          onChange={(e) => setLocation(e.target.value.substring(0, MAX_ROOM_NAME))}
        />
      )}
      <Mutation
        mutation={AssignRoomMutation}
        onError={onMutationError}
        refetchQueries={[
          'RoomsQuery',
          'CourseDrilldownQuery',
          'RoomListQuery',
          'ActiveTAsQuery',
        ]}
      >
        {(assignRoom) => {
          const onCompleted = (data) => {
            Logging.info('RoomAddForm', 'room assign complete', data);
            Toasts.successToast(
              `Added new room ${data.createRoom ? data.createRoom.name : ''}`,
            );
            setLocation('');
            setName('');
            if (course && course.id) {
              assignRoom({
                variables: {
                  room: data.createRoom.id,
                  courseID: course.id,
                },
              });
            }
          };

          return (
            <Mutation
              mutation={CreateRoomMutation}
              onError={onMutationError}
              onCompleted={onCompleted}
              refetchQueries={['RoomsQuery']}
            >
              {(createRoom) => (
                <Form.Button
                  color="blue"
                  disabled={!name && !location}
                  onClick={() => createRoom({
                    variables: {
                      location,
                      name:
                          name
                          || (roomType === 'virtual'
                            ? `${location} (Virtual)`
                            : location),
                      institutionID,
                      type: roomType,
                      // Defaulting to url for now
                      url: `/${course.id}`,
                    },
                  })}
                >
                  Add room
                </Form.Button>
              )}
            </Mutation>
          );
        }}
      </Mutation>
    </Form>
  );
};

export default RoomAddForm;
