import React, { useState, useEffect } from 'react';
import { Route } from 'react-router-dom';
import useSound from 'use-sound';
import { useSubscription } from '@apollo/react-hooks';
import { H3 } from '@blueprintjs/core';
import * as Logging from '../util/logging';
import { ConvoSubscription } from './StudentLanderContents';

import UserDrilldownView from '../User/UserDrilldownView';
import PlaceholderCurrentConvo from './PlaceholderCurrentConvo';

import StaffTutorial from './StaffTutorial';
import RoleGate from '../common/RoleGate';
import { successToast } from '../util/toast';
import { localPushNotification } from '../../api/realtime';
import notificationSound from './notification.mp3';
import { UnifiedInbox } from './UnifiedInbox';

const filterMyConversationsFactory = (user) => (conv) => user
  && user.externalid
  && conv
  && conv.currentResponder
  && conv.currentResponder.externalid === user.externalid;

const StaffLanderContents = ({
  courseID, currentRoomID, user, data, refetch,
}) => {
  const [lastQueueSize, setLastQueueSize] = useState(null);
  const { course } = data;
  const { rooms, session, beingHelpedConvs } = course;

  const myBeingHelpedConvs = (beingHelpedConvs || []).filter(
    filterMyConversationsFactory(user),
  );

  const [firstBeingHelpedConvo] = myBeingHelpedConvs;
  useSubscription(ConvoSubscription, {
    variables: { convoID: firstBeingHelpedConvo?.id },
    fetchPolicy: 'no-cache',
    onSubscriptionData: () => {
      try {
        if (typeof refetch === 'function') refetch();
      } catch (err) {
        Logging.error('StaffLanderContents', 'Subscription error', err);
      }
    },
    skip: !firstBeingHelpedConvo,
  });

  const [playNotification] = useSound(notificationSound);

  const [currentRoom] = rooms.filter((room) => room.id === currentRoomID);

  useEffect(() => {
    const hasQueueChanged = currentRoom?.queue && lastQueueSize !== currentRoom?.queue.size;
    if (
      hasQueueChanged
      && lastQueueSize != null
      && currentRoom.queue.size > lastQueueSize
    ) {
      const body = `A student has joined the ${course.code} queue`;
      localPushNotification(body, course.code);
      successToast(<>{body}</>);
      playNotification();
    }
    if (hasQueueChanged) {
      setLastQueueSize(currentRoom.queue.size);
    }
  }, [lastQueueSize, currentRoom]);

  return (
    <>
      <Route path="/:courseID/users/:userID" component={UserDrilldownView} />
      <RoleGate user={user} instructor>
        <StaffTutorial course={course} />
      </RoleGate>
      <H3>
        {course.code} Inbox
      </H3>
      <UnifiedInbox
        enqueuedConversations={currentRoom?.queue?.conversations || []}
        currentConversations={myBeingHelpedConvs}
        course={course}
        currentRoom={currentRoom}
      />
    </>
  );
};

export default StaffLanderContents;
