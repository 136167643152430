import React from 'react';
import { Header, Button, Segment } from 'semantic-ui-react';
import * as Logging from '../util/logging';

export default ({ featureName = '' }) => (
  <Segment textAlign="center" style={{ marginTop: '1em' }}>
    <Header>
      {featureName} Early Access
    </Header>
    <p>
      We are currently testing this feature, please notify us if you or your
      course is interested in early access. We continuously test and update
      Quuly Core to make it the easiest way to organize office hours, peer
      tutoring, and more.
    </p>
    <Button
      color="blue"
      onClick={() => Logging.info('ComingSoonView', 'Get notified button pressed')}
    >
      Request early access
    </Button>
  </Segment>
);
