import React, { useState, useEffect } from 'react';

import { ApolloProvider, useQuery, useMutation } from 'react-apollo';

import { useCookies } from 'react-cookie';
import { BrowserRouter } from 'react-router-dom';

import LoadingView from './EntryPoint/LoadingView';
import ErrorView from './components/common/ErrorView';

import AppRouter from './routes/RootRouter';
import AppHat from './EntryPoint/AppHat';
import * as Constants from './constants';
import useInterval from './components/util/useInterval';
import * as Logging from './components/util/logging';
import * as Contexts from './components/Contexts';
import * as Queries from './api/queries';
import Fade from './components/common/Fade';
import { doesAccountRequireCompletion } from './components/util/user-utils';

import { client } from './api/graphql';

const INSTITUTION_ID = process.env.REACT_APP_INSTITUTION || 'DEFAULT';
const AUTH_SUCCESS = 'SUCCESS';
const FETCH_MODE = 'cors';
// Poll the verifyJWT endpoint every 30 seconds
const JWT_POLL_MS = 30 * 1000;
const DISABLE_JWT = false;

const COOKIE_NAME = process.env.REACT_APP_QUUKIE;
const extractJWTFromCookie = (cookies) => {
  Logging.debug('Cookies', cookies[COOKIE_NAME]);
  if (!cookies || !cookies[COOKIE_NAME]) {
    return {};
  }
  const [maybeJWT, maybeUserID] = cookies[COOKIE_NAME].split('+');
  Logging.debug('Cookies', maybeJWT, maybeUserID);

  return { maybeJWT, maybeUserID };
};

const PreviewContainer = () => (
  <div
    style={{
      position: 'fixed',
      bottom: '20px',
      right: '20px',
      background: 'white',
    }}
  >
    This is a preview build of Quuly. Please report issues to officehours-help@cs.umd.edu
  </div>
);

/*
// gb Oct 26th 1021
const prefetchCourseData = async (courseID, roomID)  => {
  const {
    data: courseData,
    error: sessionError,
    loading
  } = await client.query({
    query: Queries.TODO
  });

  if (loading) {
    return;
  }

  Logging.debug('Course', sessionData, sessionError);

  if (!sessionData) {
    setIsLoadingSession(false);
    return;
  }
  if (sessionData.lastCourse) {
    setCourseID(sessionData.lastCourse);
  }

  if (sessionData.lastRoom) {
    setRoomID(sessionData.lastRoom);
  }

  // This has to go last because otherwise we subscribe twice. We can move this around once we get a proper state reducer going
  setIsLoadingSession(false);
};

};
*/

const onSessionSet = (session) =>
  Logging.info('App', 'user session updated', session);

// Grab the session
const getSession = async (setCourseID, setRoomID, setIsLoadingSession) => {
  const { data: sessionData, error: sessionError } = await client.query({
    query: Queries.UserSessionQuery,
  });

  Logging.debug('Session', sessionData, sessionError);

  if (!sessionData) {
    setIsLoadingSession(false);
    return;
  }
  if (sessionData.lastCourse) {
    setCourseID(sessionData.lastCourse);
  }

  if (sessionData.lastRoom) {
    setRoomID(sessionData.lastRoom);
  }

  // This has to go last because otherwise we subscribe twice. We can move this around once we get a proper state reducer going
  setIsLoadingSession(false);
};

const AuthenticatedAppContainer = ({
  appContextValue,
  roomID,
  courseID,
  hasAuth,
  isLoadingContainer,
}) => {
  const { loading, error, data } = useQuery(Queries.RootQuery, {
    fetchPolicy: 'no-cache',
    skip: isLoadingContainer,
  });

  if (loading || isLoadingContainer) {
    return <LoadingView />;
  }
  if (error) {
    Logging.error('App', 'graphql error', error);
  }
  if (!loading && (!data || !data.user)) {
    return (
      <div style={{ padding: '30px' }}>
        <ErrorView error={error} data={data} appEntryPoint />
      </div>
    );
  }
  const user = data ? data.user : null;
  if (user) {
    const [maybeEnrollment] = user.enrollments.filter(
      (e) => e.course.id === courseID
    );
    user.role = (maybeEnrollment && maybeEnrollment.type) || null;
    user.isAccountInfoRequired = doesAccountRequireCompletion(user);
  }
  return (
    <Contexts.AppContext.Provider value={appContextValue}>
      <Contexts.UserContext.Provider value={user}>
        <Contexts.RoomIDContext.Provider value={roomID}>
          <Contexts.CourseIDContext.Provider value={courseID}>
            <AppRouter hasAuth={hasAuth} />
          </Contexts.CourseIDContext.Provider>
        </Contexts.RoomIDContext.Provider>
      </Contexts.UserContext.Provider>
    </Contexts.AppContext.Provider>
  );
};

/* Main wrapper, handles auth, polling, and global data. No display here. */
const AppContainer = ({ preload }) => {
  const [cookies, setCookie, removeCookie] = useCookies([COOKIE_NAME]);
  const [jwt, setJWT] = useState('');
  const [userID, setUserID] = useState('');
  const [courseID, setCourseID] = useState('');
  const [roomID, setRoomID] = useState('');

  const [isRenderPaused, setIsRenderPaused] = useState(false);
  const [isLoadingAuth, setIsLoadingAuth] = useState(true);
  const [isLoadingSession, setIsLoadingSession] = useState(true);

  const { maybeJWT, maybeUserID } = extractJWTFromCookie(cookies);

  const [setSession] = useMutation(Queries.SetSessionMutation, {
    onCompleted: onSessionSet,
  });

  // Debug global state transitions
  useEffect(() => {
    Logging.debug('AppRoot', 'State updated', [jwt, userID, courseID, roomID]);
  }, [jwt, userID, courseID, roomID]);

  // TODO: refactor to useReducer
  const onCourseSelected = (newCourseID) => {
    if (newCourseID) {
      // Let Zion know that the user selected a course
      setSession({ variables: { key: 'last_course', value: newCourseID } });
    } else {
      Logging.warning('App', 'Selected a null course');
    }

    setCourseID(newCourseID);
  };

  // TODO: refactor to useReducer
  const onRoomSelected = (newRoomID) => {
    // Let Zion know that the user selected a room
    setSession({ variables: { key: 'last_room', value: newRoomID } });
    setRoomID(newRoomID);
  };

  const onLogout = () => {
    // Unmount the app
    setIsRenderPaused(true);
    removeCookie(COOKIE_NAME, {
      path: '/',
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
    });
    // Add a hacky long timeout since removeCookie appears async and has no callback option
    setTimeout(() => {
      window.location.href = `/login?msg=${Constants.LOGIN_LOGOUT}`;
    }, 1800);
  };

  let appContextValue = {
    onCourseSelected,
    onRoomSelected,
    onLogout,
    institution: INSTITUTION_ID,
  };

  // TODO: refactor to useReducer
  // We only want this to run once, then separately run it on every poll
  const validateAuth = async () => {
    if (maybeJWT) {
      Logging.debug('AppRoot', 'Calling /validateJWT');
      fetch(`${process.env.REACT_APP_API_URL}/validateJWT`, {
        method: 'post',
        mode: FETCH_MODE,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          jwt: maybeJWT,
        }),
      })
        .then((data) => {
          Logging.debug('AppRoot', 'Responce recvd from /validateJWT');
          return data.json();
        })
        .then((data) => {
          if (data.status === AUTH_SUCCESS) {
            // If this is a new session
            if (!jwt) {
              Logging.debug(
                'AppRoot',
                'Auth success response from /validateJWT'
              );
              // PLEASE put these in a reducer
              getSession(onCourseSelected, onRoomSelected, setIsLoadingSession);
              setJWT(maybeJWT);
              setUserID(maybeUserID);
            }
          } else {
            Logging.debug(
              'AppRoot',
              'Received non-success status from /validateJWT',
              data.status
            );

            // If we were logged in before, logout
            if (jwt) {
              removeCookie(COOKIE_NAME, {
                path: '/',
                domain: process.env.REACT_APP_COOKIE_DOMAIN,
              });
              window.location.href = `/login?msg=${Constants.LOGIN_TIMEOUT}`;
            }

            // TODO: error handling
          }
          setIsLoadingAuth(false);
          setIsLoadingSession(false);
        })
        .catch((err) => {
          Logging.error('App', 'JWT validate error', err);
          // TODO: error handling
          setIsLoadingAuth(false);
          setIsLoadingSession(false);
        });
    } else {
      setIsLoadingAuth(false);
      setIsLoadingSession(false);
    }
  };

  useEffect(() => {
    validateAuth();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  // Poll the backend regularly to ensure the JWT is still valid
  useInterval(() => {
    // We will only poll to verify logged in status on successful login
    validateAuth();
  }, JWT_POLL_MS);

  const isLoadingContainer =
    !DISABLE_JWT && (isRenderPaused || isLoadingAuth || isLoadingSession);
  const hasAuth = (!!jwt && !!userID) || DISABLE_JWT;

  if (preload || (!isLoadingContainer && !hasAuth)) {
    return <AppRouter hasAuth={hasAuth} />;
  }

  return (
    <Fade timeout={500}>
      <AuthenticatedAppContainer
        appContextValue={appContextValue}
        roomID={roomID}
        courseID={courseID}
        hasAuth={hasAuth}
        isLoadingContainer={isLoadingContainer}
      />
    </Fade>
  );
};

const App = ({ preload }) => (
  <>
    <AppHat />
    <ApolloProvider client={client}>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <AppContainer preload={preload} />
      </BrowserRouter>
    </ApolloProvider>
  </>
);

export default App;
