import * as React from 'react';
import Collapsable from '../common/Collapsable';
import { EditableText } from '@blueprintjs/core';
import { UserContext } from '../Contexts';
import gql from 'graphql-tag';
import { useDebouncedCallback } from 'use-debounce';
import { useMutation } from '@apollo/react-hooks';

const SET_TAGLINE_MUTATION = gql`
  mutation EditUserMutation($id: String!, $tagline: String) {
    editUser(id: $id, tagline: $tagline) {
      tagline
    }
  }
`;

export const UserTaglineForm: React.FC<any> = () => {
  const me = React.useContext<any>(UserContext);
  const [localStatus, setLocalStatus] = React.useState(me?.tagline || '');
  const [storeTagline] = useMutation(SET_TAGLINE_MUTATION);

  React.useEffect(() => {
    setLocalStatus(me?.tagline || '');
  }, [me]);

  const [debouncedUpdateTagline] = useDebouncedCallback(
    tagline => {
      console.log('values', tagline, me.externalid, me, localStatus);
      storeTagline({
        variables: {
          id: me.externalid,
          tagline
        }
      });
    },
    300,
    // The maximum time func is allowed to be delayed before it's invoked:
    { maxWait: 1500 }
  );

  return (
    <Collapsable expandedOnMount name="My status">
      <div style={{ marginTop: '.5em' }}>
        <EditableText
          placeholder="Add a status (e.g. wearing a red hoodie)..."
          value={localStatus}
          onChange={(value: string) => {
            setLocalStatus(value);
            debouncedUpdateTagline(value);
          }}
        />
      </div>
    </Collapsable>
  );
};
