import React, { useState, useContext } from 'react';
import { Form, Icon } from 'semantic-ui-react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import * as Toasts from '../../util/toast';

import * as Contexts from '../../Contexts.jsx';
import * as Logging from '../../util/logging';

const EditCourseMutation = gql`
  mutation editCourseMutation(
    $ID: String!
    $name: String
    $code: String
    $message: String
    $url: String
    $tokens: Int
    $preferences: EditCoursePreferencesInput
  ) {
    editCourse(
      course: {
        ID: $ID
        name: $name
        code: $code
        message: $message
        link: $url
        tokens: $tokens
        preferences: $preferences
      }
    ) {
      code
      id
    }
  }
`;

export const makeKey = (k) => ({
  key: k,
  text: `${k}`,
  value: k,
});

export const tokenOptions = [
  {
    key: 0,
    text: 'Unlimited',
    value: 0,
  },
  ...[...Array(10).keys()].map((k) => makeKey(k + 1)),
  makeKey(15),
  makeKey(20),
];

const getTokensForState = (tokens) => (!tokens ? null : tokens);

const onMutationError = (error) => {
  Logging.error('CourseEditForm', error);
  Toasts.warningToast('Something went wrong while editing this course');
};

const CourseEditForm = ({ course }) => {
  const [name, setName] = useState((course && course.name) || '');
  const [code, setCode] = useState((course && course.code) || '');
  const [message, setMessage] = useState((course && course.message) || '');
  const [url, setUrl] = useState((course && course.link) || '');
  const [tokens, setTokens] = useState((course && course.tokens) || 0);
  const [inviteCodes, setInviteCodes] = useState(
    (course && course.preferences && course.preferences.enableOpenEnrollment)
      || false,
  );

  const { institution: id } = useContext(Contexts.AppContext);
  if (!id) {
    return <Icon name="circle notched" loading />;
  }

  if (!course) {
    return <div>Cannot edit this course</div>;
  }

  const onCompleted = (data) => {
    Toasts.successToast(`Updated ${data.editCourse.code || ''}`);
    Logging.log('CourseEditForm', data);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    Logging.log('CourseEditForm', 'Editing course', {
      name,
      message,
      code,
    });
  };

  return (
    <Form onSubmit={onSubmit}>
      <Form.Input
        fluid
        label="Course code"
        placeholder="CMSC420"
        required
        value={code}
        onChange={(e) => setCode(e.target.value)}
      />
      <Form.Input
        fluid
        label="Course name"
        placeholder="Fundamentals of data structures"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <Form.Input
        fluid
        label="Student message"
        placeholder="Please submit your code before asking a TA for help"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
      <Form.Input
        fluid
        label="Instructor Zoom Session URL"
        placeholder="https://myuni.edu/courses/CMSC330"
        value={url}
        onChange={(e) => setUrl(e.target.value)}
      />
      <Form.Select
        fluid
        label="Student daily visit limit"
        options={tokenOptions}
        onChange={(e, { value }) => {
          setTokens(value);
        }}
        value={tokens}
      />
      <Form.Checkbox label="Require passcode (coming soon)" disabled />
      <Form.Checkbox
        label="Allow entry by invite code "
        enabled
        checked={inviteCodes}
        onClick={() => setInviteCodes(!inviteCodes)}
      />
      <Mutation
        mutation={EditCourseMutation}
        onError={onMutationError}
        onCompleted={onCompleted}
        refetchQueries={['CourseDrilldownQuery']}
      >
        {(editCourse) => (
          <Form.Button
            color="blue"
            onClick={() => editCourse({
              variables: {
                ID: course.id,
                name,
                code,
                message,
                url,
                tokens: getTokensForState(tokens),
                preferences: {
                  enableOpenEnrollment: inviteCodes,
                },
              },
            })}
          >
            Done
          </Form.Button>
        )}
      </Mutation>
    </Form>
  );
};

export default CourseEditForm;
