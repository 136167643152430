import React, { useContext, useState } from 'react';
import gql from 'graphql-tag';
import { Dropdown } from 'semantic-ui-react';
import { useQuery, useMutation } from 'react-apollo';
import { Link } from 'react-router-dom';
import { RoomFragment, SetOffDutyMutation } from '../../api/queries';
import { CourseIDContext, RoomIDContext, AppContext } from '../Contexts';

import * as Logging from '../util/logging';
import * as Toasts from '../util/toast';

const CourseRoomsQuery = gql`
  ${RoomFragment}
  query CourseRoomsQuery($courseID: String!) {
    course(ID: $courseID) {
      id
      code
      ...RoomParts
    }
    user {
      isOnDuty(course: $courseID)
    }
  }
`;

const switchRoomToast = (roomLoc) => Toasts.successToast(`You've joined ${roomLoc}`);

const createRoomText = (currentRoom) => (currentRoom && currentRoom.location
  ? `Room: ${currentRoom.location}`
  : 'Select room');

const RoomsDropdown = ({
  currentRoom,
  onRoomSelected,
  course,
  isOnDuty,
  dropdownProps = {},
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [setOffDutyRemote] = useMutation(SetOffDutyMutation, {
    onCompleted: () => isOnDuty
      && Toasts.successToast('You\'ve switched rooms and are now off duty.'),
    refetchQueries: ['RoomListQuery', 'ActiveTAsQuery', 'DutyQuery'],
  });
  if (!(course && course.rooms && course.rooms.length)) return null;

  return (
    <Dropdown
      onClick={() => {
        setIsOpen(!isOpen);
      }}
      open={isOpen}
      text={createRoomText(currentRoom)}
      value={currentRoom && currentRoom.id}
      {...dropdownProps}
    >
      <Dropdown.Menu>
        <Dropdown.Header>
          {course.code} Rooms
        </Dropdown.Header>
        <Dropdown.Divider />
        {course.rooms.map((room) => (room ? (
          <Dropdown.Item
            as={Link}
            to="/"
            onClick={() => {
              switchRoomToast(room.location);
              if (isOnDuty) {
                setOffDutyRemote({
                  variables: {
                    roomId: currentRoom && currentRoom.id,
                    courseId: course && course.id,
                  },
                });
              }
              onRoomSelected(room.id);
            }}
            key={room.id}
            text={room.location}
            selected={currentRoom && room.id === currentRoom.id}
          />
        ) : null))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
export default ({ dropdownProps }) => {
  const currentCourseID = useContext(CourseIDContext);
  const currentRoomID = useContext(RoomIDContext);
  const { onRoomSelected } = useContext(AppContext);
  const { loading, error, data } = useQuery(CourseRoomsQuery, {
    variables: { courseID: currentCourseID },
    errorPolicy: 'all',
    skip: !currentCourseID,
  });

  if (error) Logging.error('RoomsDropdown', error, data);

  if (
    loading
    || !currentCourseID
    || !data
    || !data.course
    || !data.course.rooms
  ) {
    return null;
  }

  const { course } = data;
  const { isOnDuty } = data.user || {};

  const currentRoom = currentRoomID
    ? course.rooms.find((room) => room.id === currentRoomID)
    : course.rooms.find((room) => room.type === 'virtual');

  return (
    <RoomsDropdown
      onRoomSelected={onRoomSelected}
      currentRoomID={currentRoom ? currentRoom.id : currentRoomID}
      currentRoom={currentRoom}
      course={course}
      dropdownProps={dropdownProps}
      isOnDuty={isOnDuty}
    />
  );
};
