import React, { useState, useEffect, useContext } from 'react';
import { useMutation } from 'react-apollo';
import { Loader } from 'semantic-ui-react';
import { UpdateEnrollmentMutation } from '../../api/queries';
import { RoleOptions } from '../../types';
import * as Toasts from '../util/toast';
import { CourseIDContext } from '../Contexts.jsx';
import Dropdown from './Dropdown';

const onEnrollmentUpdateError = (error) => {
  console.error(error);
  Toasts.warningToast("Somethign went wrong changing this user's role");
};

const onEnrollmentUpdate = (msg) => {
  Toasts.successToast('Successfully changed role');
};

const EnrollmentDropdown = ({ enrollment, disabled }) => {
  const { type, user } = enrollment;
  const [selectedType, setSelectedType] = useState(enrollment.type);
  const courseID = useContext(CourseIDContext);
  const userID = user ? user.externalid : null;

  useEffect(() => {
    setSelectedType(type);
  }, [type, setSelectedType]);

  const [updateEnrollment, { loading, error }] = useMutation(
    UpdateEnrollmentMutation,
    {
      onCompleted: onEnrollmentUpdate,
      onError: onEnrollmentUpdateError,
      refetchQueries: ['UsersForCourse', 'UsersForCourseWithInvites'],
    },
  );

  if (loading) {
    return <Loader size="large" />;
  }

  return (
    <Dropdown
      aria-label="Enrollment Dropdown"
      placeholder=" Role"
      fluid
      selection
      options={RoleOptions}
      value={selectedType}
      onChange={(e, { name, value }) => {
        updateEnrollment({
          variables: { courseID, userID, type: value },
        });
      }}
      disabled={disabled}
    />
  );
};

export default EnrollmentDropdown;
