import React, { useContext, useState } from 'react';
import { Segment, Button, Header } from 'semantic-ui-react';
import { UserContext } from '../Contexts';
import UserEditForm from '../User/UserEditForm';
import UserPasswordReset from '../User/UserPasswordReset';
import useUpdateUser from '../User/useUpdateUser';
import * as Toasts from '../util/toast';

export default () => {
  const user = useContext(UserContext);
  const [localUser, setLocalUser] = useState(user);
  const [editUser] = useUpdateUser();
  const IS_SAAS = process.env.REACT_APP_IS_SAAS === 'true';

  return (
    <>
      <Header>My Account</Header>
      <Segment style={{ marginTop: '1em' }}>
        <UserEditForm
          isEditing
          withCancel={false}
          user={user}
          onChange={(u) => setLocalUser(u)}
          onSubmitted={() => {
            editUser(localUser);
            if (localUser.variables.email !== user.email && IS_SAAS) {
              Toasts.warningToast('Please check your email to complete the account update');
            }
          }}
        />
      </Segment>
      {user.authType === 'email' && (
        <>
          <Header>Update Password</Header>

          <Segment style={{ marginTop: '1em' }} padded>
            <UserPasswordReset />
          </Segment>
        </>
      )}
    </>
  );
};
