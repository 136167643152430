import React, { useState } from 'react';
import { Header, Icon } from 'semantic-ui-react';
import Fade from './Fade';

const Collapsable = ({
  name,
  subheader = '',
  expandedOnMount = false,
  hiddenIcon = false,
  disabled = false,
  renderRightContent = null,
  onChange = null,
  children,
  containerStyles = {},
  itemStyles = {},
}) => {
  const [isExpanded, setIsExpanded] = useState(!!expandedOnMount);
  const iconName = isExpanded && !disabled ? 'angle down' : 'angle up';
  const isVisible = !hiddenIcon && !disabled;
  return (
    <div
      style={{
        display: 'flex',
        flexFlow: 'column',
        margin: '.5em 0 1em 0',
        ...containerStyles,
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          borderBox: 'bounding-box',
          ...itemStyles,
        }}
      >
        <Header
          size="medium"
          floated="left"
          className={isVisible ? 'clickable' : ''}
          onClick={() => {
            if (!isVisible) return;
            const newState = !isExpanded;
            if (onChange) onChange(newState);
            setIsExpanded(!isExpanded);
          }}
          style={{ userSelect: 'none', WebkitUserSelect: 'none' }}
        >
          {name}
          <span>
            {!hiddenIcon && (
              <Icon
                name={iconName}
                color={disabled ? 'grey' : 'black'}
                style={{ marginRight: '0' }}
              />
            )}
          </span>
          {isExpanded && subheader && (
            <Header.Subheader>{subheader}</Header.Subheader>
          )}
        </Header>
        {isExpanded && renderRightContent ? renderRightContent() : null}
      </div>
      {isExpanded ? <Fade>{children}</Fade> : null}
    </div>
  );
};

export default Collapsable;
