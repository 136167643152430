import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  H3, H5, Spinner, Callout,
} from '@blueprintjs/core';
import { Header } from 'semantic-ui-react';

import { RouteComponentProps } from 'react-router';
import { CourseDrilldownQuery, AssignRoomMutation } from '../../api/queries';
import CannotFindCourseView from './CannotFindCourseView';
import ErrorView from '../common/ErrorView';
import { MatchParamsProps, CourseDataProps } from './CourseData';
import RoomAddForm from '../AdminViews/Room/RoomAddForm';
import RoomsListView from '../AdminViews/Room/RoomsListView';
import * as Toasts from '../util/toast';

// @ts-ignore
const onRoomAssocError = (error) => {
  console.error(error);
  Toasts.warningToast('Something went wrong while adding this room');
};

const onRoomAssign = () => {
  Toasts.successToast('Successfully assigned room');
};

const SAME_INSTITUTIONS_ROOMS_ENABLED = false;

// @ts-ignore
const AddRoomToCourseView = ({ allRooms, course, institution }) => {
  // Hide if we are not scoped by institution
  const isEnabled = !!process.env.REACT_APP_INSTITUTION;
  const roomsInCourseIds = course.rooms.map((room: any) => room.id);
  const difference = [...allRooms].filter(
    (room) => !roomsInCourseIds.includes(room.id),
  );

  const [assignRoom] = useMutation(AssignRoomMutation, {
    onCompleted: onRoomAssign,
    onError: onRoomAssocError,
    refetchQueries: ['CourseDrilldownQuery', 'RoomListQuery', 'ActiveTAsQuery'],
  });

  // Do not render if no inst exists or if we do not have existing rooms in the institution
  if (!isEnabled || !allRooms.length) {
    return null;
  }

  return (
    <>
      <RoomsListView
        onCardClick={(room) => {
          assignRoom({
            variables: { room: room.id, courseID: course.id },
          });
        }}
        rooms={difference}
        withOtherCourses
      />
    </>
  );
};

const RoomEditView: React.FC<CourseDataProps> = ({ course }) => (
  <>
    <div style={{ marginBottom: '1em' }}>
      <H5>Add new room</H5>
      <RoomAddForm institutionID={course.institution.id} course={course} />
    </div>

    <div style={{ marginBottom: '1em' }}>
      <H5>{course.code} rooms</H5>

      <RoomsListView rooms={course.rooms} course={course} />
      {course?.rooms?.length == 0 && (
      <div style={{ marginTop: '1em' }}>
        <Callout
          icon="warning-sign"
          title="Tip: Add at least one room"
          intent="warning"
        >
          Please add a room above. Students will not be able to join an
          office hours queue until at least one room is added to this
          course.
        </Callout>
      </div>
        )}
    </div>
    {SAME_INSTITUTIONS_ROOMS_ENABLED && !!course?.institution?.rooms?.length && (
    <div style={{ marginTop: '2em' }}>
      <H5>
        Other {course.institution.shortname || course.institution.name} rooms
      </H5>
      <AddRoomToCourseView
        allRooms={course.institution.rooms}
        course={course}
        institution={course.institution}
      />
    </div>
    )}
  </>
);

const CourseRoomsView: React.FC<RouteComponentProps<MatchParamsProps>> = (
  props,
) => {
  const { match } = props;
  const { loading, error, data } = useQuery(CourseDrilldownQuery, {
    variables: {
      id: (match && match.params && match.params.courseID) || '',
    },
  });
  if (!match || !match.params || !match.params.courseID) {
    return <CannotFindCourseView />;
  }
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '1em',
        }}
      >
        <H3>Edit Space Rooms</H3>
      </div>
      <div style={{ marginBottom: '1em' }}>
        <Callout title="About rooms" icon="info-sign" intent="primary">
          Real-time queues are isolated by room. For in-person office hours,
          rooms let students know where to meet their TAs/tutors. Effective room
          names are short.
        </Callout>
      </div>

      {!loading && !data && <ErrorView data={data} error={error} />}
      {!loading && !!data ? <RoomEditView course={data.course} /> : <Spinner />}
    </>
  );
};

export default CourseRoomsView;
